import Moment from 'moment';
import { StateCodes } from '../../utils/states';
import { Checkbox, Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { DatePicker, DateRangePicker } from '@progress/kendo-react-dateinputs';
import { AdvancedSearchQuery } from '.';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { IDName } from 'types/idname';
import { useEffect, useState } from 'react';
import { useBoolean, useUpdateEffect } from 'usehooks-ts';
import { fetchApi } from 'services/api';
import VehicleAutoComplete from 'views/AssetVehicles/VehicleAutoComplete';
import CarrierAutoComplete from 'views/AssetCarriers/CarrierAutoComplete';
import TrailerAutoComplete from 'views/AssetTrailers/TrailerAutoComplete';
import DriverAutoComplete from 'views/AssetDrivers/DriverAutoComplete';
import { vehicleTypes } from 'views/Quote';
import CustomerInput from 'components/CustomerInput';
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';

type Props = {
  CloseDialog: () => void;
  Query: null|AdvancedSearchQuery,
  Search: (query: null|AdvancedSearchQuery) => void;
}

type State = {
  OrderStatuses: number[];
  DispatchStatuses: number[];
  SearchDateType: number;
  FromDate: Date;
  ToDate: Date;
  IgnoreServiceTeams: boolean;
  SearchOptionType: number;
  SearchOptionValue: string;
  FromState: string;
  FromCityName: string;
  ToState: string;
  ToCityName: string;
  AssetID: number;
  AssetType: number;
  VehicleTypeID: number;
  ServiceTeams: number[];
  MyFreight: boolean;
  BookUserIDs: number[];
  CustomerID: number;
  CustomerNumber: string;
  CustomerType: number;
  CopiedOrderNumber: number;
}

export const orderStatuses = [
  { ID: 90, Name: "Will Call" },
  { ID: 95, Name: "Cust. Submitted" },
  { ID: 100, Name: "Available" },
  { ID: 102, Name: "Planned" },
  { ID: 110, Name: "In-Progress" },
  { ID: 120, Name: "Completed" },
  { ID: 130, Name: "Dry Run" },
  { ID: 170, Name: "Cancelled" },
  { ID: 160, Name: "Turn Down" },
  { ID: 180, Name: "Master" },
  { ID: 210, Name: "Cross Dock" },
] as IDName[];

const dateSearchOptions = [
  { ID: 1, Name: "Ship Date" },
  { ID: 2, Name: "Delivery Date" },
  { ID: 3, Name: "Book Date" }
] as IDName[];

const searchOptions = [
  { ID: 1, Name: "Reference 1" },
  { ID: 2, Name: "Reference 2" },
  { ID: 3, Name: "Reference 3" },
  { ID: 4, Name: "Ref 1 or 2"}
] as IDName[];

const customerTypes = [
  { ID: 0, Name: 'Authorization' },
  { ID: 1, Name: 'Billing' },
  { ID: 2, Name: 'Logistics' },
  { ID: 3, Name: 'Shipper' },
  { ID: 4, Name: 'Consignee' }
] as IDName[];

const assetTypes = [
  { ID: 0, Name: 'Vehicle' },
  { ID: 1, Name: 'Carrier' },
  { ID: 2, Name: 'Trailer' },
  { ID: 3, Name: 'Driver' },
] as IDName[];

const AdvancedSearch = ({ CloseDialog, Query, Search }: Props) => {

  const { value: loadUsers, setTrue: setLoadUsers } = useBoolean(false);
  const [users, setUsers] = useState<IDName[]>([]);
  const [bookUserFilter, setBookUserFilter] = useState<FilterDescriptor>(null);
  const [state, setState] = useState<AdvancedSearchQuery>({
    OrderStatuses: [95, 100, 110, 120, 130, 170],
    DispatchStatuses: [],
    SearchDateType: 1,
    FromDate: Moment().add(-7, "days").startOf('day').toDate(),
    ToDate: Moment().startOf('day').toDate(),
    IgnoreServiceTeams: true,
    SearchOptionType: 1,
    SearchOptionValue: '',
    FromState: '',
    FromCityName: '',
    ToState: '',
    ToCityName: '',
    AssetID: 0,
    AssetType: 0,
    VehicleTypeID: 0,
    ServiceTeams: [],
    MyFreight: false,
    BookUserIDs: [],
    CustomerID: 0,
    CustomerNumber: '',
    CustomerType: 0,
    CopiedOrderNumber: undefined,
  });

  useEffect(() => {
    if (Query) {
      setState({
        OrderStatuses: Query.OrderStatuses,
        DispatchStatuses: [], // Add UI if you want to use this
        SearchDateType: Query.SearchDateType,
        FromDate: Query.FromDate,
        ToDate: Query.ToDate,
        IgnoreServiceTeams: Query.IgnoreServiceTeams,
        SearchOptionType: Query.SearchOptionType,
        SearchOptionValue: Query.SearchOptionValue,
        FromState: Query.FromState,
        FromCityName: Query.FromCityName,
        ToState: Query.ToState,
        ToCityName: Query.ToCityName,
        AssetID: Query.AssetID,
        AssetType: Query.AssetType,
        VehicleTypeID: Query.VehicleTypeID,
        ServiceTeams: [], // Add UI if you want to use this
        MyFreight: Query.MyFreight,
        BookUserIDs: Query.BookUserIDs,
        CustomerID: Query.CustomerID,
        CustomerNumber: Query.CustomerNumber,
        CustomerType: Query.CustomerType,
        CopiedOrderNumber: Query.CopiedOrderNumber,
      });
      if (Query.BookUserIDs.length > 0) setLoadUsers();
    }
  }, [Query, setLoadUsers]);

  const search = (e: any) => {
    e.preventDefault();

    if (state.OrderStatuses.length === 0) {
      alert('Please select an order status');
      return
    }

    if (!state.FromDate || !state.ToDate) {
      alert('Please enter from/to dates');
      return
    }

    Search(state);
    CloseDialog();
  }

  const partialState = (partial: Partial<State>) => {
    setState({ ...state, ...partial });
  }

  useUpdateEffect(() => {
    fetchApi('/api/User/SylectusUsersDropdown')
    .then((data: { Users: Array<{ ID: number, Name: string }> }) => {
      setUsers(data.Users);
    });
  }, [loadUsers, setUsers]);

  const bookUserFiltered = filterBy(users, bookUserFilter);

  return (
      <Dialog title="Advanced Search" onClose={CloseDialog} className='dialog-w1140'>
        <form className="k-form k-form-md" onSubmit={search}>
          <div className="row">
            <div className="col-md-6">
              <label>Order Status</label>
              <MultiSelect
                data={orderStatuses}
                textField="Name"
                dataItemKey="ID"
                value={orderStatuses.filter(x => state.OrderStatuses.includes(x.ID))}
                onChange={(e) => partialState({ OrderStatuses: e.value.map((x: IDName) => x.ID) })}
              />
              <br /><br />
              <Checkbox
                label="My Service Teams Only"
                value={!state.IgnoreServiceTeams}
                onChange={(e) => partialState({ IgnoreServiceTeams: !e.value }) }
              />
              <br /><br />
              <Checkbox
                label="My Book User Only"
                value={state.MyFreight}
                onChange={(e) => partialState({ MyFreight: e.value }) }
              />
              <br />
              <MultiSelect
                filterable
                onFilterChange={(e) => setBookUserFilter(e.filter)}
                label="Book Users"
                onOpen={setLoadUsers}
                data={bookUserFiltered}
                textField="Name"
                dataItemKey="ID"
                value={users.filter(x => state.BookUserIDs.includes(x.ID))}
                onChange={(e) => partialState({ BookUserIDs:(e.value as any[]).map(x => x.ID) }) }
              />
              <br /><br />
              <div className="input-group flex-nowrap">
                <div className="input-group-prepend">
                  <DropDownList
                    className="k-dropdown-wrap-append"
                    data={customerTypes}
                    textField="Name"
                    dataItemKey="ID"
                    value={customerTypes.find(x => x.ID == state.CustomerType)}
                    onChange={(e) => partialState({ CustomerType: e.target.value.ID }) }
                  />
                </div>
                <CustomerInput
                  prependGroup
                  placeholder='Company'
                  CustomerID={state.CustomerID}
                  CustomerNumber={state.CustomerNumber}
                  onChange={(e) => {
                    partialState({ CustomerID: e.CustomerID, CustomerNumber: e.CustomerNumber })
                  }}
                />
              </div>
              <NumericTextBox
                label="Master/Copied From Order #"
                format={'0'}
                value={state.CopiedOrderNumber}
                spinners={false}
                onChange={(e) => partialState({ CopiedOrderNumber: e.value })}
              />
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <DatePicker
                  label={`From ${dateSearchOptions.find(x => x.ID == state.SearchDateType)?.Name}`}
                  format="MM/dd/yyyy"
                  formatPlaceholder="formatPattern"
                  value={state.FromDate}
                  onChange={(e) => partialState({ FromDate: e.value || state.FromDate })}
                />
                <DatePicker
                  label={`To ${dateSearchOptions.find(x => x.ID == state.SearchDateType)?.Name}`}
                  format="MM/dd/yyyy"
                  formatPlaceholder="formatPattern"
                  value={state.ToDate}
                  onChange={(e) => partialState({ ToDate: e.value || state.ToDate })}
                />
              </div>
              <div className="mb-3">
                <DropDownList
                  data={dateSearchOptions}
                  textField="Name"
                  dataItemKey="ID"
                  value={dateSearchOptions.find(x => x.ID == state.SearchDateType)}
                  onChange={(e) => partialState({ SearchDateType: e.target.value.ID }) }
                />
              </div>
              <div className="input-group flex-nowrap">
                <div className="input-group-prepend">
                  <DropDownList
                    className="k-dropdown-wrap-append"
                    data={searchOptions}
                    value={searchOptions.find(x => x.ID == state.SearchOptionType)}
                    textField="Name"
                    dataItemKey="ID"
                    onChange={(e) => partialState({ SearchOptionType: e.target.value.ID })}
                  />
                </div>
                <Input
                  className="k-numeric-wrap-prepend"
                  value={state.SearchOptionValue}
                  onChange={(e) => partialState({ SearchOptionValue: e.value })}
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <DropDownList
                    label="From State"
                    data={StateCodes}
                    textField="Name"
                    dataItemKey="ID"
                    value={StateCodes.find(x => x.ID == state.FromState)}
                    onChange={(e) => partialState({ FromState: e.target.value.ID }) }
                  />
                </div>
                <div className="col-6">
                  <DropDownList
                    label="To State"
                    data={StateCodes}
                    textField="Name"
                    dataItemKey="ID"
                    value={StateCodes.find(x => x.ID == state.ToState)}
                    onChange={(e) => partialState({ ToState: e.target.value.ID }) }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Input
                    label="From City Name"
                    maxLength={50}
                    value={state.FromCityName}
                    onChange={(e) => partialState({ FromCityName: e.value })}
                  />
                </div>
                <div className="col-6">
                  <Input
                    label="To City Name"
                    maxLength={50}
                    value={state.ToCityName}
                    onChange={(e) => partialState({ ToCityName: e.value })}
                  />
                </div>
              </div>
              <br />
              <div className="input-group flex-nowrap">
                <div className="input-group-prepend">
                  <DropDownList
                    className="k-dropdown-wrap-append"
                    data={assetTypes}
                    textField="Name"
                    dataItemKey="ID"
                    value={assetTypes.find(x => x.ID == state.AssetType)}
                    onChange={(e) => partialState({ AssetType: e.target.value.ID, AssetID: 0 }) }
                  />
                </div>
                {state.AssetType == 0 && <VehicleAutoComplete
                  style={{ flex: 1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                  placeholder='Search...'
                  selectedVehicleID={state.AssetID}
                  onSelectedVehicle={(e) => partialState({ AssetID: e?.VehicleID })}
                />}
                {state.AssetType == 1 && <CarrierAutoComplete
                  style={{ flex: 1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                  placeholder='Search...'
                  selectedCarrierID={state.AssetID}
                  onSelectedCarrier={(e) => partialState({ AssetID: e?.CarrierID || 0 })}
                />}
                {state.AssetType == 2 && <TrailerAutoComplete
                  style={{ flex: 1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                  placeholder='Search...'
                  selectedTrailerID={state.AssetID}
                  onSelectedTrailer={(e) => partialState({ AssetID: e?.TrailerID || 0 })}
                />}
                {state.AssetType == 3 && <DriverAutoComplete
                  style={{ flex: 1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                  placeholder='Search...'
                  selectedDriverID={state.AssetID}
                  onSelectedDriver={(e) => partialState({ AssetID: e?.DriverID || 0 })}
                />}
              </div>
              <br />
              <DropDownList
                data={vehicleTypes}
                textField="text"
                dataItemKey="value"
                value={vehicleTypes.find(x => x.value == state.VehicleTypeID)}
                onChange={(e) => partialState({ VehicleTypeID: e.target.value.value }) }
              />
            </div>
          </div>
        </form>
        <DialogActionsBar layout="end">
          <Button
            type="button"
            onClick={() => {
              if (Query) Search(null);
              CloseDialog();
            }}>Clear Advanced Filters</Button>
          <Button type="submit" themeColor="primary" onClick={search}>Advanced Search</Button>
        </DialogActionsBar>
      </Dialog>
  );
}

export default AdvancedSearch;
