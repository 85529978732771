import { SvgIcon } from "@progress/kendo-react-common";
import { Upload as KendoUpload } from "@progress/kendo-react-upload";
import { imagesIcon } from "@progress/kendo-svg-icons";
import { useEffect, useRef, useState } from "react";

type CustomDropZoneProps = {
  uploadRef: React.RefObject<KendoUpload>;
}

export const CustomDropZone = (props: CustomDropZoneProps) => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [overDropZone, setOverDropZone] = useState<boolean>(false);

  useEffect(() => {
    const handleWindowDrop = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setOverDropZone(false);

      // Skips uploading twice if the drop event is on the upload component
      const targetElement = e.target as HTMLElement;
      if (targetElement.closest('div.k-upload-dropzone')) {
        return;
      }

      if (e.dataTransfer.files[0] && props.uploadRef.current && !props.uploadRef.current.props.disabled) {
        props.uploadRef.current.onAdd(e.dataTransfer.files);
      }
    };

    const handleWindowDragEnter = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.dataTransfer && e.dataTransfer.types && e.dataTransfer.types.length == 1 && e.dataTransfer.types[0] == "Files") {
        setOverDropZone(true);
      }
    };

    const handleWindowDragOver = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      // To prevent the overlay being stuck showing (not sure this can happen tbh), we can call setOverDropZone(false) if we haven't seen a over event in 100ms
      clearInterval(intervalRef.current);
      intervalRef.current = setTimeout(() => {
        setOverDropZone(false);
      }, 100);
    }

    const handleWindowDragLeave = (e: DragEvent) => {
      if (e && e.pageX == 0 && e.pageY == 0) {
        setOverDropZone(false);
      }
    };

    const handleWindowPaste = (e: any) => { // ClipboardEvent missing in window event in typescript
      const items = (e as ClipboardEvent).clipboardData.items;
      const dataTransfer = new DataTransfer();
      for (let i = 0; i < items.length; i++) {
        const file = items[i].getAsFile();
        if (file && props.uploadRef.current && !props.uploadRef.current.props.disabled) {
          dataTransfer.items.add(file);
        }
      }
      props.uploadRef.current.onAdd(dataTransfer.files);
    };

    window.addEventListener('drop', handleWindowDrop);
    window.addEventListener('dragenter', handleWindowDragEnter);
    window.addEventListener('dragover', handleWindowDragOver);
    window.addEventListener('dragleave', handleWindowDragLeave);
    window.addEventListener('paste', handleWindowPaste);

    return () => {
      window.removeEventListener('drop', handleWindowDrop);
      window.removeEventListener('dragenter', handleWindowDragEnter);
      window.removeEventListener('dragover', handleWindowDragOver);
      window.removeEventListener('dragleave', handleWindowDragLeave);
      window.removeEventListener('paste', handleWindowPaste);
    };
  }, [props.uploadRef]);

  return overDropZone ? <div>
      <div className="k-upload-overlay" />
      <div className="k-upload-overlay-content">
        <SvgIcon icon={imagesIcon} size="xxxlarge" themeColor="primary" />
        <br /><br />
        <h2>Drag and drop files here</h2>
        <p>Or paste from your clipboard using Ctrl + V.</p>
      </div>
    </div> : null;
}

export default CustomDropZone;