import { useCallback, useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import { CustomerNavBar } from "./NavBar";
import { fetchApi } from "../../services/api";
import { Loader } from "@progress/kendo-react-indicators";
import { ViewModel } from "TypeGen/Customer/SalesHistory/view-model";
import { SalesHistoryViewModel } from "TypeGen/Customer/SalesHistory/sales-history-view-model";
import { Grid, GridColumn as Column, GridNoRecords, GridToolbar } from "@progress/kendo-react-grid";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";

type RouteComponentParams = {
  customerId: string;
};

const CustomerSalesHistory = () => {
  const { customerId: customerIdParam } = useParams<RouteComponentParams>();
  const [loading, setLoading] = useState(true);
  const [useBillTo, setUseBillTo] = useState<boolean>(true);
  const [rates, setRates] = useState<SalesHistoryViewModel[]>([]);

  const refresh = useCallback(() => {
    setLoading(true);
    const payload = {
      UseBillToCustomer: useBillTo
    }
    fetchApi(`/api/Customer/CustomerSalesHistory/${customerIdParam}`, payload, 'POST')
      .then((data: ViewModel) => {
        setRates(data.Rates);
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to load customer sales history.');
      })
      .finally(() => setLoading(false));
  }, [customerIdParam, useBillTo]);

  useEffect(() => {
    refresh();
  }, [customerIdParam, refresh]);

  if (loading) return <div className="k-pos-absolute k-top-center mt-5">
    <Loader type="converging-spinner" />
  </div>;

  return (<>
    <CustomerNavBar id={customerIdParam} currentTab="SalesHistory" />
    <Title string="Customer Sales History" />
    <br />
    <Grid data={rates}>
      <GridToolbar>
        <ButtonGroup>
          <Button togglable
            selected={useBillTo}
            onClick={() => setUseBillTo(!useBillTo)}
          >
            Billing
          </Button>
          <Button togglable
            selected={!useBillTo}
            onClick={() => setUseBillTo(!useBillTo)}
          >
            Authorization
          </Button>
        </ButtonGroup>
      </GridToolbar>
      <GridNoRecords>No Customer Sales History Data.</GridNoRecords>
      <Column field="YYYYMM" title="YYYY-MM" />
      <Column field="HistoryCount" title="Order Count" />
      <Column field="TotalLinehaul" title="Linehaul" format="{0:c2}" />
      <Column field="TotalAccessorials" title="Accessorials" format="{0:c2}" />
      <Column field="TotalFuelSurcharge" title="Fuel Surcharge" format="{0:c2}" />
      <Column field="TotalTaxes" title="Taxes" format="{0:c2}" />
      <Column field="TotalExchange" title="Exchange" format="{0:c2}" />
      <Column field="TotalOther" title="Other" format="{0:c2}" />
      <Column field="TotalCharges" title="Total Charges" format="{0:c2}" />
    </Grid>
  </>);
}

export default CustomerSalesHistory;