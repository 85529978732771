import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchApi } from "../../services/api";
import { ILink } from '../../types/link';
import RetirePopup from './RetirePopup';
import { JsonResponse } from "TypeGen/json-response";
import { SvgIcon } from "@progress/kendo-react-common";
import { chevronRightIcon } from "@progress/kendo-svg-icons";

type Props = {
    id: string | number;
    currentTab: "Profile" | "Positions" | "ServiceStatuses" | "WorkloadHistory" | "Turndowns" | "Offers" | "Hardware" | "Notes" | "Logs";
}

export const VehicleNavBar = (props: Props) => {

    const [number, setNumber] = useState("");
    const [links, setLinks] = useState<ILink[]>([]);
    const [showRetirePopup, setShowRetirePopup] = useState<boolean>(false);

    const refresh = (vehicleId: string | number) => {
        setLinks([]);
        fetchApi(`/api/Asset/VehicleNavBar/${vehicleId}`)
            .then((data: { Number: string, Links: ILink[] }) => {
                setNumber(data.Number);
                setLinks(data.Links);
            });
    }

    const reactivate = () => {
        if (!window.confirm("Are you sure you want to reactivate this vehicle?")) {
            return;
        }

        fetchApi(`/api/Asset/ReactivateVehicle/${props.id}`, {}, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    refresh(props.id);
                } else {
                    alert(response.ErrorMessage);
                }
            })
            .catch(() => {
                alert("Unable to reactivate");
            });
    }

    useEffect(() => {
        refresh(props.id);
    }, [props.id]);

    return <>
        {showRetirePopup && <RetirePopup
            VehicleID={props.id}
            CloseDialog={() => setShowRetirePopup(false)}
            Refresh={() => refresh(props.id)}
        />}
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark mt-3">
            <span className="navbar-brand">
                <Link to="/Assets/Vehicles">Vehicles</Link>
                <span className="text-muted">
                    <SvgIcon icon={chevronRightIcon} />
                    {number}
                </span>
            </span>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#vehicleNav" aria-controls="vehicleNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="vehicleNav">
                <ul className="navbar-nav">
                    {links.find((x: ILink) => x.Name === 'Vehicle') && <li className={`nav-item ${props.currentTab === 'Profile' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Vehicle/${props.id}`}>Profile</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'Positions') && <li className={`nav-item ${props.currentTab === 'Positions' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Vehicle/${props.id}/Positions`}>Positions</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'ServiceStatuses') && <li className={`nav-item ${props.currentTab === 'ServiceStatuses' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Vehicle/${props.id}/ServiceStatuses`}>Service Statuses</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'WorkloadHistory') && <li className={`nav-item ${props.currentTab === 'WorkloadHistory' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Vehicle/${props.id}/WorkloadHistory`}>Workload History</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'Turndowns') && <li className={`nav-item ${props.currentTab === 'Turndowns' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Vehicle/${props.id}/Turndowns`}>Turndowns</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'Offers') && <li className={`nav-item ${props.currentTab === 'Offers' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Vehicle/${props.id}/Offers`}>Offers</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'Hardware') && <li className={`nav-item ${props.currentTab === 'Hardware' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Vehicle/${props.id}/Hardware`}>Hardware</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'Notes') && <li className={`nav-item ${props.currentTab === 'Notes' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Vehicle/${props.id}/Notes`}>Notes</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'Logs') && <li className={`nav-item ${props.currentTab === 'Logs' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/ResourceLogs/${props.id}/2`}>Logs</Link>
                    </li>}
                </ul>
                <ul className="nav navbar-nav ml-auto">
                    {links.find((x: ILink) => x.Name === 'Retire') && <Button onClick={() => setShowRetirePopup(true)} themeColor="error">Retire</Button>}
                    {links.find((x: ILink) => x.Name === 'ReHire') && <Button onClick={() => reactivate()} themeColor="primary">Reactivate</Button>}
                </ul>
            </div>
        </nav>
    </>;
}