import * as React from 'react';
import { Grid, GridColumn as Column, GridSortChangeEvent, GridCellProps, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import TheadIDCell from './Recent/ThreadIDCell';
import OrderNumberCell from './Recent/OrderNumberCell';
import { fetchApi } from '../../services/api';
import { UpdateQuoteOfferCount, EnterStopViewModel, UpdateQuoteAssigned } from '.';
import { GridLoadingPanel } from 'components/GridLoadingPanel';
import YesNoCell from '../../components/cells/YesNoCell';
import { visibility, isVisible } from '../../services/visibility';
import { Link } from 'react-router-dom';
import DateCell from '../../components/cells/DateCell';
import { Button } from '@progress/kendo-react-buttons';

export type RecentQuoteValue = {
    QuoteID: number;
    ThreadID: string;
    RouteName: string;
    Distance: number;
    Pieces: number;
    Weight: number;
    Total: number;
    BillingCustomerNumber: string;
    VehicleTypeName: string;
    FromLocation: string;
    ToLocation: string;
    FromDateTime: Date;
    CreatedByUserName: string;
    CreatedDateTime: Date;
    AcceptedOfferCount: number;
    DeclinedOfferCount: number;
    ExpiredOfferCount: number;
    isMatchingLane: boolean;
    OrderID: number;
    OrderNumber: number;
    OrderStatus: string;
    BillingCustomerID: number;
    BlindBid: boolean;
    Unassigned: boolean;
    IsTemplate: boolean;
}

type State = {
    isMine: boolean;
    isEDI: boolean;
    isUnassigned: boolean;
    isTemplate: boolean;
    isLoading: boolean;
    isBillingCustomerID: boolean;
    isVehicleTypeID: boolean;
    isMatchingLane: boolean;
    sort: SortDescriptor[];
    quotes: RecentQuoteValue[];
    refreshRequired: boolean;
}

type Props = {
    quoteID: number;
    blindBid?: boolean;
    billingCustomerID: number;
    vehicleTypeID?: number;
    locations: EnterStopViewModel[];
};

export function quoteIDCell() {
    return class QuoteIDCell extends React.Component<GridCellProps> {
        public render() {
            return (
                <td>
                    <Link
                        to={`/Quote/Index/${this.props.dataItem.QuoteID}`}
                        style={{ color: this.props.dataItem.Unassigned ? '#28a745' : '#007bff' }}
                    >
                        {this.props.dataItem.QuoteID}
                        {this.props.dataItem.DeclinedOfferCount > 0 && <div className="recent-red-circle">{this.props.dataItem.DeclinedOfferCount}</div>}
                        {this.props.dataItem.ExpiredOfferCount > 0 && <div className="recent-orange-circle">{this.props.dataItem.ExpiredOfferCount}</div>}
                        {this.props.dataItem.AcceptedOfferCount > 0 && <div className="recent-green-circle">{this.props.dataItem.AcceptedOfferCount}</div>}
                    </Link>
                </td>
            );
        }

        public shouldComponentUpdate(nextProps: GridCellProps) {
            return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
        }
    }
}

export default class RecentPanel extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isMine: false,
            isEDI: false,
            isUnassigned: false,
            isTemplate: false,
            isLoading: false,
            isMatchingLane: false,
            isBillingCustomerID: false,
            isVehicleTypeID: false,
            sort: [{ field: "QuoteID", dir: "desc" }],
            quotes: [],
            refreshRequired: false,
        }

        this.getRecentQuotes = this.getRecentQuotes.bind(this);
        this.sortChange = this.sortChange.bind(this);
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    }

    public reset() {
        if (this.state.isMatchingLane || this.state.isBillingCustomerID || this.state.isVehicleTypeID) {
            this.getRecentQuotes();
        }
    }

    public clearState() {
        this.setState({
            isMine: false,
            sort: [{ field: "QuoteID", dir: "desc" }],
            quotes: [],
            isBillingCustomerID: false,
            isVehicleTypeID: false,
        });
    }

    public getRecentQuotes() {
        if (!isVisible()) {
            if (this.state.refreshRequired == false) this.setState({ refreshRequired: true });
            return;
        }

        const data = {
            Mine: this.state.isMine,
            IsEDI: this.state.isEDI,
            IsUnassigned: this.state.isUnassigned,
            IsTemplate: this.state.isTemplate,
            Locations: this.state.isMatchingLane ? this.props.locations.map(x => x.Location.Coordinates) : [],
            BillingCustomerID: this.state.isBillingCustomerID ? this.props.billingCustomerID : 0,
            VehicleTypeID: this.state.isVehicleTypeID ? this.props.vehicleTypeID : 0,
        };

        fetchApi('/api/Quote/GetRecentQuotes', data, `post`)
            .then((quotes: RecentQuoteValue[]) => {
                this.setState({ quotes, isLoading: false, refreshRequired: false });
            });
    }

    public setQuoteOfferCount(data: UpdateQuoteOfferCount) {
        const quote = this.state.quotes.find(x => x.QuoteID == data.QuoteID);
        if (quote) {
            quote.ExpiredOfferCount = data.ExpiredOfferCount;
            quote.DeclinedOfferCount = data.DeclinedOfferCount;
            quote.AcceptedOfferCount = data.AcceptedOfferCount;
            this.forceUpdate();
        }
    }

    public setQuoteAssigned(data: UpdateQuoteAssigned) {
        const quote = this.state.quotes.find(x => x.QuoteID == data.QuoteID);
        if (quote) {
            quote.Unassigned = false;
            quote.CreatedByUserName = data.CreatedByUserName;
            this.forceUpdate();
        }
    }

    public componentDidMount() {
        this.getRecentQuotes();
        document.addEventListener(visibility.event, this.handleVisibilityChange);
    }

    public componentWillUnmount() {
        document.removeEventListener(visibility.event, this.handleVisibilityChange);
    }

    private handleVisibilityChange() {
        if (isVisible() && this.state.refreshRequired) {
            this.getRecentQuotes();
        }
    }

    public render() {
        const data = orderBy(this.state.quotes, this.state.sort);

        return <React.Fragment>
            {this.state.isLoading && <GridLoadingPanel />}
            <Grid
                style={{ minHeight: '100%', minWidth: 980 }}
                sortable={{ allowUnsort: true }}
                scrollable='none'
                sort={this.state.sort}
                onSortChange={this.sortChange}
                data={data.map(
                    (item) => ({ ...item, selected: item.QuoteID === this.props.quoteID })
                )}
            >
                <GridToolbar>
                    <Button
                        togglable
                        selected={this.state.isMine}
                        onClick={() => { this.setState({ isMine: !this.state.isMine }, this.getRecentQuotes); }}>
                        Mine
                    </Button>
                    <Button
                        togglable
                        selected={this.state.isEDI}
                        onClick={() => { this.setState({ isEDI: !this.state.isEDI }, this.getRecentQuotes); }}>
                        EDI
                    </Button>
                    <Button
                        togglable
                        selected={this.state.isUnassigned}
                        onClick={() => { this.setState({ isUnassigned: !this.state.isUnassigned }, this.getRecentQuotes); }}>
                        Unassigned
                    </Button>
                    <Button
                        togglable
                        selected={this.state.isTemplate}
                        onClick={() => { this.setState({ isTemplate: !this.state.isTemplate }, this.getRecentQuotes); }}>
                        Templates
                    </Button>
                    {this.props.billingCustomerID > 0 && <>
                        <Button
                            togglable
                            selected={this.state.isBillingCustomerID}
                            onClick={() => { this.setState({ isBillingCustomerID: !this.state.isBillingCustomerID, isLoading: true }, this.getRecentQuotes); }}>
                            Billing Customer
                        </Button>
                    </>}
                    {this.props.vehicleTypeID > 0 && <>
                        <Button
                            togglable
                            selected={this.state.isVehicleTypeID}
                            onClick={() => { this.setState({ isVehicleTypeID: !this.state.isVehicleTypeID, isLoading: true }, this.getRecentQuotes); }}>
                            Vehicle Type
                        </Button>
                    </>}
                    {this.props.locations.length > 0 && <>
                        <Button
                            togglable
                            selected={this.state.isMatchingLane}
                            onClick={() => { this.setState({ isMatchingLane: !this.state.isMatchingLane, isLoading: true }, this.getRecentQuotes); }}>
                            Matching Lane
                        </Button>
                    </>}
                </GridToolbar>
                <GridNoRecords>
                    No Quotes Found
                </GridNoRecords>
                <Column width="120px" field="QuoteID" title="#" filter="numeric" cell={quoteIDCell()} />
                <Column field="ThreadID" title="Front" cell={TheadIDCell} />
                <Column field="OrderNumber" title="Order No." filter="numeric" cell={OrderNumberCell} />
                <Column field="RouteName" title="Route" />
                {this.props.blindBid &&  <Column field="BlindBid" title="Blind Bid" cell={YesNoCell} />}
                <Column field="Distance" title="Miles" filter="numeric" format="{0:n0}" />
                <Column field="Pieces" filter="numeric" format="{0:n0}" />
                <Column field="Weight" filter="numeric" format="{0:n0}" />
                <Column field="Total" title="Charges" filter="numeric" format="{0:c}" />
                <Column field="VehicleTypeName" title="Vehicle Type" />
                <Column field="BillingCustomerNumber" title="Bill To" />
                <Column field="FromLocation" title="From" />
                <Column field="ToLocation" title="To" />
                <Column field="FromDateTime" title="Ship Date" filter="date" cell={DateCell} />
                <Column field="CreatedDateTime" title="Created" filter="date" cell={DateCell} />
                <Column field="CreatedByUserName" title="By" />
            </Grid>
        </React.Fragment>
    }

    private sortChange(event: GridSortChangeEvent) {
        this.setState({
            sort: event.sort
        });
    }
}
