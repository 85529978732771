import { Button, Chip } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Loader } from "@progress/kendo-react-indicators";
import { Tooltip } from "@progress/kendo-react-tooltip";
import Moment from 'moment-timezone';
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { CustomerViewModel as CustomerType } from "TypeGen/Customer/Profile/customer-view-model";
import { ViewModel as CustomerProfileType } from "TypeGen/Customer/Profile/view-model";
import { JsonResponse } from "TypeGen/json-response";
import AuditInfo from "../../components/AuditInfo";
import CenterDivPanel from "../../components/CenterDivPanel";
import CurrentLocalTimeDisplay from "../../components/CurrentLocalTimeDisplay";
import { EditableAddress } from "../../components/EditableAddress";
import { EditableField } from "../../components/EditableField";
import { fetchApi } from "../../services/api";
import { FuelSurcharge } from "../../TypeGen/Customer/fuel-surcharge";
import { Title } from '../../utils/title';
import SalespersonAutoComplete, { GetSalespersonAutoCompleteDisplayValue } from "../Salespeople/SalespersonAutoComplete";
import CustomerAutoComplete, { GetCustomerAutoCompleteDisplayValue } from "./CustomerAutoComplete";
import { CustomerNavBar } from "./NavBar";
import usePrompt from "components/usePrompt";
import { AddressType } from "../../TypeGen/Customer/Profile/address-type";
import { Grid, GridToolbar, GridColumn as Column, GridCellProps, GridNoRecords } from "@progress/kendo-react-grid";
import { plusIcon, xIcon } from "@progress/kendo-svg-icons";
import CarrierExclusionPopup from "./CarrierExclusionPopup";
import NoContentView from "components/NoContentView";

type Props = {
  isDialog?: boolean
  customerId?: number;

  onClose?: () => void;
}

type RouteComponentParams = {
  customerId: string;
};


const MaxMinutesEarlyArrivalAs = [
  { id: 'L', text: 'Logistics' },
  { id: 'A', text: 'Authorization' },
  { id: 'B', text: 'Billing' },
  { id: 'P', text: 'Pickup' },
  { id: 'D', text: 'Delivery' },
]

const ModifiedCell = (props: GridCellProps) => {
  if (!props.field || props.rowType !== 'data') return null;

  const field = props.dataItem[props.field];

  return (
    <td title={props.dataItem.ModifiedByFullName}>
      {field ? Moment.utc(field).tz("America/New_York").format("MM/DD/YYYY") : ''}
    </td>
  );
}

const CustomerProfile = (props: Props) => {

  const { customerId: customerIdParam } = useParams<RouteComponentParams>();
  const [customerId, setCustomerId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showCarrierExclusionPopup, setShowCarrierExclusionPopup] = useState(false);
  const [profile, setProfile] = useState<CustomerProfileType>();
  const history = useHistory();
  const { PromptDialog, prompt } = usePrompt({});

  const fetchProfile = useCallback(() => {
    async function fetchData() {
      setLoading(true);
      await fetchApi(`/api/Customer/Customer/${customerId}`)
        .then(data => {
          setProfile(data);
        })
        .catch(e => {
          // If not problem details
          if (!e?.status) alert(e);
        })
        .finally(() => setLoading(false));
    }
    fetchData();
  }, [customerId]);

  const _removeCarrierExclusion = (CarrierID: number) => {
    if (!window.confirm("Are you sure you want to remove this carrier from the exclusion list?")) return;

    fetchApi(`/api/Customer/ExcludeCarrier/${customerId}`, { CarrierID }, 'DELETE')
      .then(() => fetchProfile())
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert('Unable to remove carrier exclusion.');
      });
  }

  const _addCarrierExclusion = (newData: any) => {
    fetchApi(`/api/Customer/ExcludeCarrier/${customerId}`, newData, 'POST')
      .then(() => {
        fetchProfile();
        setShowCarrierExclusionPopup(false);
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert('Unable to save carrier exclusion.');
      });
  }

  useEffect(() => {
    if (props.customerId > 0)
      setCustomerId(props.customerId);
    else
      setCustomerId(Number(customerIdParam));
  }, [customerIdParam, props.customerId]);

  useEffect(() => {
    if (customerId > 0)
      fetchProfile();
  }, [customerId, fetchProfile]);

  const saveAsyncProfile = async (value: Partial<CustomerType>): Promise<void> => {
    let errorMsg = '';
    const shouldReload = !!value.Address;
    const newProfile: CustomerType = Object.assign({}, profile.Customer, value);
    await fetchApi(`/api/Customer/Customer/${customerId}`, newProfile, 'PUT')
      .then((response: JsonResponse) => {
        if (response.Success === false) {
          errorMsg = response.ErrorMessage;
        } else {
          updateProfileState(value);
          if (shouldReload) fetchProfile();
        }
      }).catch((e) => {
        if (e?.status) {
          return Promise.reject(e);
        } else {
          errorMsg = "Unknown Error Occurred!";
        }
      });
    if (errorMsg.length) {
      alert(errorMsg);
      return Promise.reject(errorMsg);
    }
    return Promise.resolve();
  }

  const updateProfileState = (value: Partial<CustomerType>): CustomerType => {
    const newProfile: CustomerType = Object.assign({}, profile.Customer, value);
    setProfile({ ...profile, Customer: { ...newProfile, ModNum: newProfile.ModNum + 1 } });
    return newProfile;
  }

  const dataView = () => {
    if (loading) {
      return <div className="k-pos-absolute k-top-center mt-5">
        <Loader type="converging-spinner" />
      </div>;
    }

    if (!profile) return <NoContentView />;

    return <>
      {props.isDialog !== true && customerId > 0 && <CustomerNavBar id={customerId} currentTab="Profile" />}
      <Title string={profile?.Customer?.CustomerNumber} />
      <div className="row m-2">
        <div className="col-md-5">
          <div className="form-group row">
            <label htmlFor="CustomerNumber" className="col-sm-2 col-form-label">Customer ID</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 15 }}
                data={profile.Customer.CustomerNumber}
                save={async (data) => await saveAsyncProfile({ CustomerNumber: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="CustomerName" className="col-sm-2 col-form-label">Customer Name</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 60 }}
                data={profile.Customer.CustomerName}
                save={async (data) => await saveAsyncProfile({ CustomerName: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="Address" className="col-sm-2 col-form-label">Address</label>
            <div className="col-sm-10">
              <EditableAddress
                title="Address"
                addressLineLength={50}
                data={profile.Customer.Address}
                save={async (data) => await saveAsyncProfile({ Address: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="LatitudeLongitude" className="col-sm-2 col-form-label">Coordinates</label>
            <div className="col-sm-10">
              <EditableField
                readOnly={true}
                renderDisplay={(data) => `${data.Latitude}, ${data.Longitude}`}
                open={() => window.open(`https://www.google.com/maps/place/${profile.Customer.Address.Coordinates.Latitude},${profile.Customer.Address.Coordinates.Longitude}/@16z/data=!3m1!1e3`, '_blank')}
                dataType="string"
                data={profile.Customer.Address.Coordinates}
                save={() => Promise.resolve()}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="PhoneNumber" className="col-sm-2 col-form-label">Phone Number</label>
            <div className="col-sm-10">
              <EditableField
                dataType="phone"
                data={profile.Customer.PhoneNumber ?? ""}
                save={async (data) => await saveAsyncProfile({ PhoneNumber: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="PhoneNumber" className="col-sm-2 col-form-label">Local Time</label>
            <div className="col-sm-10">
              <EditableField
                readOnly={true}
                dataType="string"
                data={profile.Customer.LocalIanaTimeZoneID}
                save={() => Promise.resolve()}
                renderDisplay={() => `${CurrentLocalTimeDisplay(profile.Customer.LocalIanaTimeZoneID)} - ${profile.Customer.LocalTimeZoneID}`}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="DedicatedEmail" className="col-sm-2 col-form-label">Dedicated Email</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ type: 'email', maxLength: 80 }}
                data={profile.Customer.DedicatedEmail}
                save={async (data) => await saveAsyncProfile({ DedicatedEmail: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="OnboardingDateTime" className="col-sm-2 col-form-label">Onboarded On</label>
            <div className="col-sm-10">
              <EditableField
                renderDisplay={(data) => (data != null ? Moment.utc(data).tz("America/New_York").format("MM/DD/YYYY") : "N/A")}
                dataType="date"
                data={profile.Customer.OnboardingDateTime != null ? Moment.utc(profile.Customer.OnboardingDateTime).tz("America/New_York").toDate() : null}
                save={async (data) => await saveAsyncProfile({ OnboardingDateTime: data.toISOString() })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="AuditDate" className="col-sm-2 col-form-label">Audited On</label>
            <div className="col-sm-10">
              <EditableField
                renderDisplay={(data) => (data != null ? Moment.utc(data).tz("America/New_York").format("MM/DD/YYYY") : "N/A")}
                dataType="date"
                data={profile.Customer.AuditDate != null ? Moment.utc(profile.Customer.AuditDate).tz("America/New_York").toDate() : null}
                save={async (data) => await saveAsyncProfile({ AuditDate: data.toISOString() })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="ClosedDate" className="col-sm-2 col-form-label">Closed On</label>
            <div className="col-sm-10">
              <EditableField
                renderDisplay={(data) => (data != null ? Moment.utc(data).tz("America/New_York").format("MM/DD/YYYY") : "N/A")}
                dataType="date"
                data={profile.Customer.ClosedDate != null ? Moment.utc(profile.Customer.ClosedDate).tz("America/New_York").toDate() : null}
                save={async (data) => await saveAsyncProfile({ ClosedDate: data.toISOString() })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="TrackingPartners" className="col-sm-2 col-form-label">{profile.TrackingPartnerList.find(x => x.ID === 0)?.Name}</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Customer.TrackingPartners}
                renderDisplay={(data) =>
                  <div className="row">
                    {data?.map((trackingPartnervalue) => (
                      <Chip className="ml-1" text={profile.TrackingPartnerList.find(x => x.ID === trackingPartnervalue).Name} />
                    ))}
                  </div>
                }
                renderEditable={(data, saving, onChange) =>
                  <MultiSelect
                    disabled={saving}
                    textField="Name"
                    dataItemKey="ID"
                    className="k-dropdown-wrap-append"
                    data={profile.TrackingPartnerList.filter(x => x.ID > 0)}
                    value={profile.TrackingPartnerList.filter(x => data?.includes(x.ID))}
                    onChange={(e) => {
                      onChange((e.value as any[]).map(x => x.ID));
                    }}
                  />
                }
                save={async (data) => await saveAsyncProfile({ TrackingPartners: data })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="InvoiceIntegrationTypes" className="col-sm-2 col-form-label">{profile.InvoiceIntegrationList.find(x => x.ID === 0)?.Name}</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Customer.InvoiceIntegrationTypes}
                renderDisplay={(data) =>
                  <div className="row">
                    {data?.map((invoiceIntegrationValue) => (
                      <Chip className="ml-1" text={profile.InvoiceIntegrationList.find(x => x.ID === invoiceIntegrationValue).Name} />
                    ))}
                  </div>
                }
                renderEditable={(data, saving, onChange) =>
                  <MultiSelect
                    disabled={saving}
                    textField="Name"
                    dataItemKey="ID"
                    className="k-dropdown-wrap-append"
                    data={profile.InvoiceIntegrationList.filter(x => x.ID > 0)}
                    value={profile.InvoiceIntegrationList.filter(x => data?.includes(x.ID))}
                    onChange={(e) => {
                      onChange((e.value as any[]).map(x => x.ID));
                    }}
                  />
                }
                save={async (data) => await saveAsyncProfile({ InvoiceIntegrationTypes: data })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="MaxMinutesEarlyArrivalAs" className="col-sm-2 col-form-label">Early Arrival Prevented When</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Customer.MaxMinutesEarlyArrivalAs}
                renderDisplay={(data) =>
                  <div className="row">
                    {data?.map((maxMinutesEarlyArrivalAsValue) => (
                      <Chip className="ml-1" text={MaxMinutesEarlyArrivalAs.find(x => x.id === maxMinutesEarlyArrivalAsValue).text} />
                    ))}
                  </div>
                }
                renderEditable={(data, saving, onChange) =>
                  <MultiSelect
                    disabled={saving}
                    textField="text"
                    dataItemKey="id"
                    className="k-dropdown-wrap-append"
                    data={MaxMinutesEarlyArrivalAs}
                    value={MaxMinutesEarlyArrivalAs.filter(x => data?.includes(x.id))}
                    onChange={(e) => {
                      onChange((e.value as any[]).map(x => x.id));
                    }}
                  />
                }
                save={async (data) => await saveAsyncProfile({ MaxMinutesEarlyArrivalAs: data })}
              />
            </div>
          </div>
          {profile.Customer.MaxMinutesEarlyArrivalAs.length > 0 && <div className="form-group row">
            <label htmlFor="MaxMinutesEarlyArrival" className="col-sm-2 col-form-label">Max Minutes Early Arrival</label>
            <div className="col-sm-10">
              <EditableField
                dataType="number"
                inputProps={{ min: 1, max: 999, format: '0', placeholder: 'number of minutes' }}
                data={profile.Customer.MaxMinutesEarlyArrival}
                save={async (data) => await saveAsyncProfile({ MaxMinutesEarlyArrival: data })}
              />
            </div>
          </div>}

          <div className="form-group row">
            <label htmlFor="SmartbidEnabled" className="col-sm-2 col-form-label">Price Optimization Enabled</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Customer.SmartbidEnabled}
                save={async (data) => await saveAsyncProfile({ SmartbidEnabled: data })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="AllowLoadBrokering" className="col-sm-2 col-form-label">Allows Brokering</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Customer.CanBrokerOrders}
                renderDisplay={(data) => profile.CanBrokerOrdersList.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {profile.CanBrokerOrdersList.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ CanBrokerOrders: data })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="WebsiteUrl" className="col-sm-2 col-form-label">Web Address</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 80, placeholder: 'https://...' }}
                data={profile.Customer.WebsiteUrl}
                save={async (data) => await saveAsyncProfile({ WebsiteUrl: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="CustomerType1" className="col-sm-2 col-form-label">Customer Type</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Customer.CustomerType1}
                renderDisplay={(data) => profile.CustomerType1List.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {profile.CustomerType1List.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ CustomerType1: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="LocationType" className="col-sm-2 col-form-label">Location Type</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Customer.LocationType}
                renderDisplay={(data) => profile.LocationTypeList.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {profile.LocationTypeList.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ LocationType: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="IndustryVertical" className="col-sm-2 col-form-label">Industry Vertical</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Customer.IndustryVertical}
                renderDisplay={(data) => profile.IndustryVerticalList.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {profile.IndustryVerticalList.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ IndustryVertical: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="PlantCode" className="col-sm-2 col-form-label">Plant Code</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 12 }}
                data={profile.Customer.PlantCode}
                save={async (data) => await saveAsyncProfile({ PlantCode: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="PCMilerVersion" className="col-sm-2 col-form-label">PC*Miler Version</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Customer.PCMilerVersion}
                renderDisplay={(data) => profile.PCMilerVersions.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {profile.PCMilerVersions.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ PCMilerVersion: data })}
              />
            </div>
          </div>
          {profile.Customer.CanBrokerOrders !== 0 && <>
            <h2>Excluded Carriers</h2>
            <Grid
              data={profile.Customer.CarrierExclusions}
              scrollable="none"
            >
              <GridToolbar>
                <Button
                  themeColor="primary"
                  icon="plus"
                  svgIcon={plusIcon}
                  onClick={() => setShowCarrierExclusionPopup(true)}
                >
                  Add Carrier
                </Button>
              </GridToolbar>
              <GridNoRecords>
                No Excluded Carriers.
              </GridNoRecords>
              <Column field="CarrierName" title="Carrier" />
              <Column field="ReasonForExclusion" title="Reason" />
              <Column field="ModifiedDateTime" title="Excluded On" cell={ModifiedCell} />
              <Column title=" " width={48} cell={(props) => (
                <td>
                  <Button
                    themeColor="error"
                    svgIcon={xIcon}
                    onClick={() => _removeCarrierExclusion(props.dataItem.CarrierID)}
                  />
                </td>
              )} />
            </Grid>
          </>}
          {showCarrierExclusionPopup && <CarrierExclusionPopup
            customerId={customerId}
            close={() => setShowCarrierExclusionPopup(false)}
            save={(newData) => _addCarrierExclusion(newData)}
          />}
        </div>

        <div className="col-md-7">
          <h2>Sales Information</h2>
          <div className="form-group row">
            <label htmlFor="Salesperson1" className="col-sm-2 col-form-label">Salesperson 1</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Customer.Salesperson1}
                renderDisplay={(data) => (data != null ? GetSalespersonAutoCompleteDisplayValue(data) : "None")}
                renderEditable={(data, saving, onChange) =>
                  <SalespersonAutoComplete
                    disabled={saving}
                    placeholder='type an id, name or leave blank to remove salesperson'
                    style={{ flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    selectedSalespersonID={profile.Customer.Salesperson1?.SalespersonID}
                    setFocus={true}
                    onSelectedSalesperson={(e) => onChange(e)}
                  />}
                save={async (data) => {
                  if (data == null && window.confirm("Remove salesperson 1?") == false) {
                    return Promise.resolve();
                  }
                  await saveAsyncProfile({ Salesperson1: data });
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="Salesperson2" className="col-sm-2 col-form-label">Salesperson 2</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Customer.Salesperson2}
                renderDisplay={(data) => (data != null ? GetSalespersonAutoCompleteDisplayValue(data) : "None")}
                renderEditable={(data, saving, onChange) =>
                  <SalespersonAutoComplete
                    disabled={saving}
                    placeholder='type an id, name or leave blank to remove salesperson'
                    style={{ flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    selectedSalespersonID={profile.Customer.Salesperson2?.SalespersonID}
                    setFocus={true}
                    onSelectedSalesperson={(e) => onChange(e)}
                  />}
                save={async (data) => {
                  if (data == null && window.confirm("Remove salesperson 2?") == false) {
                    return Promise.resolve();
                  }

                  await saveAsyncProfile({ Salesperson2: data });
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="Salesperson3" className="col-sm-2 col-form-label">Salesperson 3</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Customer.Salesperson3}
                renderDisplay={(data) => (data != null ? GetSalespersonAutoCompleteDisplayValue(data) : "None")}
                renderEditable={(data, saving, onChange) =>
                  <SalespersonAutoComplete
                    disabled={saving}
                    placeholder='type an id, name or leave blank to remove salesperson'
                    style={{ flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    selectedSalespersonID={profile.Customer.Salesperson3?.SalespersonID}
                    setFocus={true}
                    onSelectedSalesperson={(e) => onChange(e)}
                  />}
                save={async (data) => {
                  if (data == null && window.confirm("Remove salesperson 3?") == false) {
                    return Promise.resolve();
                  }

                  await saveAsyncProfile({ Salesperson3: data });
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="PreviousSalesperson1" className="col-sm-2 col-form-label">Previous SP1</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Customer.PreviousSalesperson1}
                renderDisplay={(data) => (data != null ? GetSalespersonAutoCompleteDisplayValue(data) : "None")}
                renderEditable={(data, saving, onChange) =>
                  <SalespersonAutoComplete
                    includeRecentlyRetiredSalespersons
                    disabled={saving}
                    placeholder='type an id, name or leave blank to remove salesperson'
                    style={{ flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    selectedSalespersonID={profile.Customer.PreviousSalesperson1?.SalespersonID}
                    setFocus={true}
                    onSelectedSalesperson={(e) => onChange(e)}
                  />}
                save={async (data) => {
                  if (data == null && window.confirm("Remove previous salesperson?") == false) {
                    return Promise.resolve();
                  }

                  await saveAsyncProfile({ PreviousSalesperson1: data });
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="PreviousSalesperson2" className="col-sm-2 col-form-label">Previous SP2</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Customer.PreviousSalesperson2}
                renderDisplay={(data) => (data != null ? GetSalespersonAutoCompleteDisplayValue(data) : "None")}
                renderEditable={(data, saving, onChange) =>
                  <SalespersonAutoComplete
                    includeRecentlyRetiredSalespersons
                    disabled={saving}
                    placeholder='type an id, name or leave blank to remove salesperson'
                    style={{ flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    selectedSalespersonID={profile.Customer.PreviousSalesperson2?.SalespersonID}
                    setFocus={true}
                    onSelectedSalesperson={(e) => onChange(e)}
                  />}
                save={async (data) => {
                  if (data == null && window.confirm("Remove previous salesperson?") == false) {
                    return Promise.resolve();
                  }

                  await saveAsyncProfile({ PreviousSalesperson2: data });
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="ParentCustomer" className="col-sm-2 col-form-label">Parent Customer</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Customer.ParentCustomer}
                open={profile.Customer.ParentCustomer ? () => history.push(`/Customers/Customer/${profile.Customer.ParentCustomer?.CustomerID}`) : undefined}
                renderDisplay={(data) => (data != null ? GetCustomerAutoCompleteDisplayValue(data) : "None")}
                renderEditable={(data, saving, onChange) =>
                  <CustomerAutoComplete
                    disabled={saving}
                    placeholder='type an id, name or leave blank to remove customer'
                    style={{ flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    selectedCustomerID={profile.Customer.ParentCustomer?.CustomerID}
                    setFocus={true}
                    onSelectedCustomer={(e) => onChange(e)}
                  />}
                save={async (data) => {
                  if (data == null && window.confirm("Remove customer?") == false) {
                    return Promise.resolve();
                  }

                  await saveAsyncProfile({ ParentCustomer: data });
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="EDIParentCustomer" className="col-sm-2 col-form-label">EDI Parent Customer</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Customer.EDIParentCustomer}
                open={profile.Customer.EDIParentCustomer && profile.Customer.EDIParentCustomer.CustomerID != customerId ? () => history.push(`/Customers/Customer/${profile.Customer.EDIParentCustomer?.CustomerID}`) : undefined}
                renderDisplay={(data) => (data != null ? GetCustomerAutoCompleteDisplayValue(data) : "None")}
                renderEditable={(data, saving, onChange) =>
                  <CustomerAutoComplete
                    disabled={saving}
                    placeholder='type an id, name or leave blank to remove customer'
                    style={{ flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    selectedCustomerID={profile.Customer.EDIParentCustomer?.CustomerID}
                    setFocus={true}
                    onSelectedCustomer={(e) => onChange(e)}
                  />}
                save={async (data) => {
                  if (data == null && window.confirm("Remove customer?") == false) {
                    return Promise.resolve();
                  }

                  await saveAsyncProfile({ EDIParentCustomer: data });
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="NationalCustomer" className="col-sm-2 col-form-label">National Customer</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Customer.NationalCustomer}
                open={profile.Customer.NationalCustomer && profile.Customer.NationalCustomer.CustomerID != customerId ? () => history.push(`/Customers/Customer/${profile.Customer.NationalCustomer?.CustomerID}`) : undefined}
                renderDisplay={(data) => (data != null ? GetCustomerAutoCompleteDisplayValue(data) : "None")}
                renderEditable={(data, saving, onChange) =>
                  <CustomerAutoComplete
                    disabled={saving}
                    placeholder='type an id, name or leave blank to remove customer'
                    style={{ flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    selectedCustomerID={profile.Customer.NationalCustomer?.CustomerID}
                    setFocus={true}
                    onSelectedCustomer={(e) => onChange(e)}
                  />}
                save={async (data) => {
                  if (data == null && window.confirm("Remove customer?") == false) {
                    return Promise.resolve();
                  }

                  await saveAsyncProfile({ NationalCustomer: data });
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="NationalCustomer" className="col-sm-2 col-form-label">Team (SIC Code)</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                data={profile.Customer.TeamName}
                save={async (data) => await saveAsyncProfile({ TeamName: data })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="FirstTradeDate" className="col-sm-2 col-form-label">First Trade Date</label>
            <div className="col-sm-10">
              <EditableField
                readOnly
                renderDisplay={(data) => (data != null ? Moment.utc(data).tz("America/New_York").format("MM/DD/YYYY") : "N/A")}
                dataType="date"
                data={profile.Customer.FirstTradeDate != null ? Moment.utc(profile.Customer.FirstTradeDate).tz("America/New_York").toDate() : null}
                save={() => Promise.resolve()}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="MostRecentTradeDate" className="col-sm-2 col-form-label">Most Recent Trade Date</label>
            <div className="col-sm-10">
              <EditableField
                readOnly
                renderDisplay={(data) => (data != null ? Moment.utc(data).tz("America/New_York").format("MM/DD/YYYY") : "N/A")}
                dataType="date"
                data={profile.Customer.MostRecentTradeDate != null ? Moment.utc(profile.Customer.MostRecentTradeDate).tz("America/New_York").toDate() : null}
                save={() => Promise.resolve()}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="AccountStatus" className="col-sm-2 col-form-label">Account Status</label>
            <div className="col-sm-10">
              <EditableField
                readOnly
                data={profile.Customer.AccountStatus}
                renderDisplay={(data) => data != null ? profile.AccountStatusList.find(x => x.ID == data).Name : '-'}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {profile.AccountStatusList.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ AccountStatus: data })}
              />
            </div>
          </div>

          <h2>Credit Information</h2>

          <div className="form-group row">
            <label htmlFor="CreditWarning" className="col-sm-2 col-form-label">GP Credit Warning</label>
            <div className="col-sm-10">
              <EditableField
                readOnly
                data={profile.Customer.CreditWarning}
                renderDisplay={(data) => profile.CreditWarningList.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {profile.CreditWarningList.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ CreditWarning: data })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="OnCreditHold" className="col-sm-2 col-form-label">GP Credit Hold</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Customer.OnCreditHold}
                save={async (data) => {
                  if (data !== profile.Customer.OnCreditHold) {
                    updateProfileState({ OnCreditHold: data });
                    const reason = await prompt("Enter your reason for the change");
                    if (reason == null)
                      updateProfileState({ OnCreditHold: !data });
                    else {
                      await saveAsyncProfile({ OnCreditHold: data, ChangeReason: reason });
                    }
                  }
                }}
                renderDisplay={(data) => data ? <span className='text-danger'>Yes</span> : <span>No</span>}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="CreditLimit" className="col-sm-2 col-form-label">GP Credit Limit</label>
            <div className="col-sm-10">
              <EditableField
                readOnly={profile.Customer.OnCreditHold}
                dataType="number"
                inputProps={{ min: 0, format: 'C2' }}
                data={profile.Customer.CreditLimit}
                save={async (data) => await saveAsyncProfile({ CreditLimit: data })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="ERPBalance" className="col-sm-2 col-form-label">GP Outstanding Balance</label>
            <div className="col-sm-10">
              <EditableField
                readOnly
                dataType="number"
                inputProps={{ min: 0, format: 'C2' }}
                data={profile.Customer.ERPBalance ?? 0}
                save={async (data) => await saveAsyncProfile({ ERPBalance: data })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="UnpostedRevenue" className="col-sm-2 col-form-label">Transcend Unposted Revenue</label>
            <div className="col-sm-10">
              <EditableField
                readOnly
                dataType="number"
                inputProps={{ min: 0, format: 'C2' }}
                data={profile.Customer.UnpostedRevenue}
                save={async (data) => await saveAsyncProfile({ UnpostedRevenue: data })}
              />
            </div>
          </div>

          <Tooltip anchorElement="target" position="bottom">
            <div className="form-group row">
              <label htmlFor="CreditAvailable" className="col-sm-2 col-form-label" title='Available Credit = GP.CreditLimit - GP.OutstandingBalance - TMS.UnpostedRevenue'>Available Credit</label>
              <div className="col-sm-10">
                <EditableField
                  readOnly
                  dataType="number"
                  inputProps={{ min: 0, format: 'C2' }}
                  data={profile.Customer.OnCreditHold ? 0 : profile.Customer.CreditAvailable}
                  save={() => Promise.resolve()}
                />
              </div>
            </div>
          </Tooltip>

          <div className="form-group row">
            <label htmlFor="LastPaymentDate" className="col-sm-2 col-form-label">GP Last Payment Date</label>
            <div className="col-sm-10">
              <EditableField
                readOnly
                renderDisplay={(data) => (data != null ? Moment.utc(data).tz("America/New_York").format("MM/DD/YYYY") : "N/A")}
                dataType="date"
                data={profile.Customer.LastPaymentDate != null ? Moment.utc(profile.Customer.LastPaymentDate).tz("America/New_York").toDate() : null}
                save={() => Promise.resolve()}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="AverageDaysToPayYear" className="col-sm-2 col-form-label">GP Average Days to Pay - YTD</label>
            <div className="col-sm-10">
              <EditableField
                readOnly={true}
                dataType="number"
                inputProps={{ min: 0, max: 999, format: '0' }}
                data={profile.Customer.AverageDaysToPayYear}
                save={async (data) => await saveAsyncProfile({ AverageDaysToPayYear: data })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="AverageDaysToPayLife" className="col-sm-2 col-form-label">GP Average Days to Pay - Life</label>
            <div className="col-sm-10">
              <EditableField
                readOnly={true}
                dataType="number"
                inputProps={{ min: 0, max: 999, format: '0' }}
                data={profile.Customer.AverageDaysToPayLife}
                save={async (data) => await saveAsyncProfile({ AverageDaysToPayLife: data })}
              />
            </div>
          </div>

          <h2>Billing Information</h2>

          <div className="form-group row">
            <label htmlFor="CustomerERPName" className="col-sm-2 col-form-label">ERP Name</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 60 }}
                data={profile.Customer.CustomerERPName}
                save={async (data) => await saveAsyncProfile({ CustomerERPName: data })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="PrintInvoices" className="col-sm-2 col-form-label">Print Invoices</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Customer.PrintInvoices}
                save={async (data) => await saveAsyncProfile({ PrintInvoices: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="EmailInvoices" className="col-sm-2 col-form-label">Email Invoices</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Customer.EmailInvoices}
                save={async (data) => await saveAsyncProfile({ EmailInvoices: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="FinalCustomer" className="col-sm-2 col-form-label">Final/Mailing Customer</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Customer.FinalCustomer}
                open={profile.Customer.FinalCustomer && profile.Customer.FinalCustomer.CustomerID != customerId ? () => history.push(`/Customers/Customer/${profile.Customer.FinalCustomer?.CustomerID}`) : undefined}
                renderDisplay={(data) => (data != null ? GetCustomerAutoCompleteDisplayValue(data) : "None")}
                renderEditable={(data, saving, onChange) =>
                  <CustomerAutoComplete
                    disabled={saving}
                    placeholder='type an id, name or leave blank to remove customer'
                    style={{ flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    selectedCustomerID={profile.Customer.FinalCustomer?.CustomerID}
                    onSelectedCustomer={(e) => onChange(e)}
                  />}
                save={async (data) => {
                  if (data == null && window.confirm("Remove customer?") == false) {
                    return Promise.resolve();
                  }

                  await saveAsyncProfile({ FinalCustomer: data });
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="RatingCustomer" className="col-sm-2 col-form-label">Rating Customer</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Customer.RatingCustomer}
                open={profile.Customer.RatingCustomer && profile.Customer.RatingCustomer.CustomerID != customerId ? () => history.push(`/Customers/Customer/${profile.Customer.RatingCustomer?.CustomerID}`) : undefined}
                renderDisplay={(data) => (data != null ? GetCustomerAutoCompleteDisplayValue(data) : "None")}
                renderEditable={(data, saving, onChange) =>
                  <CustomerAutoComplete
                    disabled={saving}
                    placeholder='type an id, name or leave blank to remove customer'
                    style={{ flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    selectedCustomerID={profile.Customer.RatingCustomer?.CustomerID}
                    onSelectedCustomer={(e) => onChange(e)}
                  />}
                save={async (data) => {
                  if (data == null && window.confirm("Remove customer?") == false) {
                    return Promise.resolve();
                  }

                  await saveAsyncProfile({ RatingCustomer: data });
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="FuelSurcharge" className="col-sm-2 col-form-label">Fuel Surcharge</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Customer.FuelSurcharge}
                renderDisplay={(data) => profile.FuelSurchargeList.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {profile.FuelSurchargeList.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ FuelSurcharge: data })}
              />
            </div>
          </div>

          {profile.Customer.FuelSurcharge !== FuelSurcharge.DoNotCalculateFSC && <div className="form-group row">
            <label htmlFor="SkipFuelCalculationOnMinimums" className="col-sm-2 col-form-label">Skip FSC calculations on minimums</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Customer.SkipFuelCalculationOnMinimums}
                save={async (data) => await saveAsyncProfile({ SkipFuelCalculationOnMinimums: data })}
              />
            </div>
          </div>}

          <div className="form-group row">
            <label htmlFor="MailingAddress" className="col-sm-2 col-form-label">Mailing Address</label>
            <div className="col-sm-10">
              <EditableAddress
                mailingAddress
                addressLineLength={50}
                title="Mailing Address Override"
                data={profile.Customer.MailingAddress ?? {
                  Name: profile.Customer.CustomerName,
                  AddressLine1: profile.Customer.Address.AddressLine1,
                  AddressLine2: profile.Customer.Address.AddressLine2,
                  City: profile.Customer.Address.City,
                  State: profile.Customer.Address.State,
                  ZipCode: profile.Customer.Address.ZipCode,
                  Coordinates: { Latitude: 0, Longitude: 0 }
                }}
                save={async (data) => await saveAsyncProfile({ MailingAddress: { ...data, AddressType: AddressType.MailingAddress } })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="BillingAddress" className="col-sm-2 col-form-label">Billing Address</label>
            <div className="col-sm-10">
              <EditableAddress
                mailingAddress
                addressLineLength={50}
                title="Billing Address Override"
                data={profile.Customer.BillingAddress ?? {
                  Name: profile.Customer.CustomerName,
                  AddressLine1: profile.Customer.Address.AddressLine1,
                  AddressLine2: profile.Customer.Address.AddressLine2,
                  City: profile.Customer.Address.City,
                  State: profile.Customer.Address.State,
                  ZipCode: profile.Customer.Address.ZipCode,
                  Coordinates: { Latitude: 0, Longitude: 0 }
                }}
                save={async (data) => await saveAsyncProfile({ BillingAddress: { ...data, AddressType: AddressType.BillingAddress } })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="BillingTerms" className="col-md-2 col-form-label">GP Billing Terms</label>
            <div className="col-md-10">
              <EditableField
                readOnly
                data={profile.Customer.BillingTerms}
                renderDisplay={(data) => data > 0 ? `NET ${data}` : 'Due on Receipt'}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  <option value={0}>Due on Receipt</option>
                  <option value={7}>Weekly</option>
                  <option value={30}>NET 30</option>
                  <option value={45}>NET 45</option>
                  <option value={60}>NET 60</option>
                  <option value={75}>NET 75</option>
                  <option value={90}>NET 90</option>
                </select>}
                save={async (data) => await saveAsyncProfile({ BillingTerms: data })}
              />
            </div>
          </div>
          <br />
          <AuditInfo audit={profile.Customer.AuditInfo} />
          <br />

        </div>
      </div>
      <PromptDialog />
    </>
  };

  if (props.isDialog) {
    return <Dialog
      className="dialog-w11/12"
      title={<Link to={`/Customers/Customer/${props.customerId}`}>Customer Profile {profile?.Customer.CustomerName ?? ''}</Link>}
      onClose={() => {
        props.onClose();
      }}
    >
      {dataView()}
    </Dialog>
  }

  return dataView();
}

export default CustomerProfile;