import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { Trip } from '.';
import { openWindowPerc } from '../../services/openWindow';
import { ILink } from '../../types/link';
import CarrierProfile from '../AssetCarriers/CarrierProfile';
import TrailerProfile from '../AssetTrailers/Profile';
import VehicleProfile from '../AssetVehicles/Profile';
import CarrierTripParameters from './CarrierTripParameters';
import PlannedOrdersVehicle from './PlannedOrdersVehicle';

const AssetCell = (props: GridCellProps) => {
  const [showVehicleProfileDialog, setShowVehicleProfileDialog] = React.useState(false);
  const [showCarrierProfileDialog, setShowCarrierProfileDialog] = React.useState(false);
  const [showTrailerProfileDialog, setShowTrailerProfileDialog] = React.useState(false);
  const [showCarrierTripParametersDialog, setShowCarrierTripParametersDialog] = React.useState(false);
  const [showPlannedOrdersVehicle, setShowPlannedOrdersVehicle] = React.useState(false);

  const trip = props.dataItem as Trip;

  if (trip.IsBrokered) {
    return (
      <td>
        {showCarrierProfileDialog && <CarrierProfile carrierId={trip.AssetID} isDialog={true} onClose={() => setShowCarrierProfileDialog(false)} />}
        <a
          href="#"
          style={{ color: '#007bff' }}
          onClick={(e) => {
            window.isTMS ? setShowCarrierProfileDialog(true) : openWindowPerc(trip.Links.find((x: ILink) => x.Name === 'CarrierProfile').Link);
            e.preventDefault();
          }}
        >
          {trip.AssetName}
        </a>
        <br />

        {showCarrierTripParametersDialog && <CarrierTripParameters tripId={trip.TripID} onClose={() => setShowCarrierTripParametersDialog(false)} />}
        <a
          href="#"
          style={{ color: '#007bff' }}
          onClick={(e) => {
            setShowCarrierTripParametersDialog(true);
            e.preventDefault();
          }}
        >
          {trip.VehicleTypeName}
        </a>

        {trip.TrailerName && <><br />
          {trip.TrailerName}
        </>}
      </td >
    );
  }

  return (
    <td>
      {showVehicleProfileDialog && <VehicleProfile vehicleId={trip.AssetID} isDialog={true} onClose={() => setShowVehicleProfileDialog(false)} />}
      {showTrailerProfileDialog && <TrailerProfile trailerId={trip.TrailerID} isDialog={true} onClose={() => setShowTrailerProfileDialog(false)} />}
      {showPlannedOrdersVehicle && <PlannedOrdersVehicle vehicleId={trip.AssetID} onClose={() => setShowPlannedOrdersVehicle(false)} />}
      <a
        href="#"
        style={{ color: '#007bff' }}
        onClick={(e) => {
          setShowVehicleProfileDialog(true);
          e.preventDefault();
        }}
      >
        {trip.AssetName}
      </a> &nbsp;
      {trip.Links.find((x: ILink) => x.Name === 'VehiclePlanned') && <a
        href="#"
        style={{ color: '#007bff' }}
        onClick={(e) => {
          setShowPlannedOrdersVehicle(true);
          e.preventDefault();
        }}
      >
        Planned
      </a>}

      {trip.TrailerName &&
        <>
          <br />
          <a
            href="#"
            style={{ color: '#007bff' }}
            onClick={(e) => {
              window.isTMS ? setShowTrailerProfileDialog(true) : openWindowPerc(trip.Links.find((x: ILink) => x.Name === 'TrailerProfile').Link);
              e.preventDefault();
            }}
          >
            {trip.TrailerName}
          </a>
        </>}

      <br />
      {trip.VehicleTypeName}
    </td>
  );
}
export default React.memo(AssetCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);