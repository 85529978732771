import { DropDownButton } from '@progress/kendo-react-buttons';
import { SvgIcon } from "@progress/kendo-react-common";
import { infoCircleIcon } from '@progress/kendo-svg-icons';
import LoadingPanel from "components/LoadingPanel";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { fetchApi } from "services/api";
import { JsonResponse } from 'TypeGen/json-response';
import { OrderBillingNote } from 'TypeGen/Order/OrderNotes/order-billing-note';
import { OrderGeneralNote } from 'TypeGen/Order/OrderNotes/order-general-note';
import { OrderStopNote } from 'TypeGen/Order/OrderNotes/order-stop-note';
import { ViewModel } from "TypeGen/Order/OrderNotes/view-model";
import NoteEditUI from '../Notes/Edit';

type RouteComponentParams = {
  orderId: string;
};

type Props = {
  children?: React.ReactNode
};

const OrderNotes: React.FC<Props> = () => {
  const { orderId: orderIdParam } = useParams<RouteComponentParams>();
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState<ViewModel>();
  const [note, setNote] = useState<OrderBillingNote>(undefined);
  const [generalNote, setGeneralNote] = useState<OrderGeneralNote>(undefined);
  const [stopNote, setStopNote] = useState<OrderStopNote>(undefined);

  const refreshOrderAttributes = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Order/OrderNotes/${orderIdParam}`)
      .then((response: ViewModel) => {
        setDetails(response);
        setLoading(false);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [orderIdParam]);

  const newBillingNote = () => {
    setNote({
      OrderNoteID: 0,
      ModifiedDateTime: '',
      ModifiedByFullName: '',
      CarrierVisible: false,
      InvoiceVisible: false,
      TurnoverVisible: false,
      AcknowledgedDateTime: null,
      AcknowledgedByFullName: '',
      IsCritical: false,
      Note: ''
    });
  }

  const newGeneralNote = () => {
    setGeneralNote({
      OrderNoteID: 0,
      ModifiedDateTime: '',
      ModifiedByFullName: '',
      CarrierVisible: false,
      CustomerVisible: false,
      TurnoverVisible: false,
      FrontMessageIdVisible: false,
      SentFrontMessageID: null,
      AcknowledgedDateTime: null,
      AcknowledgedByFullName: '',
      IsCritical: false,
      Note: ''
    });
  }

  const addNote = (note: OrderBillingNote) => {
    const link = details.Links.find(l => l.Name === 'AddNote');
    setLoading(true);
    fetchApi(link.Link, note, link.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          setNote(undefined);
          refreshOrderAttributes();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert(e);
      })
      .finally(() => setLoading(false));
  }

  const addGeneralNote = (note: OrderGeneralNote) => {
    const link = details.Links.find(l => l.Name === 'AddGeneralNote');
    setLoading(true);
    fetchApi(link.Link, note, link.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          setGeneralNote(undefined);
          refreshOrderAttributes();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert(e);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    refreshOrderAttributes();
  }, [orderIdParam, refreshOrderAttributes]);

  if (loading && !details) {
    return <LoadingPanel />;
  }

  return <div className="mt-3">
    {note && <NoteEditUI
      loading={loading}
      orderNote={note}
      cancel={() => setNote(undefined)}
      noteType="Billing"
      save={addNote}
    />}
    {generalNote && <NoteEditUI
      loading={loading}
      orderNote={generalNote}
      cancel={() => setGeneralNote(undefined)}
      noteType="General"
      save={addGeneralNote}
    />}
    {stopNote && <NoteEditUI
      loading={loading}
      orderNote={stopNote}
      cancel={() => setStopNote(undefined)}
      noteType="Stop"
    />}
    {details.StopNotes.length === 0
      ? <h4 className="text-center">No Stop Notes.</h4>
      : <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Stop #</th>
            <th>Stop Note</th>
            <th>Sharing</th>
            <th>Modified By</th>
            <th>Modified Date</th>
          </tr>
        </thead>
        <tbody>
          {details.StopNotes.map(note => {
            const sharingArray = Array.from([(note.CustomerVisible ? 'Customer' : ''), (note.CarrierVisible ? 'Carrier' : ''), (note.DriverVisible ? 'Driver' : '')]).filter(Boolean);
            if (sharingArray.length === 0) sharingArray.push('None');
            return <tr key={note.OrderNoteID} onClick={() => setStopNote(note)}>
              <td>{note.StopSequence}</td>
              <td>
                {note.IsCritical && <SvgIcon icon={infoCircleIcon} className='text-danger mr-1' />}
                {note.Note}
              </td>
              <td>{sharingArray.join(', ')}</td>
              <td>{note.ModifiedByFullName}</td>
              <td>{moment.utc(note.ModifiedDateTime).local().format('MM/DD/YYYY HH:mm')}</td>
            </tr>
          })}
        </tbody>
      </table>}
    {details.GeneralNotes.length === 0
      ? <h4 className="text-center">No General Notes.</h4>
      : <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>General Note</th>
            <th>Sharing</th>
            <th>Modified By</th>
            <th>Modified Date</th>
          </tr>
        </thead>
        <tbody>
          {details.GeneralNotes.map(note => {
            const sharingArray = Array.from([(note.CarrierVisible ? 'Carrier' : ''), (note.CustomerVisible ? 'Customer' : ''), (note.TurnoverVisible ? 'Turnover' : ''), (note.FrontMessageIdVisible ? 'Front' : '')]).filter(Boolean);
            if (sharingArray.length === 0) sharingArray.push('None');
            return <tr key={note.OrderNoteID} onClick={() => setGeneralNote(note)}>
              <td>
                {note.IsCritical && <SvgIcon icon={infoCircleIcon} className='text-danger mr-1' />}
                {note.Note}
              </td>
              <td>{sharingArray.join(', ')}</td>
              <td>{note.ModifiedByFullName}</td>
              <td>{moment.utc(note.ModifiedDateTime).local().format('MM/DD/YYYY HH:mm')}</td>
            </tr>
          })}
        </tbody>
      </table>
    }
    {details.Notes.length === 0
      ? <h4 className="text-center">No Billing Notes.</h4>
      : <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Billing Note</th>
            <th>Sharing</th>
            <th>Modified By</th>
            <th>Modified Date</th>
          </tr>
        </thead>
        <tbody>
          {details.Notes.map((note, index) => {
            const sharingArray = Array.from([(note.CarrierVisible ? 'Carrier' : ''), (note.InvoiceVisible ? 'Invoice' : ''), (note.TurnoverVisible ? 'Turnover' : '')]).filter(Boolean);
            if (sharingArray.length === 0) sharingArray.push('None');
            return <tr key={index} onClick={() => {
              if (note.OrderNoteID > 0)
                setNote(note)
            }}>
              <td>
                {note.IsCritical && <SvgIcon icon={infoCircleIcon} className='text-danger mr-1' />}
                {note.Note}
              </td>
              <td>{sharingArray.join(', ')}</td>
              <td>{note.ModifiedByFullName}</td>
              <td>{moment.utc(note.ModifiedDateTime).local().format('MM/DD/YYYY HH:mm')}</td>
            </tr>
          })}
        </tbody>
      </table>}
    <div className="text-center mt-3">
      <DropDownButton
        text="Add Note"
        items={[
          { text: "Billing Note", onClick: newBillingNote },
          { text: "General Note", onClick: newGeneralNote }
        ]}
        onItemClick={(e) => e.item.onClick()}
        themeColor={"primary"}
      />
    </div>
  </div>
}

export default OrderNotes;