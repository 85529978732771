import { useCallback, useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import moment from 'moment-timezone';
import { CustomerNavBar } from "./NavBar";
import { fetchApi } from "../../services/api";
import { ILink } from '../../types/link';
import { Loader } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-buttons";
import NoteEditUI, { noteTypes, priorityTypes } from "./NoteEditUI";
import { ViewModel } from "TypeGen/Customer/Notes/view-model";
import { Command } from "TypeGen/Customer/Notes/command";
import { CustomerNoteViewModel } from "TypeGen/Customer/Notes/customer-note-view-model";

type RouteComponentParams = {
  customerId: string;
};

const CustomerNotes = () => {
  const { customerId: customerIdParam } = useParams<RouteComponentParams>();
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState<CustomerNoteViewModel[]>();
  const [links, setLinks] = useState<ILink[]>();
  const [note, setNote] = useState<Command>(undefined);

  const refresh = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Customer/CustomerNotes/${customerIdParam}`)
      .then((data: ViewModel) => {
        setNotes(data.Notes);
        setLinks(data.Links);
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert(e);
      })
      .finally(() => setLoading(false));
  }, [customerIdParam]);

  useEffect(() => {
    refresh();
  }, [customerIdParam, refresh]);

  const addNote = (newNote: Command) => {
    setLoading(true);
    fetchApi(`/api/Customer/AddCustomerNote/${customerIdParam}`, newNote, 'POST')
      .then(() => {
        setLoading(false);
        setNote(undefined);
        refresh();
      })
      .catch((e) => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('An error occurred while saving the note.');
      });
  }

  if (loading && !note) return <div className="k-pos-absolute k-top-center mt-5">
    <Loader type="converging-spinner" />
  </div>;

  return (<>
    <CustomerNavBar id={customerIdParam} currentTab="Notes" />
    <Title string="Customer Notes" />
    <Button
      themeColor="primary"
      className="float-right my-2"
      onClick={() => setNote({
        CustomerContactID: null,
        Priority: 0,
        NoteType: 0,
        Note: ''
      })}
    >
      Add Note
    </Button>
    {note && <NoteEditUI
      loading={loading}
      customerId={customerIdParam}
      orderNote={note}
      cancel={() => setNote(undefined)}
      save={addNote}
    />}
    {notes.length === 0
      ? <h4 className="text-center">No Customer Notes.</h4>
      : <table className="table table-striped">
          <thead>
            <tr>
              <th>Note</th>
              <th>Contact</th>
              <th>Priority</th>
              <th>Type</th>
              <th>By</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {notes.map(note => {
              return <tr key={note.CustomerNoteID}>
                <td>{note.Note}</td>
                <td>{note.CustomerContactName}</td>
                <td>{priorityTypes.find(p => p.ID === note.Priority)?.Name}</td>
                <td>{noteTypes.find(p => p.ID === note.NoteType)?.Name}</td>
                <td>{note.ModifiedByFullName}</td>
                <td>{moment.utc(note.ModifiedDateTime).local().format('MM/DD/YYYY HH:mm')}</td>
              </tr>})}
        </tbody>
    </table>}
  </>);
}

export default CustomerNotes;