import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import Moment from 'moment-timezone';
import { openWindow } from '../../../services/openWindow';
import { ILink } from '../../../types/link';
import { VehicleValue } from '../Vehicles';

// Rate = Linehaul + Accessorial
export default function vehicleOfferCell(
    distance: number,
    rate: number,
    fuel: number,
    bonus: number,
    cancel: (vehicleID: number) => void,
    offer: (vehicle: VehicleValue, allInRate: number, linehaul: number, fuel: number, deadhead: number) => void
) {
    return class extends React.Component<GridCellProps> {
        render() {
            const vehicle = this.props.dataItem as VehicleValue;

            if (vehicle.QuoteOfferStatus == 0) {
                return <td><button className="btn btn-sm btn-info" onClick={() => {
                    cancel(vehicle.VehicleID);
                }}>
                    Cancel
                </button></td>
            }

            if (vehicle.QuoteOfferStatus == 1) {
                return <td><button className="btn btn-sm btn-danger" onClick={(e) => {
                    e.preventDefault();
                    openWindow(`/Assets/Vehicle/${vehicle.VehicleID}/Turndowns`);
                }}>
                    Declined
                </button></td>
            }

            if (vehicle.QuoteOfferStatus == 3) {
                return <td><button className="btn btn-sm btn-warning" onClick={(e) => {
                    e.preventDefault();
                    openWindow(`/Assets/Vehicle/${vehicle.VehicleID}/Turndowns`);
                }}>
                    Expired
                </button></td>
            }

            if (vehicle.QuoteOfferStatus == 2) {
                return <td><span className="badge badge-success" style={{ fontSize: '100%' }}>
                    Accepted
                </span></td>
            }

            if (vehicle.Links.filter((x: ILink) => x.Name === "Offer").length > 0) {
                const fuelAmount = fuel * vehicle.FuelPercentage;
                const linehaulAmount = (rate * vehicle.LinehaulPercentage) + (distance * vehicle.LinehaulRPM);
                const minLinehaulAmount = distance * this.props.dataItem.LinehaulRPMMin;
                const deadheadAmount = Math.max(this.props.dataItem.Distance - this.props.dataItem.FreeDeadheadMiles, 0) * this.props.dataItem.DeadheadRPM;
                const payAmount = vehicle.QuoteOfferAllInRate ? vehicle.QuoteOfferAllInRate : Math.max(linehaulAmount, minLinehaulAmount) + fuelAmount + deadheadAmount + bonus;
                return <td><button className="btn btn-sm btn-default" onClick={() => {
                    if (vehicle.IsReservedByMe && !window.confirm("The reservation you have for this vehicle will be released if they decline.")) {
                        return;
                    }
                    if (vehicle.IsAgainstDestination && !window.confirm(`${vehicle.VehicleNumber} would like to be in ${vehicle.Destination.Location} by ${Moment.utc(vehicle.Destination.DateTime).tz("America/New_York").format("M/D HH:mm")}, still offer?`)) {
                        return;
                    }
                    if (vehicle.HoursOfService && !vehicle.HasHoursRemaining && !window.confirm("Driver may not be have enough hours of service to complete this trip, still offer?")) {
                        return;
                    }

                    offer(vehicle, payAmount, linehaulAmount, fuelAmount, deadheadAmount);
                }}>
                    Offer
                </button></td>
            }

            return <td>
                {vehicle.Links.find((x: ILink) => x.Name === 'DriverProfile') && <button className="btn btn-sm btn-default" style={{ opacity: .65 }} onClick={() => {
                openWindow(vehicle.Links.find((x: ILink) => x.Name === 'DriverProfile').Link);
            }}>
                Offer
            </button>}
            </td>
        }

        public shouldComponentUpdate(nextProps: GridCellProps) {
            return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
        }
    };
}
