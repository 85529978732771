import { Dialog } from "@progress/kendo-react-dialogs";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Loader } from "@progress/kendo-react-indicators";
import Moment from 'moment-timezone';
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import AuditInfo, { AuditInfoViewModel } from "../../components/AuditInfo";
import CenterDivPanel from "../../components/CenterDivPanel";
import { EditableAddress } from "../../components/EditableAddress";
import { EditableField } from "../../components/EditableField";
import { fetchApi } from "../../services/api";
import { AddressViewModel } from '../../types/address';
import { IDName } from "../../types/idname";
import { Title } from '../../utils/title';
import { CarrierNavBar } from "./CarrierNavBar";
import { JsonResponse } from "TypeGen/json-response";
import { EditableIDName } from "components/EditableIDName";
import CustomerInput from "components/CustomerInput";
import { Link } from "react-router-dom";
import { openWindowPerc } from "../../services/openWindow";
import { Chip } from "@progress/kendo-react-buttons";

type Props = {
  isDialog?: boolean
  carrierId?: number;

  onClose?: () => void;
}

type RouteComponentParams = {
  carrierId: string;
};

type CarrierProfileType = {
  Carrier: CarrierType
  ModesOfTransportationList: IDName[];
  CarrierStatusList: IDName[];
  Users: IDName[];
}

type CarrierType = {
  CarrierName: string;
  PhoneNumber: string;
  PhoneNumberExtension: string;
  AfterHoursPhoneNumber: string;
  SCAC: string;
  HasCrossDock: boolean;
  Is247Carrier: boolean;
  EPayCode: number;
  BusinessUnitID: number;
  EVFEnabled: boolean;

  CarrierStatus: number;
  TEANAMember: boolean;
  GeneralNote: string;

  AuthorityOnFile: boolean;
  MCNumber1: string;
  MCNumber2: string;
  USDOT: string;
  CTPATApproved: CarrierCompliance;
  TSAApproved: CarrierCompliance;
  FASTCarrier: CarrierCompliance;
  PIPApproved: CarrierCompliance;
  CSACarrier: CarrierCompliance;
  TankerEndorsement: boolean;
  CarrierAssureScore: number | null;
  CarrierAssureGrade: string;
  Bonded: boolean;
  AuthorityDateIssued: Date;

  AutoNamedInsured: boolean;
  IsRequestedAsHolder: boolean;
  GeneralLiabilityAmount: number;
  CargoInsuranceAmount: number;
  AutoLiabilityAmount: number;
  CargoLiabilityAmount: number;
  AutoHazMatCertified: boolean;
  AutoLiabilityExpiryDate: Date;
  CargoLiabilityExpiryDate: Date;
  AutoInsuredBy: string;
  AutoInsuranceApprovedByUserID: number;

  ModesOfTransportation: number[];

  AccountingName: string;
  IsPOBoxAddress: boolean;
  AccountingEmail: string;
  AccountingPhone: string;
  AccountingFax: string;
  AccountingContact: string;
  FederalTaxpayerID: string;
  VendorID: string;
  BillingCustomerID: number | null;
  BillingCustomerNumber: string;

  Address: AddressViewModel;
  AccountingAddress: AddressViewModel;
  AuditInfo: AuditInfoViewModel;
}

enum CarrierCompliance {
  No = 0,
  Yes = 1,
  Pending = 2
}

const CarrierComplianceOptions = [{ ID: 0, Name: 'No' }, { ID: 1, Name: 'Yes' }, { ID: 2, Name: 'Pending' }];

const CarrierProfile = (props: Props) => {

  const { carrierId: carrierIdParam } = useParams<RouteComponentParams>();
  const [carrierId, setCarrierId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<CarrierProfileType>();

  const fetchProfile = useCallback(() => {
    async function fetchData() {
      setLoading(true);
      await fetchApi(`/api/Asset/Carrier/${carrierId}`)
        .then(data => {
          setProfile(data);
          setLoading(false);
        })
        .catch(e => {
          setLoading(false);
          // If not problem details
          if (!e?.status) alert('Unable to load carrier profile');
        });
    }
    fetchData();
  }, [carrierId]);

  useEffect(() => {
    if (props.carrierId > 0)
      setCarrierId(props.carrierId);
    else
      setCarrierId(Number(carrierIdParam));
  }, [carrierIdParam, props.carrierId]);

  useEffect(() => {
    if (carrierId > 0)
      fetchProfile();
  }, [carrierId, fetchProfile]);

  const updateProfileState = (value: Partial<CarrierType>): CarrierType => {
    const newProfile: CarrierType = Object.assign({}, profile.Carrier, value);
    setProfile({ ...profile, Carrier: newProfile });
    return newProfile;
  }

  const saveAsyncProfile = async (value: Partial<CarrierType>): Promise<void> => {
    let errorMsg = '';
    const newProfile: CarrierType = Object.assign({}, profile.Carrier, value);
    const url_action = value.CarrierStatus ? 'CarrierStatus' : 'Carrier';
    await fetchApi(`/api/Asset/${url_action}/${carrierId}`, newProfile, 'PUT')
      .then((response: JsonResponse) => {
        if (response.Success === false) {
          errorMsg = response.ErrorMessage;
        } else {
          updateProfileState(value);
        }
      }).catch((e) => {
        if (e?.status) {
          return Promise.reject(e);
        } else {
          errorMsg = "Unknown Error Occurred!";
        }
      });
    if (errorMsg.length) {
      alert(errorMsg);
      return Promise.reject(errorMsg);
    }
    return Promise.resolve();
  }

  const dataView = () => {
    if (loading || !profile) {
      return <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>;
    }

    return (<>
      {props.isDialog !== true && carrierId > 0 && <CarrierNavBar id={carrierId} currentTab="Profile" />}
      <Title string={profile?.Carrier?.CarrierName} />
      <div className="row m-2">
        <div className="col-md-5">
          <div className="form-group row">
            <label htmlFor="CarrierName" className="col-sm-2 col-form-label">Carrier Name</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 40 }}
                data={profile.Carrier.CarrierName}
                save={async (data) => saveAsyncProfile({ CarrierName: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="Address" className="col-sm-2 col-form-label">Address</label>
            <div className="col-sm-10">
              <EditableAddress
                title="Address"
                data={profile.Carrier.Address}
                save={async (data) => saveAsyncProfile({ Address: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="PrimaryPhoneNumber" className="col-sm-2 col-form-label">Phone Number</label>
            <div className="col-sm-10">
              <EditableField
                dataType="intlphone"
                data={profile.Carrier.PhoneNumber ?? ""}
                save={async (data) => saveAsyncProfile({ PhoneNumber: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="AfterHoursPhoneNumber" className="col-sm-2 col-form-label">Emergency Phone</label>
            <div className="col-sm-10">
              <EditableField
                dataType="intlphone"
                data={profile.Carrier.AfterHoursPhoneNumber ?? ""}
                save={async (data) => saveAsyncProfile({ AfterHoursPhoneNumber: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="CarrierStatus" className="col-sm-2 col-form-label">Carrier Status</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Carrier.CarrierStatus}
                renderDisplay={(data) => profile.CarrierStatusList.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {profile.CarrierStatusList.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ CarrierStatus: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="GeneralNote" className="col-sm-2 col-form-label">General Note</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 50 }}
                data={profile.Carrier.GeneralNote}
                save={async (data) => saveAsyncProfile({ GeneralNote: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="HasCrossDock" className="col-sm-2 col-form-label">Has Cross Dock</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Carrier.HasCrossDock}
                save={async (data) => await saveAsyncProfile({ HasCrossDock: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="Is247Carrier" className="col-sm-2 col-form-label">Is 24/7 Operation</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Carrier.Is247Carrier}
                save={async (data) => await saveAsyncProfile({ Is247Carrier: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="TEANAMember" className="col-sm-2 col-form-label">TEANA Member</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Carrier.TEANAMember}
                save={async (data) => await saveAsyncProfile({ TEANAMember: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="EPayCode" className="col-sm-2 col-form-label">EPay Code</label>
            <div className="col-sm-10">
              <EditableField
                dataType="number"
                inputProps={{ min: 0, format: '#' }}
                data={profile.Carrier.EPayCode}
                save={async (data) => await saveAsyncProfile({ EPayCode: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="BusinessUnitID" className="col-sm-2 col-form-label">Sylectus Corp ID</label>
            <div className="col-sm-10">
              <EditableField
                dataType="number"
                inputProps={{ min: 475, format: '#', placeholder: 'For EVF' }}
                data={profile.Carrier.BusinessUnitID}
                save={async (data) => await saveAsyncProfile({ BusinessUnitID: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="EVFEnabled" className="col-sm-2 col-form-label">Sylectus EVF</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Carrier.EVFEnabled}
                save={async (data) => await saveAsyncProfile({ EVFEnabled: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="ModesOfTransportation" className="col-sm-2 col-form-label">{profile.ModesOfTransportationList.find(x => x.ID === 0)?.Name}</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Carrier.ModesOfTransportation}
                renderDisplay={(data) =>
                  <div className="row">
                    {profile.ModesOfTransportationList.filter(x => data?.includes(x.ID)).map((option) => (
                      <Chip className="ml-1" text={profile.ModesOfTransportationList.find(x => x.ID === option.ID).Name} />
                    ))}
                  </div>
                }
                renderEditable={(data, saving, onChange) =>
                  <MultiSelect
                    disabled={saving}
                    textField="Name"
                    dataItemKey="ID"
                    className="k-dropdown-wrap-append"
                    data={profile.ModesOfTransportationList.filter(x => x.ID > 0)}
                    value={profile.ModesOfTransportationList.filter(x => data?.includes(x.ID))}
                    onChange={(e) => {
                      onChange((e.value as any[]).map(x => x.ID));
                    }}
                  />
                }
                save={async (data) => await saveAsyncProfile({ ModesOfTransportation: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="BillingCustomerID" className="col-sm-2 col-form-label">Billing Customer</label>
            <div className="col-sm-10">
              <EditableField
                data={{ ID: profile.Carrier.BillingCustomerID, Number: profile.Carrier.BillingCustomerNumber }}
                renderDisplay={(data) => data.ID ? data.Number : <span className="text-muted">Not Set</span>}
                renderEditable={(data, saving, onChange) =>
                  <CustomerInput
                    disabled={saving}
                    CustomerID={data.ID}
                    CustomerNumber={data.Number}
                    onChange={(e) => onChange({ ID: e.CustomerID, Number: e.CustomerNumber })}
                  />}
                save={async (data) => await saveAsyncProfile({ BillingCustomerID: data.ID, BillingCustomerNumber: data.Number })}
              />
            </div>
          </div>
          <h2>Compliance</h2>
          <div className="form-group row">
            <label htmlFor="CTPATApproved" className="col-sm-2 col-form-label">C-TPAT Certified</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Carrier.CTPATApproved}
                renderDisplay={(data) => CarrierComplianceOptions.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {CarrierComplianceOptions.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ CTPATApproved: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="TSAApproved" className="col-sm-2 col-form-label">TSA Approved</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Carrier.TSAApproved}
                renderDisplay={(data) => CarrierComplianceOptions.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {CarrierComplianceOptions.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ TSAApproved: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="FASTCarrier" className="col-sm-2 col-form-label">FAST Approved</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Carrier.FASTCarrier}
                renderDisplay={(data) => CarrierComplianceOptions.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {CarrierComplianceOptions.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ FASTCarrier: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="PIPApproved" className="col-sm-2 col-form-label">PIP Approved</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Carrier.PIPApproved}
                renderDisplay={(data) => CarrierComplianceOptions.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {CarrierComplianceOptions.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ PIPApproved: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="CSACarrier" className="col-sm-2 col-form-label">CSA Approved</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Carrier.CSACarrier}
                renderDisplay={(data) => CarrierComplianceOptions.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {CarrierComplianceOptions.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ CSACarrier: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="TankerEndorsement" className="col-sm-2 col-form-label">Tanker Endorsement</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Carrier.TankerEndorsement}
                save={async (data) => await saveAsyncProfile({ TankerEndorsement: data })}
              />
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <br />
          <AuditInfo audit={profile.Carrier.AuditInfo} />
          <br />
          <h2>Authority</h2>
          <div className="form-group row">
            <label htmlFor="AuthorityOnFile" className="col-sm-2 col-form-label">Authority On File</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Carrier.AuthorityOnFile}
                save={async (data) => await saveAsyncProfile({ AuthorityOnFile: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="SCAC" className="col-sm-2 col-form-label">SCAC</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 5 }}
                data={profile.Carrier.SCAC}
                save={async (data) => saveAsyncProfile({ SCAC: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="MCNumber1" className="col-sm-2 col-form-label">MC Number 1</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 15 }}
                data={profile.Carrier.MCNumber1}
                save={async (data) => saveAsyncProfile({ MCNumber1: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="MCNumber2" className="col-sm-2 col-form-label">MC Number 2</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 15 }}
                data={profile.Carrier.MCNumber2}
                save={async (data) => saveAsyncProfile({ MCNumber2: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="USDOT" className="col-sm-2 col-form-label">U.S. DOT Number</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 10 }}
                data={profile.Carrier.USDOT}
                save={async (data) => saveAsyncProfile({ USDOT: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="CarrierAssureGrade" className="col-sm-2 col-form-label">Carrier Assure Grade</label>
            <div className="col-sm-10" style={{ display: "flex", alignItems: "center" }}>
              {profile.Carrier.CarrierAssureScore ? <Link style={{ color: "#007bff", marginLeft: "12px" }} to="#" onClick={(e) => {
                e.preventDefault();
                openWindowPerc(`/Assets/Carrier/${carrierId}/CarrierAssureReportCard`, .35, .6);
              }}>{profile.Carrier.CarrierAssureGrade}</Link> :
                <span style={{ marginLeft: "12px" }}>{profile.Carrier.CarrierAssureGrade}</span>}
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="Bonded" className="col-sm-2 col-form-label">Bonded</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Carrier.Bonded}
                save={async (data) => await saveAsyncProfile({ Bonded: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="AuthorityDateIssued" className="col-sm-2 col-form-label">Date Issued</label>
            <div className="col-sm-10">
              <EditableField
                renderDisplay={(data) => (data != null ? Moment.utc(data).tz("America/New_York").format("MM/DD/YYYY") : "N/A")}
                dataType="date"
                data={profile.Carrier.AuthorityDateIssued != null ? Moment.utc(profile.Carrier.AuthorityDateIssued).tz("America/New_York").toDate() : null}
                save={async (data) => await saveAsyncProfile({ AuthorityDateIssued: data })}
              />
            </div>
          </div>
          <br />
          <h2>Insurance</h2>
          <div className="form-group row">
            <label htmlFor="AutoNamedInsured" className="col-sm-2 col-form-label">Named Insured</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Carrier.AutoNamedInsured}
                save={async (data) => await saveAsyncProfile({ AutoNamedInsured: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="IsRequestedAsHolder" className="col-sm-2 col-form-label">Requested as Holder</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Carrier.IsRequestedAsHolder}
                save={async (data) => await saveAsyncProfile({ IsRequestedAsHolder: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="AutoInsuredBy" className="col-sm-2 col-form-label">Insured By</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 50 }}
                data={profile.Carrier.AutoInsuredBy}
                save={async (data) => saveAsyncProfile({ AutoInsuredBy: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="GeneralLiabilityAmount" className="col-sm-2 col-form-label">General Liability Amount</label>
            <div className="col-sm-10">
              <EditableField
                dataType="number"
                inputProps={{ min: 0, format: 'N0' }}
                data={profile.Carrier.GeneralLiabilityAmount}
                save={async (data) => await saveAsyncProfile({ GeneralLiabilityAmount: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="AutoLiabilityAmount" className="col-sm-2 col-form-label">Auto Liability Amount</label>
            <div className="col-sm-10">
              <EditableField
                dataType="number"
                inputProps={{ min: 0, format: 'N0' }}
                data={profile.Carrier.AutoLiabilityAmount}
                save={async (data) => await saveAsyncProfile({ AutoLiabilityAmount: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="AutoLiabilityExpiryDate" className="col-sm-2 col-form-label">Auto Liability Expiry</label>
            <div className="col-sm-10">
              <EditableField
                renderDisplay={(data) => (data != null ? Moment.utc(data).tz("America/New_York").format("MM/DD/YYYY") : "N/A")}
                dataType="date"
                data={profile.Carrier.AutoLiabilityExpiryDate != null ? Moment.utc(profile.Carrier.AutoLiabilityExpiryDate).tz("America/New_York").toDate() : null}
                save={async (data) => await saveAsyncProfile({ AutoLiabilityExpiryDate: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="CargoInsuranceAmount" className="col-sm-2 col-form-label">Cargo Deductible</label>
            <div className="col-sm-10">
              <EditableField
                dataType="number"
                inputProps={{ min: 0, format: 'N0' }}
                data={profile.Carrier.CargoInsuranceAmount}
                save={async (data) => await saveAsyncProfile({ CargoInsuranceAmount: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="CargoLiabilityAmount" className="col-sm-2 col-form-label">Cargo Liability Amount</label>
            <div className="col-sm-10">
              <EditableField
                dataType="number"
                inputProps={{ min: 0, format: 'N0' }}
                data={profile.Carrier.CargoLiabilityAmount}
                save={async (data) => await saveAsyncProfile({ CargoLiabilityAmount: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="CargoLiabilityExpiryDate" className="col-sm-2 col-form-label">Cargo Liability Expiry</label>
            <div className="col-sm-10">
              <EditableField
                renderDisplay={(data) => (data != null ? Moment.utc(data).tz("America/New_York").format("MM/DD/YYYY") : "N/A")}
                dataType="date"
                data={profile.Carrier.CargoLiabilityExpiryDate != null ? Moment.utc(profile.Carrier.CargoLiabilityExpiryDate).tz("America/New_York").toDate() : null}
                save={async (data) => await saveAsyncProfile({ CargoLiabilityExpiryDate: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="AutoHazMatCertified" className="col-sm-2 col-form-label">Hazmat Certified</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Carrier.AutoHazMatCertified}
                save={async (data) => await saveAsyncProfile({ AutoHazMatCertified: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="AutoInsuranceApprovedByUserID" className="col-sm-2 col-form-label">Insurance Approved By</label>
            <div className="col-sm-10">
              <EditableIDName
                data={profile.Users.find(x => x.ID === profile.Carrier.AutoInsuranceApprovedByUserID)}
                options={profile.Users}
                save={async (data) => await saveAsyncProfile({ AutoInsuranceApprovedByUserID: data.ID })}
              />
            </div>
          </div>
          <br />
          <h2>Accounting</h2>
          <div className="form-group row">
            <label htmlFor="AccountingName" className="col-sm-2 col-form-label">Name</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 40 }}
                data={profile.Carrier.AccountingName}
                save={async (data) => saveAsyncProfile({ AccountingName: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="IsPOBoxAddress" className="col-sm-2 col-form-label">Is P.O. Box Location</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Carrier.IsPOBoxAddress}
                save={async (data) => await saveAsyncProfile({ IsPOBoxAddress: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="AccountingAddress" className="col-sm-2 col-form-label">Address</label>
            <div className="col-sm-10">
              <EditableAddress
                title="Accounting Address"
                data={profile.Carrier.AccountingAddress}
                save={async (data) => saveAsyncProfile({ AccountingAddress: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="AccountingPhone" className="col-sm-2 col-form-label">Phone Number</label>
            <div className="col-sm-10">
              <EditableField
                dataType="intlphone"
                data={profile.Carrier.AccountingPhone ?? ""}
                save={async (data) => saveAsyncProfile({ AccountingPhone: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="AccountingFax" className="col-sm-2 col-form-label">Fax Number</label>
            <div className="col-sm-10">
              <EditableField
                dataType="intlphone"
                data={profile.Carrier.AccountingFax ?? ""}
                save={async (data) => saveAsyncProfile({ AccountingFax: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="AccountingEmail" className="col-sm-2 col-form-label">Email Address</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                inputProps={{ type: 'email', maxLength: 50 }}
                data={profile.Carrier.AccountingEmail}
                save={async (data) => saveAsyncProfile({ AccountingEmail: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="FederalTaxpayerID" className="col-sm-2 col-form-label">Federal Tax Payer ID</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 15 }}
                data={profile.Carrier.FederalTaxpayerID}
                save={async (data) => saveAsyncProfile({ FederalTaxpayerID: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="VendorID" className="col-sm-2 col-form-label">Vendor ID</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 100 }}
                data={profile.Carrier.VendorID}
                save={async (data) => saveAsyncProfile({ VendorID: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="AccountingContact" className="col-sm-2 col-form-label">Accounting Contact</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 40 }}
                data={profile.Carrier.AccountingContact}
                save={async (data) => saveAsyncProfile({ AccountingContact: data })}
              />
            </div>
          </div>
        </div>
      </div>
    </>);
  };

  if (props.isDialog) {
    return <Dialog
      className="dialog-w11/12"
      title={<Link to={`/Assets/Carrier/${props.carrierId}`}>Carrier Profile {profile?.Carrier.CarrierName ?? ''}</Link>}
      onClose={() => {
        props.onClose();
      }}
    >
      {dataView()}
    </Dialog>
  }

  return dataView();
}

export default CarrierProfile;