import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { Push } from "@progress/kendo-react-animation";
import { Button, DropDownButton, DropDownButtonItem, Toolbar, ToolbarItem } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TextArea } from "@progress/kendo-react-inputs";
import { Error } from '@progress/kendo-react-labels';
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { useEffect, useState } from 'react';
import { JsonResponse } from 'TypeGen/json-response';
import LoadingPanel from '../../components/LoadingPanel';
import { fetchApi } from "../../services/api";
import { IDName } from "../../types/idname";
import { isNullOrWhiteSpaceValidator } from '../../utils/utils';
import { bellIcon, checkIcon } from '@progress/kendo-svg-icons';

type Props = {
    CloseDialog?: (newMessageCreated: boolean) => void;
    VehicleID?: number;
    QuoteReply?: string;
}

type GetFleetVehiclesResponse = {
    Vehicles: IDName[];
}

const messagePriorities = [
    { ID: 1, Name: "Normal" },
    { ID: 2, Name: "Important" },
    { ID: 3, Name: "Sleepy" },
    { ID: 4, Name: "Important Sleepy" },
    { ID: 5, Name: "Extended Sleepy" },
];

const SendSatMessageDialog = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [messageVehiclesFilter, setMessageVehiclesFilter] = useState<FilterDescriptor>();
    const [messageVehicleId, setMessageVehicleId] = useState<{ value: number, error: string }>({ value: -1, error: '' });
    const [vehicles, setVehicles] = useState<IDName[]>([]);
    const [messagePriority, setMessagePriority] = useState(1);
    const [message, setMessage] = useState<{ value: string, error: string }>({ value: '', error: '' });
    const [messageConfirmReceipt, setMessageConfirmReceipt] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        if (props.VehicleID) {
            setMessageVehicleId({ value: props.VehicleID, error: '' })
        }
    }, [props.VehicleID]);

    useEffect(() => {
        setLoading(true);
        fetchApi("/api/Asset/FleetMessages")
            .then((response: GetFleetVehiclesResponse) => {
                setLoading(false);
                setVehicles(response.Vehicles);
            });
    }, []);

    const send = () => {
        setSuccessMessage('');
        const messageVehicleIdError = messageVehicleId.value < 1 ? 'Please select a vehicle.' : '';
        const messageError = isNullOrWhiteSpaceValidator(message.value, 'Value cannot be empty.');
        if (messageVehicleIdError || messageError) {
            setMessageVehicleId({ ...messageVehicleId, error: messageVehicleIdError });
            setMessage({ ...message, error: messageError });
            return;
        }

        setLoading(true);
        const data = {
            VehicleID: messageVehicleId.value,
            Message: message.value,
            ConfirmReceipt: messageConfirmReceipt,
            Priority: messagePriority,
        };
        fetchApi('/api/Asset/SendFreeFormMessage', data, 'POST')
            .then((response: JsonResponse) => {
                setLoading(false);
                if (response.Success) {
                    // Reset Form
                    setMessageVehicleId({ value: 0, error: '' });
                    setMessage({ value: '', error: '' });
                    setMessageConfirmReceipt(false);
                    setMessagePriority(1);

                    if (props.CloseDialog)
                        props.CloseDialog(true);
                    else
                        setSuccessMessage('Message was successfully sent');
                } else {
                    setMessage({ ...message, error: response.ErrorMessage });
                }
            })
            .catch(() => {
                setLoading(false);
                setMessage({ ...message, error: 'Error sending message.' });
            });
    }

    const dataView = () => {
        if (loading) {
            return <LoadingPanel />;
        }

        return (<>
            {props.QuoteReply
                ? <blockquote className="blockquote">{props.QuoteReply}</blockquote>
                : <>
                <DropDownList
                    data={filterBy(vehicles.filter(x => x.ID > 0), messageVehiclesFilter)}
                    className="w-100"
                    textField="Name"
                    filterable
                    valid={!messageVehicleId.error}
                    defaultValue={{ ID: 0, Name: 'Select Vehicle' }}
                    onFilterChange={(e) => setMessageVehiclesFilter(e.filter)}
                    value={vehicles.find(x => x.ID === messageVehicleId.value)}
                    onChange={(e) => setMessageVehicleId({ value: e.target.value.ID, error: '' })}
                />
                <Error>{messageVehicleId.error}</Error>
            </>}
            <Toolbar className='mt-3'>
                <ToolbarItem>
                    <DropDownButton
                        svgIcon={bellIcon}
                        title="Omnitracs ONLY"
                        onItemClick={(e) => setMessagePriority(e.itemIndex + 1)}
                        text={messagePriorities.find(x => x.ID === messagePriority)?.Name}
                        popupSettings={{ popupClass: 'k-toolbar-popup' }}
                    >
                        {messagePriorities.map((priority, index) => (
                            <DropDownButtonItem
                                key={index}
                                selected={messagePriority === priority.ID}
                                text={priority.Name}
                            />
                        ))}
                    </DropDownButton>
                </ToolbarItem>
                <Button
                    togglable={true}
                    svgIcon={checkIcon}
                    title="Omnitracs ONLY"
                    selected={messageConfirmReceipt}
                    onClick={() => setMessageConfirmReceipt(!messageConfirmReceipt)}
                >
                    Confirm Receipt
                </Button>
            </Toolbar>
            <TextArea
                autoFocus
                className="w-100 k-rounded-t-none"
                rows={4}
                valid={!message.error}
                value={message.value}
                onChange={(e) => setMessage({ value: e.value, error: '' })}
                placeholder="Type Message..."
            />
            <Error>{message.error}</Error>
        </>);
    }

    if (props.CloseDialog) {
        return <Dialog
            title={props.QuoteReply ? `Reply to ${vehicles.find(x => x.ID === messageVehicleId.value)?.Name}` : 'Send Message'}
            onClose={() => props.CloseDialog(false)}
            className="dialog-w800"
        >
            {dataView()}
            <DialogActionsBar>
                <Button onClick={() => props.CloseDialog(false)}>Cancel</Button>
                <Button disabled={!!!message.value} themeColor="primary" onClick={send}>{props.QuoteReply ? 'Send Reply' : 'Send Message'}</Button>
            </DialogActionsBar>
        </Dialog>
    }

    return <div>
        <Push style={{ zIndex: 0, width: '100%' }}>
            {successMessage.length > 0 && <NotificationGroup style={{ position: "relative", alignItems: "normal", zIndex: 0, width: '100%' }}>
                <Notification style={{ padding: "8px", width: '100%' }} type={{ style: 'success', icon: true }}>
                    <div>{successMessage}</div>
                </Notification>
            </NotificationGroup>}
        </Push>
        {dataView()}
        <div className="mt-3">
            <Button themeColor="primary" onClick={send}>Send Message</Button>
        </div>
    </div>
}

export default SendSatMessageDialog;