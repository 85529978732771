import { useCallback, useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useHistory, useParams } from "react-router";
import { fetchApi } from "../../services/api";
import { Badge, BadgeContainer, Loader } from "@progress/kendo-react-indicators";
import { Label } from '@progress/kendo-react-labels';
import { Link } from "react-router-dom";
import Moment from 'moment-timezone';
import Documents, { DocumentEntityType } from "views/Documents/Documents";
import { Button } from "@progress/kendo-react-buttons";
import { SvgIcon } from "@progress/kendo-react-common";
import { chevronRightIcon } from "@progress/kendo-svg-icons";
import { ViewModel } from "TypeGen/Assets/DriverAccidents/view-model";
import { DriverAccidentViewModel } from "TypeGen/Assets/DriverAccidents/driver-accident-view-model";
import { ApiLink } from "TypeGen/api-link";
import { DriverAccidentClaim } from "TypeGen/Assets/DriverAccidents/driver-accident-claim";
import DriverAutoComplete from "views/AssetDrivers/DriverAutoComplete";
import TrailerAutoComplete from "views/AssetTrailers/TrailerAutoComplete";
import OwnerAutoComplete from "views/AssetOwners/OwnerAutoComplete";
import { CreatingField } from "components/CreatingField";
import VehicleAutoComplete from "views/AssetVehicles/VehicleAutoComplete";
import { TextArea } from "@progress/kendo-react-inputs";
import DriverAccidentAuditLogs from "./DriverAccidentAuditLogs";
import { formatNumber } from '@progress/kendo-intl';
import { Grid, GridColumn as Column, GridNoRecords, GridFooterCellProps } from "@progress/kendo-react-grid";
import DriverAccidentClaimEditUI, { claimTypes } from "./DriverAccidentClaimEditUI";
import { JsonResponse } from "TypeGen/json-response";
import useConfirm from "components/useConfirm";

type RouteComponentParams = {
  driverAccidentId: string;
};

const YesNoUnknown = [{ ID: 'Y', Name: 'Yes' }, { ID: 'N', Name: 'No' }, { ID: 'U', Name: 'Unknown' }];

const DriverAccident = () => {

  const { driverAccidentId: driverAccidentIdParam } = useParams<RouteComponentParams>();
  const history = useHistory();
  const { ConfirmationDialog, confirm } = useConfirm({});
  const [loading, setLoading] = useState(true);
  const [showDocumentDialog, setShowDocumentDialog] = useState(false);
  const [showLogsDialog, setShowLogsDialog] = useState(false);
  const [documentCount, setDocumentCount] = useState(0);
  const [data, setData] = useState<DriverAccidentViewModel>();
  const [claims, setClaims] = useState<DriverAccidentClaim[]>([]);
  const [claim, setClaim] = useState<DriverAccidentClaim>();
  const [links, setLinks] = useState<ApiLink[]>([]);
  const [saved, setSaved] = useState(false);

  const refresh = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Asset/DriverAccident/${driverAccidentIdParam}`)
      .then((response: ViewModel) => {
        setDocumentCount(response.DocumentCount);
        setData(response.Data);
        setClaims(response.Claims);
        setLinks(response.Links);
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to load');
      });
  }, [driverAccidentIdParam]);

  const updateStatus = async (link: ApiLink) => {
    if (link.Name === 'Cancel') {
      if (!await confirm('Are you sure you want to cancel this accident?')) return;
    }
    setLoading(true);
    fetchApi(link.Link, {}, link.Method)
      .then(() => {
        history.push('/Assets/DriverAccidents');
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to update status');
      });
  }

  const save = () => {
    const link = links.find(x => x.Name === "Update") as ApiLink;
    setLoading(true);
    fetchApi(link.Link, { Data: data }, link.Method)
      .then(() => {
        setSaved(true);
        setTimeout(() => setSaved(false), 2000);
        refresh();
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to save');
      });
  }

  const addUpdateClaim = (claim: DriverAccidentClaim) => {
    const link = links.find(l => l.Name === 'AddAccidentClaim');
    setLoading(true);
    fetchApi(link.Link, claim, link.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          setClaim(undefined);
          refresh();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert(e);
      });
  }

  const createScheduledDeduction = async () => {
    if (await confirm('Are you sure you want to create a $1,000 liability deduction for this accident?') === false) {
      return;
    }
    setLoading(true);
    fetchApi(`/api/Asset/CreateDriverAccidentDeduction/${driverAccidentIdParam}`, {}, "POST")
      .then(() => {
        alert('Liability deduction created successfully!');
      })
      .catch((e) => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert(e);
      });
  }

  useEffect(() => {
    refresh();
  }, [driverAccidentIdParam, refresh]);

  const loadingView = <div className="text-center">
    <Loader type="converging-spinner" />
  </div>;

  const ClaimsFooter = (props: GridFooterCellProps) => {
    return (
      <td colSpan={props.colSpan} style={props.style}>
        {formatNumber(claims.reduce((total, claim) => total + claim.PayoutAmount, 0), 'C')}
      </td>
    );
  };

  return (<div>
    <Title string="Driver Accident / Incident" />
    <ConfirmationDialog />
    {showDocumentDialog &&
      <Documents
        IsWindow
        EntityType={DocumentEntityType.DriverAccident}
        EntityId={parseInt(driverAccidentIdParam)}
        EntityNumber={`Accident # ${driverAccidentIdParam}`}
        CloseDocumentModal={() => setShowDocumentDialog(false)}
      />
    }
    {showLogsDialog && 
      <DriverAccidentAuditLogs
        DriverAccidentID={parseInt(driverAccidentIdParam)}
        CloseDialog={() => setShowLogsDialog(false)}
      />
    }
    {claim && <DriverAccidentClaimEditUI
      loading={loading}
      accidentClaim={claim}
      cancel={() => setClaim(undefined)}
      save={addUpdateClaim}
    />}
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark mt-3">
      <span className="navbar-brand">
        <Link to="/Assets/DriverAccidents">Accidents / Incidents</Link>
        <span className="text-muted">
          <SvgIcon icon={chevronRightIcon} />
          # {data?.ReportNumber || driverAccidentIdParam}
        </span>
      </span>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#CarrierNav" aria-controls="CarrierNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="CarrierNav">
        <ul className="navbar-nav">
        </ul>
        <ul className="nav navbar-nav ml-auto">
          <BadgeContainer className="mr-2">
            <Button onClick={() => setShowDocumentDialog(true)}>Documents</Button>
            {documentCount > 0 && <Badge>{documentCount}</Badge>}
          </BadgeContainer>
          <Button onClick={() => setShowLogsDialog(true)}>Logs</Button>
        </ul>
      </div>
    </nav>
    <br />
    {loading ? loadingView : null}
    {data && <>
      <Grid data={claims} onRowClick={(e) => setClaim(e.dataItem)}>
        <GridNoRecords>
          No Claims Created
        </GridNoRecords>
        <Column field="ClaimNumber" title="Claim #" />
        <Column field="ClaimType" title="Claim Type" cell={(e) => <td>
          {claimTypes.find(x => x.ID === e.dataItem.ClaimType)?.Name || "Unknown"}
        </td>} />
        <Column field="ClaimStatus" title="Claim Status" cell={(e) => <td>
          {e.dataItem.ClaimStatus === 1 ? "Open" : e.dataItem.ClaimStatus === 2 ? "Closed" : "Unknown"}
        </td>} />
        <Column field="VendorName" title="Vendor" />
        <Column field="PayoutAmount" title="Payout" format="{0:c2}" footerCell={ClaimsFooter} />
      </Grid>
      <div className="row mt-2">
        <div className="form-group col-md-3">
          <Label>Driver</Label>
          <DriverAutoComplete
            selectedDriverID={data.DriverID}
            onSelectedDriver={value => setData({ ...data, DriverID: value.DriverID })}
          />
        </div>
        <div className="form-group col-md-3">
          <Label>Owner</Label>
          <OwnerAutoComplete
            selectedOwnerID={data.OwnerID}
            onSelectedOwner={value => setData({ ...data, OwnerID: value.OwnerID })}
          />
        </div>
        <div className="form-group col-md-3">
          <Label>Vehicle</Label>
          <VehicleAutoComplete
            selectedVehicleID={data.VehicleID}
            onSelectedVehicle={value => setData({ ...data, VehicleID: value.VehicleID })}
          />
        </div>
        <div className="form-group col-md-3">
          <Label>Trailer</Label>
          <TrailerAutoComplete
            selectedTrailerID={data.TrailerID}
            onSelectedTrailer={value => setData({ ...data, TrailerID: value.TrailerID })}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-3">
          <Label>Accident / Incident Type</Label>
          <CreatingField
            required
            dataType="string"
            data={data.AccidentType}
            onChange={value => setData({ ...data, AccidentType: value })}
            inputProps={{ maxLength: 50 }}
          />
        </div>
        <div className="form-group col-md-3">
          <Label>Accident Date/Time</Label>
          <CreatingField
            required
            dataType="datetime"
            data={data.DateTimeOfAccident != null ? Moment.utc(data.DateTimeOfAccident).tz("America/New_York").toDate() : null}
            onChange={value => setData({ ...data, DateTimeOfAccident: value })}
          />
        </div>
        <div className="form-group col-md-3">
          <Label>Injuries Reported?</Label>
          <CreatingField
            dataType="boolean"
            data={data.InjuriesReported}
            onChange={e => setData({ ...data, InjuriesReported: e })}
          />
        </div>
        <div className="form-group col-md-3">
          <Label>Injury Information</Label>
          <CreatingField
            dataType="string"
            data={data.InjuryInformation}
            onChange={e => setData({ ...data, InjuryInformation: e })}
            inputProps={{ maxLength: 200 }}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12">
          <Label>Accident Description</Label>
          <TextArea
            value={data.AccidentDescription}
            onChange={e => setData({ ...data, AccidentDescription: e.value })}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-4">
          <Label>Accident Location</Label>
          <CreatingField
            dataType="string"
            data={data.AccidentLocation}
            onChange={e => setData({ ...data, AccidentLocation: e })}
            inputProps={{ maxLength: 200 }}
          />
        </div>
        <div className="form-group col-md-4">
          <Label>Road Conditions</Label>
          <CreatingField
            dataType="string"
            data={data.AccidentRoadConditions}
            onChange={e => setData({ ...data, AccidentRoadConditions: e })}
            inputProps={{ maxLength: 200 }}
          />
        </div>
        <div className="form-group col-md-4">
          <Label>Weather Conditions</Label>
          <CreatingField
            dataType="string"
            data={data.AccidentWeatherConditions}
            onChange={e => setData({ ...data, AccidentWeatherConditions: e })}
            inputProps={{ maxLength: 200 }}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-3">
          <Label>Any Vehicles Towed?</Label>
          <CreatingField
            dataType="boolean"
            data={data.AnyVehiclesTowed}
            onChange={e => setData({ ...data, AnyVehiclesTowed: e })}
          />
        </div>
        <div className="form-group col-md-3">
          <Label>Load One Paid for Tow?</Label>
          <CreatingField
            data={data.PaidForTow === true ? "Y" : data.PaidForTow === false ? "N" : "U"}
            renderEditable={(data, onChange) => <select className="custom-select" value={data} onChange={(e) => onChange(e.target.value)}>
                {YesNoUnknown.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
              </select>}
            onChange={e => setData({ ...data, PaidForTow: e === "Y" ? true : e === "N" ? false : null })}
          />
        </div>
        <div className="form-group col-md-3">
          <Label>Towed Location</Label>
          <CreatingField
            dataType="string"
            data={data.VehicleTowedLocation}
            onChange={e => setData({ ...data, VehicleTowedLocation: e })}
            inputProps={{ maxLength: 200 }}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-3">
          <Label>Police Report Taken?</Label>
          <CreatingField
            dataType="boolean"
            data={data.PoliceReportTaken}
            onChange={e => setData({ ...data, PoliceReportTaken: e })}
          />
        </div>
        <div className="form-group col-md-3">
          <Label>Police Report or Badge Number</Label>
          <CreatingField
            dataType="string"
            data={data.PoliceReportOrBadgeNumber}
            onChange={e => setData({ ...data, PoliceReportOrBadgeNumber: e })}
            inputProps={{ maxLength: 50 }}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-3">
          <Label>Is our vehicle damaged?</Label>
          <CreatingField
            dataType="boolean"
            data={data.AnyDamageToOurVehicle}
            onChange={e => setData({ ...data, AnyDamageToOurVehicle: e })}
          />
        </div>
        <div className="form-group col-md-3">
          <Label>Is our vehicle drivable?</Label>
          <CreatingField
            dataType="boolean"
            data={data.IsVehicleDrivable}
            onChange={e => setData({ ...data, IsVehicleDrivable: e })}
          />
        </div>
        <div className="form-group col-md-3">
          <Label>Does load need to be recovered?</Label>
          <CreatingField
            dataType="boolean"
            data={data.DoesLoadNeedToBeRecovered}
            onChange={e => setData({ ...data, DoesLoadNeedToBeRecovered: e })}
          />
        </div>
        <div className="form-group col-md-3">
          <Label>Trip #</Label>
          <CreatingField
            dataType="number"
            data={data.TripNumber}
            onChange={e => setData({ ...data, TripNumber: e || null })}
            inputProps={{ spinners: false, format: '0'}}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-3">
          <Label>Was the other vehicle damaged?</Label>
          <CreatingField
            dataType="boolean"
            data={data.DamageToOtherVehicle}
            onChange={e => setData({ ...data, DamageToOtherVehicle: e })}
          />
        </div>
        <div className="form-group col-md-3">
          <Label>Other Vehicle Make</Label>
          <CreatingField
            dataType="string"
            data={data.OtherVehicleMake}
            onChange={e => setData({ ...data, OtherVehicleMake: e })}
            inputProps={{ maxLength: 50 }}
          />
        </div>
        <div className="form-group col-md-3">
          <Label>Other Vehicle Model</Label>
          <CreatingField
            dataType="string"
            data={data.OtherVehicleModel}
            onChange={e => setData({ ...data, OtherVehicleModel: e })}
            inputProps={{ maxLength: 50 }}
          />
        </div>
        <div className="form-group col-md-3">
          <Label>Other Vehicle Plate</Label>
          <CreatingField
            dataType="string"
            data={data.OtherVehiclePlate}
            onChange={e => setData({ ...data, OtherVehiclePlate: e })}
            inputProps={{ maxLength: 8 }}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-4">
          <Label>Other Vehicle Owner Name</Label>
          <CreatingField
            dataType="string"
            data={data.OtherVehicleOwnerName}
            onChange={e => setData({ ...data, OtherVehicleOwnerName: e })}
            inputProps={{ maxLength: 50 }}
          />
        </div>
        <div className="form-group col-md-4">
          <Label>Other Vehicle Insurance Information</Label>
          <CreatingField
            dataType="string"
            data={data.OtherVehicleInsuranceInformation}
            onChange={e => setData({ ...data, OtherVehicleInsuranceInformation: e })}
            inputProps={{ maxLength: 200 }}
          />
        </div>
        <div className="form-group col-md-4">
          <Label>Witness Information</Label>
          <CreatingField
            dataType="string"
            data={data.WitnessInformation}
            onChange={e => setData({ ...data, WitnessInformation: e })}
            inputProps={{ maxLength: 200 }}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-4">
          <Label>Citation Issued?</Label>
          <CreatingField
            data={data.CitationGiven === true ? "Y" : data.CitationGiven === false ? "N" : "U"}
            renderEditable={(data, onChange) => <select className="custom-select" value={data} onChange={(e) => onChange(e.target.value)}>
                {YesNoUnknown.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
              </select>}
            onChange={e => setData({ ...data, CitationGiven: e === "Y" ? true : e === "N" ? false : null })}
          />
        </div>
        <div className="form-group col-md-4">
          <Label>DOT Recordable?</Label>
          <CreatingField
            data={data.DOTRecordable === true ? "Y" : data.DOTRecordable === false ? "N" : "U"}
            renderEditable={(data, onChange) => <select className="custom-select" value={data} onChange={(e) => onChange(e.target.value)}>
                {YesNoUnknown.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
              </select>}
            onChange={e => setData({ ...data, DOTRecordable: e === "Y" ? true : e === "N" ? false : null })}
          />
        </div>
        <div className="form-group col-md-4">
          <Label>Occupants in our Vehicle Besides Driver</Label>
          <CreatingField
            data={data.OccupantsInOurVehicle === true ? "Y" : data.OccupantsInOurVehicle === false ? "N" : "U"}
            renderEditable={(data, onChange) => <select className="custom-select" value={data} onChange={(e) => onChange(e.target.value)}>
                {YesNoUnknown.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
              </select>}
            onChange={e => setData({ ...data, OccupantsInOurVehicle: e === "Y" ? true : e === "N" ? false : null })}
          />
        </div>
      </div>
    </>}
    <div className="text-center mt-3">
      <Button themeColor="primary" onClick={() => setClaim({
        DriverAccidentClaimID: 0,
        ClaimNumber: '',
        ClaimType: 0,
        ClaimStatus: 0,
        VendorName: '',
        PayoutAmount: 0
      })}>Add Claim</Button>
      <Button themeColor={saved ? "success" : "primary"} className="ml-2" onClick={save} disabled={loading}>Update Accident</Button>
      <Button onClick={createScheduledDeduction} className="ml-2">Create Liability Deduction</Button>
      {links.find(x => x.Name == 'Cancel') && <Button themeColor="error" className="ml-2"  onClick={() => updateStatus(links.find(x => x.Name == 'Cancel') as ApiLink)} disabled={loading}>Cancel Accident</Button>}
    </div>
  </div>);
}

export default DriverAccident;