import { formatNumber } from '@progress/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Grid, GridCellProps, GridColumn as Column, GridHeaderCellProps, GridItemChangeEvent, GridRowDoubleClickEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from "@progress/kendo-react-indicators";
import { Checkbox, NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import Moment from 'moment-timezone';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { JsonResponse } from 'TypeGen/json-response';
import CenterDivPanel from '../../components/CenterDivPanel';
import SimpleDatePicker from '../../components/SimpleDatePicker';
import useAlert from '../../components/useAlert';
import { fetchApi } from '../../services/api';
import { RateFuelUnitOfMeasure } from '../../TypeGen/rate-fuel-unit-of-measure';
import { IDName } from '../../types/idname';
import CopyRateMasterDialog from './CopyRateMasterDialog';
import FuelPumpPointRateBreak from './FuelPumpPointRateBreak';
import FuelPumpRate from './FuelPumpRate';
import FuelVehicleRateBreakStructure from './FuelVehicleRateBreakStructure';

type Props = {
  CustomerID?: number;
  CloseDialog?: () => void;
};

type RateFuelMasterSummary = {
  Title: string;

  RateFuelMasters: RateFuelMasterViewModel[];

  UnitOfMeasureList: IDName[];
}

export type RateFuelMasterViewModel = {

  RateFuelMasterID: number;

  UnitOfMeasureID: number;
  UnitOfMeasureName: string;
  Rate: number;

  ModifiedByFullName: string;
  ModifiedDateTime: Date;
  StartDate: Date;
  EndDate: Date;

  inEdit?: boolean | string;
  isNew?: boolean;
  IsExpired: boolean;
};

const RateFuelMasterContext = createContext<{ UnitOfMeasureList: IDName[] }>({ UnitOfMeasureList: [] })

const LastModifiedByCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: RateFuelMasterViewModel = props.dataItem;
  return (
    <td colSpan={props.colSpan} style={props.style}>
      <span>{Moment.utc(dataItem.ModifiedDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} - {dataItem.ModifiedByFullName}</span>
    </td>
  );
};

interface EditCommandCellProps extends GridCellProps {
  edit: (item: RateFuelMasterViewModel) => void;
  //remove: (item: RateFuelMasterViewModel) => void;
  add: (item: RateFuelMasterViewModel) => void;
  discard: () => void;
  update: (item: RateFuelMasterViewModel) => void;
  cancel: (item: RateFuelMasterViewModel) => void;
  editField: string;
}

const DateCell = (props: GridCellProps) => {

  const handleChange = (e: Date) => {
    if (props.field === "StartDate") {
      e = Moment.utc(e).tz("America/New_York").toDate();
    } else {
      e = Moment.utc(e).tz("America/New_York").endOf('day').toDate();
    }

    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: null,
        value: e,
      });
    }
  };

  return (
    <td colSpan={props.colSpan} style={props.style}>
      {props.dataItem.inEdit ?
        <SimpleDatePicker
          value={Moment.utc(props.dataItem[props.field]).tz("America/New_York").toDate()}
          onChange={handleChange}
        />
        :
        <div className={props.field === 'EndDate' && props.dataItem.IsExpired ? 'text-warning' : ''}>
          {Moment.utc(props.dataItem[props.field]).tz("America/New_York").format("MM/DD/YYYY")}
        </div>
      }
    </td>
  );
};

const RateCell = (props: GridCellProps) => {
  const handleChange = (e: NumericTextBoxChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.value
      });
    }
  };

  let dataItem: RateFuelMasterViewModel = props.dataItem;
  const noTables: RateFuelUnitOfMeasure[] = [RateFuelUnitOfMeasure.PercOfLHNoTable, RateFuelUnitOfMeasure.PerMileNoTable];
  const usingFuelMasterRate = noTables.some(x => x === dataItem.UnitOfMeasureID);

  return (
    <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
      {dataItem.inEdit && usingFuelMasterRate ? <NumericTextBox format="n3" value={dataItem.Rate} onChange={handleChange} min={0} inputStyle={{ textAlign: "right" }} /> :
        <span>{usingFuelMasterRate ? formatNumber(dataItem.Rate, "n3") : '-'}</span>
      }
    </td>
  );
};

const RightAlignHeaderCell = (props: GridHeaderCellProps) => {
  return (<a className="k-link" onClick={props.onClick} style={{ textAlign: "right" }}>
    <span>{props.title}</span>
    {props.children}
  </a>);
};

const UnitOfMeasureCell = (props: GridCellProps) => {

  const context = useContext(RateFuelMasterContext);

  let dataItem: RateFuelMasterViewModel = props.dataItem;

  const handleChange = (e: DropDownListChangeEvent) => {
    if (props.onChange) {

      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.ID,
      });
    }
  };

  return (
    <td colSpan={props.colSpan} style={props.style}>
      {dataItem.inEdit ? <DropDownList
        className="my-1"
        data={context.UnitOfMeasureList}
        textField="Name"
        dataItemKey="ID"
        value={context.UnitOfMeasureList.find((x: IDName) => x.ID == dataItem.UnitOfMeasureID)}
        onChange={handleChange}
      /> :
        <div>{dataItem.UnitOfMeasureName}</div>
      }
    </td>
  );
};

const RateFuelMaster = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const editField: string = "inEdit";
  const [unitOfMeasures, setUnitOfMeasures] = useState<IDName[]>([]);
  const [CSIDataCopy, setCSIDataCopy] = useState<RateFuelMasterViewModel[]>([]);
  const [RateFuelMasters, setRateFuelMasters] = useState<RateFuelMasterViewModel[]>([]);
  const [title, setTitle] = useState('');
  const [hideExpiredPayRateStructures, setHideExpiredPayRateStructures] = useState(true);
  const { alert } = useAlert();
  const [selectedRateFuelMaster, setSelectedRateFuelMaster] = useState<RateFuelMasterViewModel>();
  const [showFuelPumpRateDialog, setShowFuelPumpRateDialog] = useState(false);
  const [rateMasterToCopy, setRateMasterToCopy] = useState(0);

  const RateFuelMasterIDCell = (props: GridCellProps) => {
    if (!props.field)
      return null;

    let dataItem: RateFuelMasterViewModel = props.dataItem;
    const noTables: RateFuelUnitOfMeasure[] = [RateFuelUnitOfMeasure.PercOfLHNoTable, RateFuelUnitOfMeasure.PerMileNoTable];
    const usingFuelMasterRate = noTables.some(x => x === dataItem.UnitOfMeasureID);

    return dataItem.inEdit || usingFuelMasterRate ? (
      <td colSpan={props.colSpan} style={props.style}>
        <span className="ml-2">{dataItem.isNew ? '-' : dataItem.RateFuelMasterID}</span>
      </td>
    ) : (
      <td colSpan={props.colSpan} style={props.style}>
        <Button fillMode="flat" size="small" themeColor="primary" onClick={() => setSelectedRateFuelMaster(dataItem)}>{dataItem.RateFuelMasterID}</Button>
      </td>
    );
  };

  const EditCommandCell = (props: EditCommandCellProps) => {
    const inEdit = props.dataItem[props.editField];
    let dataItem: RateFuelMasterViewModel = props.dataItem;
    const isNewItem = dataItem.isNew;

    return inEdit ? (
      <td className="k-command-cell">
        <Button themeColor={"primary"}
          disabled={dataItem.StartDate == null || dataItem.EndDate == null}
          onClick={() =>
            isNewItem ? props.add(dataItem) : props.update(dataItem)
          }
        >
          {isNewItem ? "Save" : "Update"}
        </Button>
        <Button themeColor={"secondary"}
          onClick={() =>
            isNewItem ? props.discard() : props.cancel(dataItem)
          }
        >
          {isNewItem ? "Discard" : "Cancel"}
        </Button>
      </td>
    ) : (
      <td className="k-command-cell">
        <Button themeColor={"primary"}
          disabled={RateFuelMasters.some(x => x.inEdit === true)}
          onClick={() => props.edit(dataItem)}
        >
          Edit
        </Button>
        <Button themeColor={"primary"}
          disabled={RateFuelMasters.some(x => x.inEdit === true)}
          onClick={() => setRateMasterToCopy(dataItem.RateFuelMasterID)}
        >
          Copy
        </Button>
      </td>
    );
  };

  const refresh = useCallback(() => {
    setLoading(true);

    fetchApi(`/api/Quote/GetRateFuelMasters/${props.CustomerID ?? 0}`)
      .then((summary: RateFuelMasterSummary) => {
        summary.RateFuelMasters.forEach(obj => {
          obj.StartDate = Moment.utc(obj.StartDate).tz("America/New_York").toDate();
          obj.EndDate = Moment.utc(obj.EndDate).tz("America/New_York").toDate();
        });

        setRateFuelMasters(summary.RateFuelMasters);
        setCSIDataCopy(summary.RateFuelMasters);

        setTitle(summary.Title);

        setUnitOfMeasures(summary.UnitOfMeasureList);
      })
      .catch(async e => {
        // If not problem details
        if (!e?.status) await alert(e);
      })
      .finally(() => setLoading(false));
  }, [props.CustomerID, alert]);

  useEffect(() => {
    refresh();
  }, [props.CustomerID, refresh]);

  const enterEdit = (dataItem: RateFuelMasterViewModel) => {
    if (RateFuelMasters.some(x => x.inEdit === true))
      return;

    setRateFuelMasters(
      RateFuelMasters.map((item) =>
        item.RateFuelMasterID === dataItem.RateFuelMasterID ? { ...item, inEdit: true } : item
      )
    );
  };

  const updateRateFuelMasters = (dataItem: RateFuelMasterViewModel) => {
    setLoading(true);
    const data = { ...dataItem }
    fetchApi(`/api/Quote/RateFuelMaster/${props.CustomerID ?? 0}`, data, 'POST')
      .then(async (response: JsonResponse) => {
        if (response.Success === false) {
          setLoading(false);
          await alert(`Error: ${response.ErrorMessage}`);
        }
        else
          refresh();
      })
      .catch(async e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) await alert('An error occurred while saving.');
      });
  }

  const add = (dataItem: RateFuelMasterViewModel) => {
    updateRateFuelMasters(dataItem);
  };

  const update = (dataItem: RateFuelMasterViewModel) => {
    updateRateFuelMasters(dataItem);
  };

  const discard = () => {
    const newData = [...RateFuelMasters];
    newData.splice(0, 1);
    setRateFuelMasters(newData);
  };

  const cancel = (dataItem: RateFuelMasterViewModel) => {
    const originalItem = CSIDataCopy.find(
      //const originalItem = RateFuelMasters.find(
      (p) => p.RateFuelMasterID === dataItem.RateFuelMasterID
    );
    const newData = RateFuelMasters.map((item) =>
      item.RateFuelMasterID === originalItem.RateFuelMasterID ? originalItem : item
    );

    setRateFuelMasters(newData);
  };

  const generateId = () =>
    RateFuelMasters.reduce((acc, current) => Math.max(acc, current.RateFuelMasterID), 0) + 1;

  const addNew = () => {
    const newDataItem = {
      inEdit: true,
      RateFuelMasterID: generateId(),
      isNew: true,
      StartDate: Moment.utc().tz("America/New_York").startOf('day').toDate(),
      EndDate: Moment([2999, 11, 31]).utc().tz("America/New_York").endOf('day').toDate(),
      IsExpired: false, UnitOfMeasureID: RateFuelUnitOfMeasure.PerMile
    } as RateFuelMasterViewModel;
    setRateFuelMasters([newDataItem, ...RateFuelMasters]);
  };

  const itemChange = (event: GridItemChangeEvent) => {
    const dataItem = event.dataItem as RateFuelMasterViewModel;
    const newData = RateFuelMasters.map((item) =>
      item.RateFuelMasterID === dataItem.RateFuelMasterID
        ? { ...item, [event.field || ""]: event.value }
        : item
    );

    setRateFuelMasters(newData);
  }

  const MyEditCommandCell = (props: GridCellProps) => (
    <EditCommandCell {...props}
      edit={enterEdit}
      //remove={remove}
      add={add}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );


  const dataView = () => {
    return (<>
      <div className="container-fluid mt-4" style={{ position: "relative" }}>
        {loading && <CenterDivPanel>
          <Loader type="converging-spinner" />
        </CenterDivPanel>}
        <h4 className="text-left">{title} Fuel Rate Structure</h4>
        <RateFuelMasterContext.Provider value={{ UnitOfMeasureList: unitOfMeasures ?? [] }}>
          <Grid
            data={hideExpiredPayRateStructures ? RateFuelMasters.filter(x => x.IsExpired === false) : RateFuelMasters}
            onItemChange={itemChange}
            editField={editField}
            onRowDoubleClick={(e: GridRowDoubleClickEvent) => { enterEdit(e.dataItem); }}
          >
            <GridToolbar>
              <Button themeColor={"primary"}
                disabled={RateFuelMasters.some(x => x.inEdit === true)}
                title="Add new"
                onClick={addNew}
              >Add New Fuel Rate Structure</Button>
              <span>
                <Checkbox
                  disabled={RateFuelMasters.some(x => x.inEdit === true)}
                  className="ml-2"
                  value={hideExpiredPayRateStructures}
                  onChange={(e) => setHideExpiredPayRateStructures(e.value)}
                  label={"Hide Expired Rate Structures"}
                />
              </span>
              <Button themeColor={"primary"}
                fillMode="link"
                style={{ fontSize: 'larger' }}
                disabled={RateFuelMasters.some(x => x.inEdit === true)}
                title="Fuel Pump Rates"
                onClick={() => setShowFuelPumpRateDialog(true)}
              >View Fuel Pump Rates</Button>
            </GridToolbar>
            <Column field="RateFuelMasterID" title="Rate Code" editable={false} cell={RateFuelMasterIDCell} width={75} />
            <Column field="StartDate" title="Effective From" cell={DateCell} minResizableWidth={175} />
            <Column field="EndDate" title="Effective To" cell={DateCell} minResizableWidth={175} />
            <Column field="UnitOfMeasureID" title="Rate UoM" cell={UnitOfMeasureCell} minResizableWidth={175} width={175} />
            <Column field="Rate" title="Rate" cell={RateCell} minResizableWidth={175} headerCell={RightAlignHeaderCell} />
            <Column field="ModifiedByFullName" title="Modified By" cell={LastModifiedByCell} minResizableWidth={250} />
            <Column cell={MyEditCommandCell} width={160} />
          </Grid>
        </RateFuelMasterContext.Provider>

        {(selectedRateFuelMaster && selectedRateFuelMaster.UnitOfMeasureID == RateFuelUnitOfMeasure.PerMileTruckClass) ?
          <Dialog title={`${selectedRateFuelMaster.UnitOfMeasureName.toUpperCase()} (${selectedRateFuelMaster.RateFuelMasterID})`} className='dialog-w11/12' onClose={() => setSelectedRateFuelMaster(null)}>
            <FuelVehicleRateBreakStructure RateFuelMasterID={selectedRateFuelMaster.RateFuelMasterID} />
          </Dialog> : selectedRateFuelMaster && <Dialog title={`${selectedRateFuelMaster.UnitOfMeasureName.toUpperCase()} (${selectedRateFuelMaster.RateFuelMasterID})`} className='dialog-w11/12' onClose={() => setSelectedRateFuelMaster(null)}>
            <FuelPumpPointRateBreak RateFuelMasterID={selectedRateFuelMaster.RateFuelMasterID} />
          </Dialog>}

        {showFuelPumpRateDialog &&
          <Dialog title={`${title} Fuel Pump Rates`} className='dialog-w11/12' onClose={() => setShowFuelPumpRateDialog(false)}>
            <FuelPumpRate CustomerID={props.CustomerID} />
          </Dialog>}
        {rateMasterToCopy > 0 && <CopyRateMasterDialog rateFuelMasterID={rateMasterToCopy} rateMasterDescription={`${title} Fuel Rate Structure`} onClose={() => setRateMasterToCopy(0)} />}
      </div>
    </>);
  };

  if (props.CloseDialog) {
    return <Dialog title={`Set ${title} Fuel Rate Structure`} onClose={props.CloseDialog} className='dialog-w11/12'>
      {dataView()}
    </Dialog>
  }

  return dataView();
}

export default RateFuelMaster;