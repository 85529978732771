import { Button } from "@progress/kendo-react-buttons";
import { Window } from "@progress/kendo-react-dialogs";
import { FieldWrapper } from "@progress/kendo-react-form";
import { InputSuffix, TextBox } from "@progress/kendo-react-inputs";
import { Error } from '@progress/kendo-react-labels';
import { searchIcon } from "@progress/kendo-svg-icons";
import { useEffect, useState } from "react";
import { fetchApi } from "services/api";

type Props = {
  vin: string;
  CloseDialog: () => void;
}

type VINReport = {
  Count: number;
  Message: string;
  Results: {
    [key: string]: string;
  }[];
  SearchCriteria: string;
}

const VINLookupDialog = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [vin, setVIN] = useState<{ value: string, error: string }>({ value: props.vin, error: '' });
  const [report, setReport] = useState<VINReport | null>(null);

  useEffect(() => {
    if (props.vin) {
      _search();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.vin]);

  const _search = () => {
    const vinError = vin.value ? '' : 'Value cannot be empty.';

    if (vinError) {
      setVIN({ ...vin, error: vinError });
      return;
    }

    setLoading(true);
    setReport(null);
    fetchApi(`https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${vin.value}?format=json`)
      .then((data: VINReport) => {
        setReport(data);
      })
      .catch((e) => {
        alert('Unable to connect to DOT VIN Lookup service.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return <Window title="NHTSA VIN Search" onClose={props.CloseDialog} initialWidth={Math.min(850, window.innerWidth)}>
    <form className="k-form k-form-md p-0">
      <FieldWrapper>
        <TextBox
          placeholder="Enter VIN Number"
          disabled={loading}
          value={vin.value}
          onChange={(e) => setVIN({ value: e.value as string, error: '' })}
          suffix={() => (<InputSuffix>
            <Button type="submit" themeColor="primary" svgIcon={searchIcon} disabled={loading} onClick={_search}>Search</Button>
          </InputSuffix>)}
        />
        <Error>{vin.error}</Error>
      </FieldWrapper>
    </form>
    {report && <div className="p-2">
      {report.Results[0]["ErrorCode"] !== "0" && <div className="alert alert-danger">{report.Results[0]["ErrorText"]}</div>}
      <div className="row">
        <div className="col-md-6">
          <div className="form-group row mb-0">
            <label className="col-md-4 col-form-label">Make</label>
            <div className="col-md-8">
              <p className="form-control-plaintext">{report.Results[0]["Make"] || 'N/A'}</p>
            </div>
          </div>
          <div className="form-group row mb-0">
            <label className="col-md-4 col-form-label">Model</label>
            <div className="col-md-8">
              <p className="form-control-plaintext">{report.Results[0]["Model"] || 'N/A'}</p>
            </div>
          </div>
          <div className="form-group row mb-0">
            <label className="col-md-4 col-form-label">Model Year</label>
            <div className="col-md-8">
              <p className="form-control-plaintext">{report.Results[0]["ModelYear"] || 'N/A'}</p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          {report.Results[0]["FuelTypePrimary"] && <div className="form-group row mb-0">
            <label className="col-md-4 col-form-label">Fuel Type</label>
            <div className="col-md-8">
              <p className="form-control-plaintext">{report.Results[0]["FuelTypePrimary"]}</p>
            </div>
          </div>}
          {report.Results[0]["Axles"] && <div className="form-group row mb-0">
            <label className="col-md-4 col-form-label">Axle Count</label>
            <div className="col-md-8">
              <p className="form-control-plaintext">{report.Results[0]["Axles"]}</p>
            </div>
          </div>}
          {report.Results[0]["GVWR"] && <div className="form-group row mb-0">
            <label className="col-md-4 col-form-label">Weight Class</label>
            <div className="col-md-8">
              <p className="form-control-plaintext">{report.Results[0]["GVWR"]}</p>
            </div>
          </div>}
          {report.Results[0]["SuggestedVIN"] && <div className="form-group row mb-0">
            <label className="col-md-4 col-form-label">Suggested VIN</label>
            <div className="col-md-8">
              <p className="form-control-plaintext">{report.Results[0]["SuggestedVIN"]}</p>
            </div>
          </div>}
          {report.Results[0]["PossibleValues"] && <div className="form-group row mb-0">
            <label className="col-md-4 col-form-label">Possible Values</label>
            <div className="col-md-8">
              <p className="form-control-plaintext">{report.Results[0]["PossibleValues"]}</p>
            </div>
          </div>}
        </div>
      </div>
    </div>}
  </Window>;
}

export default VINLookupDialog;
