import { formatNumber } from '@progress/kendo-intl';
import { Button, SplitButton, SplitButtonItem } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Loader } from "@progress/kendo-react-indicators";
import { RadioButton } from "@progress/kendo-react-inputs";
import { Card, CardBody, PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import useConfirm from "components/useConfirm";
import moment from 'moment';
import { cloneElement, useCallback, useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { fetchApi } from "services/api";
import { ApiLink } from "TypeGen/api-link";
import { JsonResponse } from "TypeGen/json-response";
import { CalculatePayRateResult } from "TypeGen/Pay/calculate-pay-rate-result";
import { PayDropdown } from "TypeGen/Trip/Pay/pay-dropdown";
import { ViewModel } from "TypeGen/Trip/Pay/view-model";
import PayEditable from "views/Order/Planning/PayEditable";
import KendoWindow from "../../../components/KendoWindow";
import { StatusChangedByLog } from '../../../TypeGen/Trip/Pay/status-changed-by-log';
import PayPeriodDropDownList from "../../Settlements/PayPeriodDropDownList";
import CreatePayTrip from "../PayTrip/Create";
import { TripContext } from "../Profile";
import useMessage from 'utils/useMessage';

type RouteComponentParams = {
  tripId: string;
  sequence: string;
  letter: string;
};

type Props = {
  tripId?: number;
  sequence?: number;
  letter?: string;
  onClose?: () => void;

  children?: React.ReactNode;
}

const TripPay = (props: Props) => {
  const history = useHistory();
  const { tripId: tripIdParam, sequence: paySequenceParam, letter: payLetterParam } = useParams<RouteComponentParams>();
  const { sendToParentIfExists } = useMessage('refresh');
  const [tripId, setTripId] = useState(0);
  const [paySequence, setPaySequence] = useState(0);
  const [payLetter, setPayLetter] = useState("A");
  const { refresh: refreshTrip } = useContext(TripContext);
  const { confirm, ConfirmationDialog } = useConfirm({});
  const [loading, setLoading] = useState(true);
  const [payNumber, setPayNumber] = useState("");
  const [superceded, setSuperceded] = useState(false);
  const [payStatus, setPayStatus] = useState("");
  const [payDivision, setPayDivision] = useState(0);
  const [payPeriodName, setPayPeriodName] = useState('');
  const [payPostBatchID, setPayPostBatchID] = useState(0);
  const [readyToPostBy, setReadyToPostBy] = useState<StatusChangedByLog>();
  const [postedBy, setPostedBy] = useState<StatusChangedByLog>();
  const [showPayAnotherAssetWindow, setShowPayAnotherAssetWindow] = useState(false);
  const [autoPayRate, setAutoPayRate] = useState<CalculatePayRateResult>();
  const [manualPayRate, setManualPayRate] = useState<CalculatePayRateResult>();
  const [supercededPayRates, setSupercededPayRates] = useState<CalculatePayRateResult[]>([]);
  const [useManualPayRate, setUseManualPayRate] = useState("true");
  const [pays, setPays] = useState<PayDropdown[]>([]);
  const [links, setLinks] = useState<ApiLink[]>([]);

  const [selectedPostToPayPeriodID, setSelectedPostToPayPeriodID] = useState<number>();
  const [showPostToPayPeriodDialog, setShowPostToPayPeriodDialog] = useState(false);

  const refreshTripPay = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Trip/Pay/${tripId}/${paySequence}/${payLetter}`)
      .then((response: ViewModel) => {
        setPayNumber(response.PayNumber);
        setSuperceded(response.Superceded);
        setPayStatus(response.PayStatus);
        setPayDivision(response.PayDivision);
        setPayPeriodName(response.PayPeriodName);
        setReadyToPostBy(response.ReadyToPostBy);
        setPostedBy(response.PostedBy);
        setPayPostBatchID(response.PayPostBatchID);
        setAutoPayRate(response.AutoPayRate);
        setManualPayRate(response.ManualPayRate);
        setSupercededPayRates(response.SupercededPayRates);
        if (response.ManualPayRate === null) setUseManualPayRate("false");
        setPays(response.Pays);
        setLinks(response.Links);
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to load trip pay');
      });
  }, [tripId, paySequence, payLetter]);

  const savePay = async () => {
    const link = links.find(x => x.Name === "SetPay");
    setLoading(true);
    fetchApi(link.Link, { LineItems: useManualPayRate === "true" ? manualPayRate.LineItems : autoPayRate.LineItems }, link.Method)
      .then((e: JsonResponse) => {
        if (e.Success) {
          refreshTrip();
          refreshTripPay();
          sendToParentIfExists({ type: 'refresh', payload: null });
        } else alert(e.ErrorMessage);
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to set pay');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const saveZeroPay = async () => {
    if (!await confirm('Are you sure you want to set the pay to zero?', 'Set Pay To Zero?')) return;

    const link = links.find(x => x.Name === "SetPay");
    setLoading(true);
    fetchApi(link.Link, { LineItems: [] }, link.Method)
      .then((e: JsonResponse) => {
        if (e.Success) {
          refreshTrip();
          refreshTripPay();
          sendToParentIfExists({ type: 'refresh', payload: null });
        } else alert(e.ErrorMessage);
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to set pay');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const setRated = async () => {
    if (!await confirm('Are you sure you want to set rated?', 'Set As Rated?')) return;

    const link = links.find(x => x.Name === "SetRated");
    setLoading(true);
    fetchApi(link.Link, {}, link.Method)
      .then((e: JsonResponse) => {
        if (e.Success) {
          refreshTripPay();
          sendToParentIfExists({ type: 'refresh', payload: null });
        }
        else alert(e.ErrorMessage);
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to set rated');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const setReadyToPost = async () => {
    if (!await confirm('Are you sure you want to set ready to post?', 'Set As Ready To Post?')) return;

    const link = links.find(x => x.Name === "SetReadyToPost");
    setLoading(true);
    fetchApi(link.Link, {}, link.Method)
      .then((e: JsonResponse) => {
        if (e.Success) {
          refreshTripPay();
          sendToParentIfExists({ type: 'refresh', payload: null });
        }
        else alert(e.ErrorMessage);
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to set ready to post');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const PostToPayPeriod = async () => {

    const link = links.find(x => x.Name === "PostToPayPeriod");
    setLoading(true);
    fetchApi(link.Link, { PayPeriodID: selectedPostToPayPeriodID }, link.Method)
      .then((e: JsonResponse) => {
        if (e.Success) {
          refreshTripPay();
          sendToParentIfExists({ type: 'refresh', payload: null });
        }
        else alert(e.ErrorMessage);
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to post to pay period');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (props.tripId > 0) {
      setTripId(props.tripId);
      setPaySequence(props.sequence ?? 0);
      setPayLetter(props.letter ?? "A");
    }
    else {
      setTripId(Number(tripIdParam));
      setPaySequence(Number(paySequenceParam));
      setPayLetter(payLetterParam);
    }
  }, [tripIdParam, paySequenceParam, payLetterParam, props.tripId, props.sequence, props.letter]);

  useEffect(() => {
    if (tripId > 0)
      refreshTripPay();
  }, [tripId, refreshTripPay]);

  const dataView = () => {
    if (loading || !autoPayRate) {
      return <div className="d-flex justify-content-center mt-5">
        <Loader type="converging-spinner" />
      </div>;
    }

    const setPayLink = links.find(x => x.Name === "SetPay") !== undefined;
    const setRatedLink = links.find(x => x.Name === "SetRated") !== undefined;
    const setReadyToPostLink = links.find(x => x.Name === "SetReadyToPost") !== undefined;
    const driver2Link = links.find(x => x.Name === "Driver2Pay");
    const driver3Link = links.find(x => x.Name === "Driver3Pay");
    const postToPayPeriodLink = links.find(x => x.Name === "PostToPayPeriod") !== undefined;

    const customItemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: any) => {
      const dataItem = itemProps.dataItem as PayDropdown;
      return cloneElement(
        li,
        {},
        <div>
          <span className="mr-2">{dataItem.PayNumber} - {dataItem.Asset}</span>
          <span>{formatNumber(dataItem.TotalPay, { style: 'accounting' })}</span>
        </div>
      );
    };

    const drawAutoRateSection = (): JSX.Element => {
      return (<><RadioButton
        className="my-2"
        name="existingPay"
        value="false"
        checked={useManualPayRate === "false"}
        label="Use Auto Pay Rate"
        onChange={(e) => setUseManualPayRate(e.value)}
      />
        <br />
        <PayEditable
          editable={setPayLink && manualPayRate === null}
          data={autoPayRate}
          onChange={(x) => {
            setAutoPayRate(x);
            if (useManualPayRate === "true") setUseManualPayRate("false")
          }}
        /></>);
    };

    return <div>
      <ConfirmationDialog />

      <Card className="my-2">
        <CardBody className="py-2">
          <div className="row align-items-center">
            <div className="col-auto">
              <div className="row d-flex align-items-center">
                <label className="col-auto col-form-label pr-1 py-0">
                  {pays.length === 1
                    ? <Link className="px-0 font-weight-bold" to={pays[0].Link}>{payNumber}</Link>
                    : <DropDownList
                      className="font-weight-bold"
                      style={{ width: "175px", marginLeft: "-12px" }}
                      textField="PayNumber"
                      dataItemKey="PayNumber"
                      data={pays}
                      fillMode={'flat'}
                      defaultValue={pays.find(x => x.PayNumber === payNumber)}
                      itemRender={customItemRender}
                      onChange={(e) => history.push((e.value as PayDropdown).Link)}
                    />
                  }
                </label>
                <div className="col-auto pl-1">
                  <h5 className="mb-0"><span className="badge badge-info">{payStatus}</span></h5>
                </div>
              </div>
            </div>

            <div className="col"></div>

            <div className="col-auto">
              <div className="align-items-center float-right">
                {postToPayPeriodLink && <Button
                  disabled={loading}
                  className="ml-2 px-1 py-1"
                  themeColor="primary"
                  fillMode="flat"
                  onClick={() => { setSelectedPostToPayPeriodID(null); setShowPostToPayPeriodDialog(true); }}
                >
                  Post To Closed Pay Period
                </Button>}
                {/*className="ml-2 px-1 py-1"*/}

                {links.find((x) => x.Name === 'SwitchAsset') && <Button
                  disabled={loading}
                  className="ml-2 px-1 py-1"
                  themeColor="primary"
                  fillMode="flat"
                  onClick={() => { history.push(`/Trip/${tripId}/Pay/${paySequence}/${payLetter}/SwitchAsset`) }}
                >
                  Switch Asset
                </Button>}

                {links.find((x) => x.Name === 'PayAnotherAsset') && <Button
                  disabled={loading}
                  className="ml-2 px-1 py-1"
                  themeColor="primary"
                  fillMode="flat"
                  onClick={() => setShowPayAnotherAssetWindow(true)}
                >
                  Pay Another Asset
                </Button>}
              </div>
            </div>

          </div>

          {(readyToPostBy || payPeriodName) &&
            <div className="row align-items-center">
              <div className="col-auto">
                <div className="row d-flex align-items-center">

                  {readyToPostBy && <label className="col-auto col-form-label pr-1" title={moment.utc(readyToPostBy.LogDateTime).local().format('MM/DD/YYYY HH:mm')}>
                    Ready to Post by {readyToPostBy.UserName} {moment.utc(readyToPostBy.LogDateTime).fromNow()}
                  </label>}

                  {payPeriodName && <label className="col-auto col-form-label pr-1">
                    {readyToPostBy || postedBy ? '' : 'Estimated '}Pay Period: {payPeriodName}
                  </label>}
                </div>
              </div>
            </div>}

          {postedBy &&
            <div className="row align-items-center">
              <div className="col-auto">
                <div className="row d-flex align-items-center">

                  <label className="col-auto col-form-label pr-1" title={`${moment.utc(postedBy.LogDateTime).local().format('MM/DD/YYYY HH:mm')}`}>
                    Posted by {postedBy.UserName} {moment.utc(postedBy.LogDateTime).fromNow()}
                  </label>

                  <label className="col-auto col-form-label pr-1" title={`${moment.utc(postedBy.LogDateTime).local().format('MM/DD/YYYY HH:mm')}`}>
                    Post Batch ID: {payPostBatchID}
                  </label>

                </div>
              </div>
            </div>}
        </CardBody>
      </Card>

      {useManualPayRate === "true" && autoPayRate?.Total > 0 ? <PanelBar animation={false} className="mt-2">
        <PanelBarItem title={`Auto Pay Rate: ${autoPayRate?.Comment} ${formatNumber(autoPayRate?.Total, 'C')}`}>
          {drawAutoRateSection()}
        </PanelBarItem>
      </PanelBar> : (autoPayRate?.Total > 0 || manualPayRate === null) && drawAutoRateSection()}

      {manualPayRate && <>
        <RadioButton
          className="my-2"
          name="existingRate"
          value="true"
          checked={useManualPayRate === "true"}
          label="Use Existing Pay"
          onChange={(e) => setUseManualPayRate(e.value)}
        />
        <PayEditable
          editable={setPayLink}
          data={manualPayRate}
          onChange={(x) => {
            setManualPayRate(x);
            if (useManualPayRate === "false") setUseManualPayRate("true")
          }}
        />
      </>}
      <br />
      {supercededPayRates.length > 0 && <PanelBar animation={false}>
        {supercededPayRates.map((x, i) => <PanelBarItem expanded={superceded && i === 0} title={x.Comment}>
          <PayEditable
            data={x}
            editable={false}
            showComment={false}
          />
        </PanelBarItem>)}
      </PanelBar>}
      <div className="text-center mt-3">
        {setPayLink && <SplitButton
          disabled={loading}
          themeColor="primary"
          text="Set Pay"
          onButtonClick={savePay}
          onItemClick={saveZeroPay}
        >
          <SplitButtonItem text="Set Zero Pay" />
        </SplitButton>}
        {setRatedLink && <Button
          disabled={loading}
          className="ml-2"
          themeColor="primary"
          onClick={setRated}
        >
          Set As Rated
        </Button>}
        {setReadyToPostLink && <Button
          disabled={loading}
          className="ml-2"
          themeColor="primary"
          onClick={setReadyToPost}
        >
          Set Ready To Post
        </Button>}
        {driver2Link && <Link
          className="btn btn-primary ml-2"
          to={driver2Link.Link}
        >
          Pay Driver 2
        </Link>}
        {driver3Link && <Link
          className="btn btn-primary ml-2"
          to={driver3Link.Link}
        >
          Pay Driver 3
        </Link>}
      </div>
      {showPayAnotherAssetWindow && <CreatePayTrip tripId={tripId} closeDialog={() => setShowPayAnotherAssetWindow(false)} />}

      {showPostToPayPeriodDialog && <Dialog title="Post To Closed Pay Period?" onClose={() => setShowPostToPayPeriodDialog(false)}>
        <PayPeriodDropDownList
          onSelected={(e) => setSelectedPostToPayPeriodID(e.PayPeriodID)}
          includeClosedPayPeriods={true}
          includeOpenPayPeriods={false}
          fromPeriodEndingDate={moment.utc().subtract(4, 'weeks').startOf('day').toDate()}
          label={'Select Closed Pay Period'}
          payDivision={payDivision}
        />
        <DialogActionsBar layout={'end'}>
          <Button themeColor={'primary'} onClick={() => {
            setShowPostToPayPeriodDialog(false);
            PostToPayPeriod();
          }} disabled={selectedPostToPayPeriodID > 0 === false || loading}>Submit</Button>
          <Button themeColor={'base'} onClick={() => setShowPostToPayPeriodDialog(false)}>Cancel</Button>
        </DialogActionsBar>
      </Dialog>}

    </div>;
  }

  if (props.onClose) {
    return <KendoWindow
      autoSizePercentage={95}
      //title=""
      resizable
      onClose={() => props.onClose()}
    >
      {dataView()}
    </KendoWindow>
  }

  return dataView();

};

export default TripPay;