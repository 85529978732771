import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Label } from '@progress/kendo-react-labels';
import { FieldWrapper } from "@progress/kendo-react-form";
import { Checkbox, TextArea } from '@progress/kendo-react-inputs';
import { useState } from "react";
import { OrderStopNote } from "TypeGen/Order/StopEdit/order-stop-note";
import { TripNote } from "TypeGen/Trip/Attributes/trip-note";
import { OrderBillingNote } from "TypeGen/Order/OrderNotes/order-billing-note";
import { OrderGeneralNote } from "TypeGen/Order/OrderNotes/order-general-note";
import { ServiceExceptionNote } from "TypeGen/Order/ServiceException/service-exception-note";
import { dateFormatter } from "utils/utils";

type Props<T> = {
  loading: boolean;
  orderNote: T;
  noteType: string;
  save?: (note: T) => void;
  cancel: () => void;
}

export const NoteEditUI = <T extends OrderStopNote | OrderBillingNote | OrderGeneralNote | TripNote | ServiceExceptionNote>({ loading, orderNote, noteType, save, cancel }: Props<T>) => {
  const [note, setNote] = useState(orderNote);
  const noteName = `${note.OrderNoteID == 0 ? 'Add ' : 'Update '} ${noteType} Note`;

  return <Dialog
    title={noteName}
    className="dialog-w525"
    onClose={cancel}
  >
    <form className="k-form k-form-md p-0">
      <FieldWrapper>
        <TextArea autoFocus rows={8} readOnly={!!note.OrderNoteID} placeholder="Note" value={note.Note} onChange={e => setNote({ ...note, Note: e.target.value })} />
      </FieldWrapper>
      {'CustomerVisible' in note && <FieldWrapper className="mb-1">
        <Checkbox label="Customer Visible" value={note.CustomerVisible} onChange={(e) => setNote({ ...note, CustomerVisible: e.value })} />
      </FieldWrapper>}
      {'CarrierVisible' in note && <FieldWrapper className="mb-1">
        <Checkbox label="Carrier Visible" value={note.CarrierVisible} onChange={(e) => setNote({ ...note, CarrierVisible: e.value })} />
      </FieldWrapper>}
      {'InvoiceVisible' in note && <FieldWrapper className="mb-1">
        <Checkbox label="Invoice Visible" value={note.InvoiceVisible} onChange={(e) => setNote({ ...note, InvoiceVisible: e.value })} />
      </FieldWrapper>}
      {'DriverVisible' in note && <FieldWrapper className="mb-1">
        <Checkbox label="Driver Visible" value={note.DriverVisible} onChange={(e) => setNote({ ...note, DriverVisible: e.value })} />
      </FieldWrapper>}
      {'TurnoverVisible' in note && <>
        <FieldWrapper className="mb-1">
          <Checkbox label="For Shift Turnover" value={note.TurnoverVisible} onChange={(e) => setNote({ ...note, TurnoverVisible: e.value })} />
        </FieldWrapper>
        {note.AcknowledgedDateTime && <p className="mt-2">Acknowledged by <b>{note.AcknowledgedByFullName}</b> on <b>{dateFormatter(note.AcknowledgedDateTime)}</b>.</p>}
      </>}
      {'IsCritical' in note && <FieldWrapper className="mb-1">
        <Checkbox label="Critical" value={note.IsCritical} onChange={(e) => setNote({ ...note, IsCritical: e.value })} />
      </FieldWrapper>}
      {'FrontMessageIdVisible' in note && <>
        <FieldWrapper className="mb-1">
          <Checkbox label={`Reply In Front Thread (using ref#2 as Thread ID)`} value={note.FrontMessageIdVisible} onChange={(e) => setNote({ ...note, FrontMessageIdVisible: e.value })} />
        </FieldWrapper>
        {note.SentFrontMessageID && <p className="mt-2">
          <a target="_blank" style={{ color: '#007bff' }} href={`https://app.frontapp.com/open/${note.SentFrontMessageID}`}        >
            {note.SentFrontMessageID}
          </a></p>}
      </>}
    </form>
    <DialogActionsBar>
      <Button disabled={loading} onClick={cancel}>Cancel</Button>
      {save && <Button
        disabled={loading}
        themeColor={note.OrderNoteID == 0 ? 'success' : 'primary'}
        onClick={() => save(note)}
      >
        {noteName}
      </Button>}
    </DialogActionsBar>
  </Dialog>
}

export default NoteEditUI;