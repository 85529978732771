import * as React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Link, useHistory } from "react-router-dom";
import { fetchApi } from '../services/api';
import { useEffect } from 'react';
import { paths } from '../routes';

type GlobalSearchResult = {
  Name: string;
  Link: string;
}

const GlobalSearch = () => {

  const menuSearch: GlobalSearchResult[] = [
    { Link: paths.Home, Name: 'Home' },
    { Link: paths.AccountNotifications, Name: 'Notifications' },
    { Link: paths.LoadBoard, Name: 'Open Shipments' },
    { Link: paths.LoadBoardBidHistory, Name: 'Bid History' },
    { Link: "/Track/Index", Name: 'Track' },
    { Link: paths.ShiftTurnover, Name: 'Shift Turnover' },
    { Link: paths.TrackPositions, Name: 'Positions' },
    { Link: paths.DeadHeadCreate, Name: 'Deadhead Trip' },
    { Link: paths.PayTripCreate, Name: 'Additional Pay Trip' },
    { Link: "/Quote/Index", Name: 'New Quote' },
    { Link: paths.QuoteHistory, Name: 'Quote History' },
    { Link: paths.BlindBidQuote, Name: 'New Blind Bid' },
    { Link: paths.CarrierLoadBoard, Name: 'Carrier Load Board' },
    { Link: "/Assets/Drivers", Name: 'Drivers' },
    { Link: "/Assets/Vehicles", Name: 'Vehicles' },
    { Link: paths.AssetTrailers, Name: 'Trailers' },
    { Link: paths.AssetOwners, Name: 'Owners' },
    { Link: paths.AssetsCarriers, Name: 'Carriers' },
    { Link: paths.CarrierContactsGrid, Name: 'Carrier Contacts' },
    { Link: paths.CarrierGroups, Name: 'Carrier Groups' },
    { Link: paths.AssetInspections, Name: 'Inspections' },
    { Link: "/Driver/Chat", Name: 'Driver Chat' },
    { Link: paths.DriverAccidents, Name: 'Accidents / Incidents' },
    { Link: paths.LoadMap, Name: 'Load Map' },
    { Link: paths.ComplianceDates, Name: 'Compliance Dates' },
    { Link: paths.DriverCardLogs, Name: 'Card Logs' },
    { Link: paths.UploadBestpassTollReport, Name: 'Upload Bestpass Toll Report' },
    { Link: paths.DriverMetrics, Name: 'Driver Metrics' },
    { Link: paths.CarrierRankings, Name: 'Carrier Rankings' },
    { Link: paths.WorkloadHistory, Name: 'Workload History' },
    { Link: paths.FleetMessages, Name: 'Fleet Messages' },
    { Link: paths.Customers, Name: 'Customers' },
    { Link: paths.CustomerContactsGrid, Name: 'Customers Contacts' },
    { Link: paths.Salespeople, Name: 'Salespeople' },
    { Link: paths.CustomsBrokers, Name: 'Customs Brokers' },
    { Link: paths.EmailAliases, Name: 'Email Aliases' },
    { Link: paths.RateMasterViewer, Name: 'Tariff Rate Structures' },
    { Link: paths.PayRateLinehaul, Name: 'Pay Rate Linehaul' },
    { Link: paths.PayRateAccessorial, Name: 'Pay Rate Accessorial' },
    { Link: paths.CustomersMerge, Name: 'Merge Customers' },
    { Link: paths.CustomersBackhaul, Name: 'Backhaul Assistant' },
    { Link: paths.InvoiceListing, Name: 'Invoice Listing' },
    { Link: paths.PostInvoiceBatch, Name: 'Post Invoice Batch' },
    { Link: paths.DownloadInvoiceBatch, Name: 'Download Invoice Batch' },
    { Link: paths.InvoiceGateway, Name: 'Invoice Gateway' },
    { Link: paths.UploadVehicleFuelDeductions, Name: 'Upload Vehicle Fuel Deductions' },
    { Link: paths.UploadDriverChargebacks, Name: 'Upload Driver/Vehicle Chargebacks' },
    { Link: paths.UploadEmailOwnerFuelReport, Name: 'Upload/Email Owner Fuel Report' },
    { Link: paths.UploadAmortizedVehicleChargebacks, Name: 'Upload Amortized Vehicle Chargebacks' },
    { Link: "/PurchaseOrder/Index", Name: 'Purchase Orders' },

    { Link: paths.CompanySettings, Name: 'Company Settings' },
    { Link: paths.AccountSettings, Name: 'User Settings' },
    { Link: paths.AgentNotes, Name: 'Agent Notes' },
    { Link: paths.KnowledgeBase, Name: 'Knowledge Base' },
  ];

  const searchInput = React.useRef<HTMLInputElement>(null);
  const history = useHistory();

  const [showSearchResults, setShowSearchResults] = React.useState<boolean>(false);
  const [results, setResults] = React.useState<GlobalSearchResult[]>([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleShortcuts = React.useCallback((event: KeyboardEvent) => {

    // Previous
    if (showSearchResults && event.key === 'ArrowUp' && selectedIndex > 0) {
      setSelectedIndex(prevState => prevState - 1);
      event.preventDefault();
      return;
    }

    // Next
    if (showSearchResults && event.key === 'ArrowDown' && (selectedIndex + 1) < results.length) {
      setSelectedIndex(prevState => prevState + 1);
      event.preventDefault();
      return;
    }

    // Exit
    if (showSearchResults && event.key === 'Escape') {
      resetSearch();
    }

    // Select Item
    if (showSearchResults && event.key === 'Enter' && results.length) {
      const link = results[selectedIndex].Link;
      if (link.startsWith("https://")) {
        window.location.href = link;
      } else {
        history.push(link);
      }
      resetSearch();
    }

    // Ignore if in other inputs
    if (event.target instanceof HTMLElement) {
      if (event.target.tagName === "INPUT"
        || event.target.tagName === "SELECT"
        || event.target.tagName === "TEXTAREA"
        || event.target.contentEditable === "true") {
        return;
      }
    }

    // Active GlobkeyCodeal Search
    if (event.key === '/') {
      if (searchInput.current) {
        searchInput.current.focus();
        event.preventDefault();
      }
    }
  }, [showSearchResults, results, selectedIndex, history]);

  useEffect(() => {
    window.addEventListener("keydown", handleShortcuts);
    return () => window.removeEventListener("keydown", handleShortcuts);
  }, [handleShortcuts]);

  const search = (searchTerm: string) => {

    if (searchTerm.length < 1) {
      return;
    }

    // Local Results
    const localResults = menuSearch.filter(item => item.Name.toLowerCase().includes(searchTerm.toLowerCase())).slice(0, 10);
    setResults(localResults);

    if (searchTerm.length < 3) {
      return;
    }

    searchOnline(searchTerm, localResults);
  }

  const searchOnline = useDebouncedCallback((searchTerm: string, localResults: GlobalSearchResult[]) => {
    const data = {
      SearchTerm: searchTerm
    }

    fetchApi('/api/User/GlobalSearch', data, 'POST')
      .then((response: GlobalSearchResult[]) => {
        setSelectedIndex(0);
        setResults([...localResults, ...response]);
      });
  }, 250);

  const resetSearch = () => {
    setShowSearchResults(false);
    setSelectedIndex(0);
    setResults([]);
    if (searchInput.current) {
      searchInput.current.value = '';
      searchInput.current.blur();
    }
  }

  return <form className="form-inline my-4 my-lg-0">
    <input
      ref={searchInput}
      className="form-control mr-sm-2"
      type="search"
      onFocus={() => setShowSearchResults(true)}
      onBlur={() => setTimeout(() => {
        setShowSearchResults(false);
      }, 150)}
      onChange={(e) => search(e.target.value.trim())}
      placeholder={`Press "/" to search`}
      aria-label="Search" />
    <ul className={`search-results list-group ${showSearchResults ? "" : "d-none"}`}>
      {results.map((result, index) => {
        return result.Link.startsWith("https://")
          ? <a
            key={index}
            href={result.Link}
            target="_blank" rel="noopener noreferrer"
            className={`list-group-item ${selectedIndex === index ? "active" : ""}`}>
            {result.Name}
          </a>
          : <Link
            key={index}
            to={result.Link}
            onClick={resetSearch}
            className={`list-group-item ${selectedIndex === index ? "active" : ""}`}
          >
            {result.Name}
          </Link>
      })}
    </ul>
  </form>;
}

export default GlobalSearch;
