import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Input, TextArea } from '@progress/kendo-react-inputs';
import { useEffect, useState } from 'react';
import { AddressViewModel } from '../types/address';
import SingleLocationAutoComplete from '../views/PCMiler/SingleLocationAutoComplete';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { StateCodes } from '../utils/states';

type Props<T> = {
  data: T;
  readOnly?: boolean;
  mailingAddress?: boolean;
  addressLineLength?: number;
  title: string;
  save: (data: T) => Promise<void>;
}

export const EditableAddress = <T extends AddressViewModel>(props: Props<T>) => {

  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [data, setData] = useState(props.data);
  const [unlistedAddress, setUnlistedAddress] = useState(props.data.State === "XX" && !props.mailingAddress);

  // Update internal data
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const stopEditing = () => {
    if (saving) return;
    setData(props.data);
    setEditing(false);
  }

  const startEditing = () => {
    if (props.readOnly) return;
    setEditing(true);
  }

  const save = async () => {
    setSaving(true);
    await props.save(data);
    setSaving(false);
    setEditing(false);
  }

  const saveForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    save();
  }

  const captureEscape = (e: any) => {
    if (e.key === "Escape") {
      stopEditing();
    }
  }

  const updateUnlistedAddress = (value: boolean) => {
    if (value) {
      setData({
        ...data,
        State: "XX",
        Coordinates: {
          Latitude: 0,
          Longitude: 0
        }
      });
    }
    setUnlistedAddress(value);
  }

  const hasAddress = data.AddressLine1 || data.AddressLine2 || data.City || data.State || data.ZipCode;
  const height = 330 + (props.data.Name != null ? 65 : 0) + (props.mailingAddress ? 81 : 0) + (unlistedAddress ? 50 : 0);

  return <>
    {editing && <Dialog title={props.title} onClose={stopEditing} className="dialog-w525" height={height}>
      <form className="k-form k-form-md p-0" onKeyDown={captureEscape} onSubmit={saveForm}>
        <div className="row">
          <div className="col">
            {props.data.Name != null && <FieldWrapper className='mt-0'>
              <Input
                required
                autoFocus
                maxLength={60}
                label="Name"
                value={data.Name}
                onChange={(e) => setData({ ...data, Name: e.value })}
              />
            </FieldWrapper>}
            <FieldWrapper className={props.data.Name != null ? '' : 'mt-0'}>
              <Input
                required
                autoFocus
                maxLength={props.addressLineLength || 30}
                label="Address Line 1"
                value={data.AddressLine1}
                onChange={(e) => setData({ ...data, AddressLine1: e.value })}
              />
            </FieldWrapper>
            <FieldWrapper>
              {props.mailingAddress
                ? <TextArea
                  rows={3}
                  maxLength={150}
                  placeholder="Address Line 2"
                  value={data.AddressLine2}
                  onChange={(e) => setData({ ...data, AddressLine2: e.target.value })} />
                : <Input
                  maxLength={props.addressLineLength || 30}
                  label="Address Line 2"
                  value={data.AddressLine2}
                  onChange={(e) => setData({ ...data, AddressLine2: e.value })}
                />
              }

            </FieldWrapper>
            {!props.mailingAddress && <FieldWrapper>
              <div className="input-group">
                <div className="input-group-prepend">
                  <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{unlistedAddress ? 'City' : 'PC*Miler'}</button>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); updateUnlistedAddress(false); }}>PC*Miler</a>
                    <a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); updateUnlistedAddress(true); }}>Manual</a>
                  </div>
                </div>
                {unlistedAddress
                  ? <Input
                    required
                    maxLength={50}
                    placeholder='City'
                    className="form-control"
                    value={data.City}
                    onChange={(e) => setData({ ...data, City: e.value })}
                  />
                  : <SingleLocationAutoComplete
                    value={(data.ZipCode || data.City) && `${data.ZipCode} ${data.City}, ${data.State}`}
                    autoCompleteProps={{
                      clearButton: true,
                      placeholder: 'Enter City or Zip',
                      required: !unlistedAddress,
                      readonly: unlistedAddress,
                      style: { flex: 1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }
                    }}
                    onSelected={(e) => {
                      setUnlistedAddress(false);
                      if (e == null) return;

                      setData({
                        ...data,
                        ...e.Address,
                        ZipCode: e.Address.Zip,
                        Coordinates: {
                          Latitude: e.Coords.Lat,
                          Longitude: e.Coords.Lon
                        }
                      });
                    }}
                    onSelectUnlisted={() => {
                      updateUnlistedAddress(true);
                    }}
                  />}
              </div>
            </FieldWrapper>}
            {(props.mailingAddress || unlistedAddress) && <>
              {props.mailingAddress && <FieldWrapper>
                <Input
                  required
                  maxLength={50}
                  label="City"
                  value={data.City}
                  onChange={(e) => setData({ ...data, City: e.value })}
                />
              </FieldWrapper>}
              <div className="row">
                <div className="col">
                  <FieldWrapper>
                    {props.mailingAddress
                      ? <Input
                        required
                        maxLength={50}
                        label="State/Province"
                        value={data.State}
                        onChange={(e) => setData({ ...data, State: e.value })}
                      />
                      : <DropDownList
                        required
                        disabled={unlistedAddress}
                        data={StateCodes}
                        label="State/Province"
                        textField="Name"
                        dataItemKey="ID"
                        value={StateCodes.find(x => x.ID === data.State)}
                        onChange={(e) => setData({ ...data, State: e.target.value.ID })}
                      />
                    }

                  </FieldWrapper>
                </div>
                <div className="col">
                  <FieldWrapper>
                    <Input
                      required={props.mailingAddress !== true}
                      maxLength={16}
                      label="Zip Code"
                      value={data.ZipCode}
                      onChange={(e) => setData({ ...data, ZipCode: e.value })}
                    />
                  </FieldWrapper>
                </div>
              </div>
            </>}
          </div>
        </div>
        <br />
        <Button themeColor="primary" disabled={saving} type="submit" style={{ width: '100%' }}>
          {saving ? "Saving..." : "Save Address"}
        </Button>
      </form>
    </Dialog>}
    <span onClick={startEditing} className={`${props.readOnly ? 'disabled' : 'editable-field'} text-left btn btn-block`}>
      {hasAddress ? <>
        {data.Name && <>{data.Name}<br /></>}
        {data.AddressLine1}<br />
        {data.AddressLine2 && <>{data.AddressLine2}<br /></>}
        {data.City}, {data.State} {data.ZipCode}
      </> : `Set ${props.title}`}
    </span>
  </>;
};