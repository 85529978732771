import { SvgIcon } from "@progress/kendo-react-common";
import { cancelOutlineIcon } from "@progress/kendo-svg-icons";
import { Link } from "react-router-dom";

const NoContentView = () => (
  <div className="text-center p-5">
    <SvgIcon icon={cancelOutlineIcon} size="xxxlarge" />
    <br /><br />
    <h2>Resource not found</h2>
    <p>Maybe go back to the <Link to="/">home page</Link>?</p>
  </div>
);
export default NoContentView;
