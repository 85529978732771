import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Checkbox, Input, NumericTextBox } from '@progress/kendo-react-inputs';
import * as React from 'react';
import { IndividualRequestQuoteSettingsViewModel } from 'TypeGen/CompanySettings/individual-request-quote-settings-view-model';

type Props = {
  settings: IndividualRequestQuoteSettingsViewModel;
  defaults: IndividualRequestQuoteSettingsViewModel;
  onChange: (settings: IndividualRequestQuoteSettingsViewModel) => void;
}

export default class QuoteSettings extends React.Component<Props> {

  private afterHoursFrom = [
    { Value: '13:00:00', Name: '1pm' },
    { Value: '14:00:00', Name: '2pm' },
    { Value: '15:00:00', Name: '3pm' },
    { Value: '16:00:00', Name: '4pm' },
    { Value: '17:00:00', Name: '5pm' },
    { Value: '18:00:00', Name: '6pm' },
    { Value: '19:00:00', Name: '7pm' },
    { Value: '20:00:00', Name: '8pm' },
    { Value: '21:00:00', Name: '9pm' },
    { Value: '22:00:00', Name: '10pm' },
    { Value: '23:00:00', Name: '11pm' },
    { Value: '00:00:00', Name: '12am' }
  ];

  private afterHoursTo = [
    { Value: '01:00:00', Name: '1am' },
    { Value: '02:00:00', Name: '2am' },
    { Value: '03:00:00', Name: '3am' },
    { Value: '04:00:00', Name: '4am' },
    { Value: '05:00:00', Name: '5am' },
    { Value: '06:00:00', Name: '6am' },
    { Value: '07:00:00', Name: '7am' },
    { Value: '08:00:00', Name: '8am' },
    { Value: '09:00:00', Name: '9am' },
    { Value: '10:00:00', Name: '10am' },
    { Value: '11:00:00', Name: '11am' },
    { Value: '12:00:00', Name: '12pm' }
  ];

  public render() {
    return <div>
      {this.props.settings.ID !== 739 && <>
        <Checkbox
          label="Create Quotes"
          value={this.props.settings.CreateQuotes}
          onChange={(e) =>  this.setSettings({ CreateQuotes: e.value })}
        />
        <br />
        {this.props.settings.CreateQuotes && <>
          <Checkbox
            label="Use Price Optimization"
            value={this.props.settings.UsePriceOptimization}
            onChange={(e) =>  this.setSettings({ UsePriceOptimization: e.value })}
          />
          <br />
          <Checkbox
            label="Email Reply or Decline when No Units in Area"
            value={this.props.settings.AutoEmailDecline}
            onChange={(e) =>  this.setSettings({ AutoEmailDecline: e.value })}
          />
          <br />
          <Checkbox
            label="Automatically Offer Drivers After Hours"
            value={this.props.settings.AutoOfferDriversAfterHours}
            onChange={(e) =>  this.setSettings({ AutoOfferDriversAfterHours: e.value })}
          />
          <br />
          <Checkbox
            label="Automatically Offer Drivers During Office Hours"
            value={this.props.settings.AutoOfferDriversWorkHours}
            onChange={(e) =>  this.setSettings({ AutoOfferDriversWorkHours: e.value })}
          />
          <br />
        </>}
        {this.props.settings.CreateQuotes && (this.props.settings.AutoOfferDriversAfterHours || this.props.settings.AutoOfferDriversWorkHours) && <>
          {(this.props.settings.AutoOfferDriversAfterHours == false || this.props.settings.AutoOfferDriversWorkHours == false) && <>
            <div className="k-form-field">
              <label>After hours From:</label>
              <DropDownList
                textField="Name"
                data={this.afterHoursFrom}
                onChange={(e) => {
                  this.setSettings({ AfterHoursFrom: e.target.value.Value });
                }}
                value={this.afterHoursFrom.find(x => x.Value === this.props.settings.AfterHoursFrom)}
              />
            </div>
            <div className="k-form-field">
              <label>After hours To:</label>
              <DropDownList
                textField="Name"
                data={this.afterHoursTo}
                onChange={(e) => {
                  this.setSettings({ AfterHoursTo: e.target.value.Value });
                }}
                value={this.afterHoursTo.find(x => x.Value === this.props.settings.AfterHoursTo)}
              />
            </div>
          </>}
        </>}
        <Checkbox
          label="Email Reply or Bid w/ Rate on Offer Accept"
          value={this.props.settings.AutoEmailRate}
          onChange={(e) =>  this.setSettings({ AutoEmailRate: e.value })}
        />
        <br /><br />
      </>}
      <Checkbox
        label="Override Units in Area Miles"
        value={this.props.settings.UnitsInAreaMiles !== null}
        onChange={(e) =>  this.setSettings({ UnitsInAreaMiles: e.value ? this.props.settings.UnitsInAreaMiles || this.props.defaults.UnitsInAreaMiles || 300 : null })}
      />
      {this.props.settings.UnitsInAreaMiles !== null && <>
        <NumericTextBox
          min={25}
          max={500}
          step={25}
          format="n0"
          value={this.props.settings.UnitsInAreaMiles || this.props.defaults.UnitsInAreaMiles}
          onChange={(e) =>  this.setSettings({ UnitsInAreaMiles: e.value })}
        />
      </>}
      <br /><br />
      <Checkbox
        label="Override Vehicle Reserve Minutes"
        value={this.props.settings.VehicleReserveMinutes !== null}
        onChange={(e) =>  this.setSettings({ VehicleReserveMinutes: e.value ? this.props.settings.VehicleReserveMinutes || this.props.defaults.VehicleReserveMinutes || 15 : null })}
      />
      {this.props.settings.VehicleReserveMinutes !== null && <>
        <NumericTextBox
          min={5}
          max={60}
          step={5}
          format="n0"
          value={this.props.settings.VehicleReserveMinutes || this.props.defaults.VehicleReserveMinutes}
          onChange={(e) =>  this.setSettings({ VehicleReserveMinutes: e.value })}
        />
      </>}
      <br /><br />
      <Checkbox
        label="Override Auto Offer Tractors"
        value={this.props.settings.AutoOfferTractors !== null}
        onChange={(e) =>  this.setSettings({ AutoOfferTractors: e.value ? this.props.settings.AutoOfferTractors || this.props.defaults.AutoOfferTractors || false : null })}
      />
      {this.props.settings.AutoOfferTractors !== null && <>
        &nbsp;
        <Checkbox
          label="Auto Offer Tractors"
          value={this.props.settings.AutoOfferTractors}
          onChange={(e) =>  this.setSettings({ AutoOfferTractors: e.value })}
        />
      </>}
      <br /><br />
      <Checkbox
        label="Override Post to Carrier Load Board"
        value={this.props.settings.PostToCarrierLoadBoard !== null}
        onChange={(e) =>  this.setSettings({ PostToCarrierLoadBoard: e.value ? this.props.settings.PostToCarrierLoadBoard || this.props.defaults.PostToCarrierLoadBoard || false : null })}
      />
      {this.props.settings.PostToCarrierLoadBoard !== null && <>
        &nbsp;
        <Checkbox
          label="Post to Carrier Load Board"
          value={this.props.settings.PostToCarrierLoadBoard}
          onChange={(e) =>  this.setSettings({ PostToCarrierLoadBoard: e.value })}
        />
      </>}
      <br /><br />
      <Checkbox
        label="Override Auto Offer Miles"
        value={this.props.settings.AutoOfferMiles !== null}
        onChange={(e) =>  this.setSettings({ AutoOfferMiles: e.value ? this.props.settings.AutoOfferMiles || this.props.defaults.AutoOfferMiles || 125 : null })}
      />
      {this.props.settings.AutoOfferMiles !== null && <>
        <NumericTextBox
          min={25}
          max={500}
          step={25}
          format="n0"
          value={this.props.settings.AutoOfferMiles || this.props.defaults.AutoOfferMiles}
          onChange={(e) =>  this.setSettings({ AutoOfferMiles: e.value })}
        />
      </>}

      <br />
    </div>;
  }

  private setSettings(value: Partial<IndividualRequestQuoteSettingsViewModel>) {
    const settings = Object.assign({}, this.props.settings, value);
    this.props.onChange(settings);
  }
}
