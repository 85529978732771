import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { ILink } from '../../types/link';
import TripSummary from './TripSummary';
import { Trip } from '.';
import OrderStatusNotePopup from './OrderStatusNotePopup';

const StatusCell = (props: GridCellProps) => {
  const [showTripSummary, setShowTripSummary] = React.useState(false);
  const [showOrderStatusNotePopup, setShowOrderStatusNotePopup] = React.useState(false);
  const trip = props.dataItem as Trip;

  const orderStopStatusName = (orderStatusName: string, status: number, event: number) => {
    if (orderStatusName) return orderStatusName;

    switch (status) {
      case 100:
        return 'Not Started';
      case 110:
        return `Enroute to ${(event === 1) ? 'PU' : 'DEL'}`;
      case 115:
        return `Arrived at ${(event === 1) ? 'PU' : 'DEL'}`;
      case 120:
        return 'Completed';
      default:
        return 'On Route'; // Deadhead
    }
  }

  return <td>
    {showTripSummary && <TripSummary orderId={trip.OrderID} close={() => setShowTripSummary(false)} />}
    {showOrderStatusNotePopup && <OrderStatusNotePopup
      orderId={trip.OrderID}
      statusNote={trip.StatusNote}
      onSave={(statusNote) => {
        trip.StatusNote = statusNote;
        setShowOrderStatusNotePopup(false);
      }}
      onClose={() => setShowOrderStatusNotePopup(false)}
    />}
    {trip.StopSequence} of {trip.StopCount} &nbsp;
    {trip.Links.find((x: ILink) => x.Name === 'TripSummary') && <a
      href="#"
      style={{ color: '#007bff' }}
      onClick={(e) => {
        setShowTripSummary(true);
        e.preventDefault();
      }}
    >TS
    </a>}<br />
    {trip.OrderID ? <a
      href="#"
      style={{ color: '#007bff' }}
      onClick={(e) => {
        setShowOrderStatusNotePopup(true);
        e.preventDefault();
      }}
    >{orderStopStatusName(trip.OrderStatusName, trip.OrderStopStatus, trip.OrderStopEvent)}
    </a> : orderStopStatusName(trip.OrderStatusName, trip.OrderStopStatus, trip.OrderStopEvent)}
    <br />
    <span style={{ cursor: 'pointer' }} onClick={() => setShowOrderStatusNotePopup(true)} className={trip.StatusNote ? "badge badge-info" : "badge badge-light"}>{trip.StatusNote || "Add Note"}</span>
    </td>
  }

  export default React.memo(StatusCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);
