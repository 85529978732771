import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchApi } from "../../services/api";
import { ILink } from '../../types/link';
import RetirePopup from './RetirePopup';
import { JsonResponse } from "TypeGen/json-response";
import { SvgIcon } from "@progress/kendo-react-common";
import { chevronRightIcon } from "@progress/kendo-svg-icons";

type Props = {
  id: string | number;
  currentTab: DriverTabs;
}

export type DriverTabs = "Profile" | "Notes" | "Performance" | "MovementHistory" | "HoursOfService" | "WorkloadHistory" | "Turndowns" | "Offers" | "Devices" | "Logs";

export const DriverNavBar = (props: Props) => {

  const [number, setNumber] = useState("");
  const [links, setLinks] = useState<ILink[]>([]);
  const [showRetirePopup, setShowRetirePopup] = useState<boolean>(false);

  const refresh = (driverId: string | number) => {
    setLinks([]);
    fetchApi(`/api/Asset/DriverNavBar/${driverId}`)
      .then((data: { Number: string, Links: ILink[] }) => {
        setNumber(data.Number);
        setLinks(data.Links);
      });
  }

  const reactivate = () => {
    if (!window.confirm("Are you sure you want to rehire this driver?")) {
      return;
    }

    fetchApi(`/api/Asset/ReactivateDriver/${props.id}`, {}, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          refresh(props.id);
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(() => {
        alert("Unable to reactivate");
      });
  }

  useEffect(() => {
    refresh(props.id);
  }, [props.id]);

  const vehicleLink = links.find((x: ILink) => x.Name === 'VehicleProfile');

  return <>
    {showRetirePopup && <RetirePopup
      DriverID={props.id}
      CloseDialog={() => setShowRetirePopup(false)}
      Refresh={() => refresh(props.id)}
    />}
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark mt-3">
      <span className="navbar-brand">
        <Link to="/Assets/Drivers">Drivers</Link>
        <span className="text-muted">
          <SvgIcon icon={chevronRightIcon} />
          {number}
        </span>
      </span>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#driverNav" aria-controls="driverNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="driverNav">
        <ul className="navbar-nav">
          {links.find((x: ILink) => x.Name === 'Driver') && <li className={`nav-item ${props.currentTab === 'Profile' ? "active" : ""}`}>
            <Link className="nav-link" to={`/Assets/Driver/${props.id}`}>Profile</Link>
          </li>}
          {links.find((x: ILink) => x.Name === 'Devices') && <li className={`nav-item ${props.currentTab === 'Devices' ? "active" : ""}`}>
            <Link className="nav-link" to={`/Assets/Driver/${props.id}/Devices`}>Devices</Link>
          </li>}
          {links.find((x: ILink) => x.Name === 'HoursOfService') && <li className={`nav-item ${props.currentTab === 'HoursOfService' ? "active" : ""}`}>
            <Link className="nav-link" to={`/Assets/Driver/${props.id}/HoursOfService`}>HOS</Link>
          </li>}
          {links.find((x: ILink) => x.Name === 'WorkloadHistory') && <li className={`nav-item ${props.currentTab === 'WorkloadHistory' ? "active" : ""}`}>
            <Link className="nav-link" to={`/Assets/Driver/${props.id}/WorkloadHistory`}>Workload History</Link>
          </li>}
          {links.find((x: ILink) => x.Name === 'Turndowns') && <li className={`nav-item ${props.currentTab === 'Turndowns' ? "active" : ""}`}>
            <Link className="nav-link" to={`/Assets/Driver/${props.id}/Turndowns`}>Turndowns</Link>
          </li>}
          {links.find((x: ILink) => x.Name === 'Offers') && <li className={`nav-item ${props.currentTab === 'Offers' ? "active" : ""}`}>
            <Link className="nav-link" to={`/Assets/Driver/${props.id}/Offers`}>Offers</Link>
          </li>}
          {links.find((x: ILink) => x.Name === 'Notes') && <li className={`nav-item ${props.currentTab === 'Notes' ? "active" : ""}`}>
            <Link className="nav-link" to={`/Assets/Driver/${props.id}/Notes`}>Notes</Link>
          </li>}
          {links.find((x: ILink) => x.Name === 'Performance') && <li className={`nav-item ${props.currentTab === 'Performance' ? "active" : ""}`}>
            <Link className="nav-link" to={`/Assets/Driver/${props.id}/Performance`}>Performance</Link>
          </li>}
          {links.find((x: ILink) => x.Name === 'MovementHistory') && <li className={`nav-item ${props.currentTab === 'MovementHistory' ? "active" : ""}`}>
            <Link className="nav-link" to={`/Assets/Driver/${props.id}/MovementHistory`}>Movement History</Link>
          </li>}
          {links.find((x: ILink) => x.Name === 'Logs') && <li className={`nav-item ${props.currentTab === 'Logs' ? "active" : ""}`}>
            <Link className="nav-link" to={`/Assets/ResourceLogs/${props.id}/3`}>Logs</Link>
          </li>}
        </ul>
        <ul className="nav navbar-nav ml-auto">
          {vehicleLink && <Link className="btn btn-secondary mr-1" to={vehicleLink.Link}>Vehicle</Link>}
          {links.find((x: ILink) => x.Name === 'Retire') && <Button onClick={() => setShowRetirePopup(true)} themeColor="error">Retire</Button>}
          {links.find((x: ILink) => x.Name === 'ReHire') && <Button onClick={() => reactivate()} themeColor="primary">Rehire</Button>}
        </ul>
      </div>
    </nav>
  </>;
}