import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { dateWindowFormatter } from 'utils/utils';

const NextStopCell = (props: GridCellProps) => {

  const getWaitingAtArrivedColor = (minutes: number) => {
    if (minutes > 45) return 'red';
    if (minutes > 30) return 'yellow';
    return undefined;
  }

  return (
    <td>
      {props.dataItem.OrderStopCustomerCustomerName && <>{props.dataItem.OrderStopCustomerCustomerName}<br /></>}
      {props.dataItem.EarliestStopDateTime && (
        <span style={{ color: (props.dataItem.IsScheduledSoon ? 'red' : '#007bff') }}>
          {dateWindowFormatter(props.dataItem.EarliestStopDateTime, props.dataItem.LatestStopDateTime)}
        </span>
      )} &nbsp; <span style={{ color: getWaitingAtArrivedColor(props.dataItem.WaitingAtArrivedMinutes) }}>{props.dataItem.Verb}</span>
    </td>
  );
}

export default React.memo(NextStopCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);
