import * as React from 'react';
import { Title } from '../../utils/title';
import { translateDataSourceResultGroups, toDataSourceRequestString, FilterDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar, GridDataStateChangeEvent, GridColumnMenuFilter, GridColumnProps } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { fetchApi } from '../../services/api';
import ColumnMenu from '../LoadBoard/BidHistory/ColumnMenu';
import { quoteIDCell } from '../Quote/Recent';
import TheadIDCell from '../Quote/Recent/ThreadIDCell';
import OrderNumberCell from '../Quote/Recent/OrderNumberCell';
import { debounce } from 'ts-debounce';
import { State as DataState } from '@progress/kendo-data-query';
import DateCell from '../../components/cells/DateCell';
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import { CustomFilterUIDropdown } from 'components/CustomFilterUIDropDown';
import { orderStatuses } from 'views/Track/AdvancedSearch';
import { arrowRotateCwIcon, filterClearIcon } from '@progress/kendo-svg-icons';
import { GridLoadingPanel } from 'components/GridLoadingPanel';

type Props = {};

type State = {
  dataState: DataState;
  data: QuoteValue[],
  total: number;
  vehicleId: number;
  vehicles: Array<{ ID: number; Name: string }>;
  filterText: string;
  loading: boolean;
}

type QuoteValue = {
  QuoteID: number;
  ThreadID: string;
  RouteName: string;
  Distance: number;
  Total: number;
  CustomerNumber: string;
  VehicleTypeName: string;
  FromLocation: string;
  FromDateTime: Date;
  ToLocation: string;
  CreatedDateTime: Date;
  AcceptedOfferCount: number;
  DeclinedOfferCount: number;
  ExpiredOfferCount: number;
  Pieces: number;
  Weight: number;
  OrderID: null | number;
  OrderNumber: null | number;
  OrderStatus: null | number;
  CreatedByUserName: string;
  IsTemplate: boolean;
}

class QuoteHistory extends React.Component<Props, State> {

  private export: React.RefObject<ExcelExport>;

  constructor(props: Props) {
    super(props);

    let dataState: DataState = {
      skip: 0,
      take: 50,
      sort: [{field: "QuoteID", dir: "desc"}],
      filter: { logic: 'and', filters: [] }
    };
    const sessionStorageDataState = sessionStorage.getItem("QuoteHistory-DataState");
    if (sessionStorageDataState) {
      dataState = JSON.parse(sessionStorageDataState);
    }

    this.state = {
      dataState,
      data: [],
      total: 0,
      vehicles: [],
      vehicleId: 0,
      filterText: '',
      loading: false,
    };

    this.export = React.createRef();

    this.reset = this.reset.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.dataStateChange = this.dataStateChange.bind(this);
    this.filterTextChange = this.filterTextChange.bind(this);
  }

  private columnProps(field: string): Partial<GridColumnProps> {
    return {
        field: field,
        columnMenu: ColumnMenu,
        headerClassName: this.isColumnActive(field, this.state.dataState) ? 'active' : ''
    };
  }

  private isColumnActive(field: string, dataState: any) {
    return GridColumnMenuFilter.active(field, dataState.filter);
  }

  public componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const offeredVehicleId = urlParams.get('offeredVehicleId');
    if (offeredVehicleId != null) {
      this.setState({ vehicleId: parseInt(offeredVehicleId) }, this.fetchData);
    } else {
      this.fetchData();
    }

    fetchApi("/api/Track/AssetNumbers")
      .then((data: { Drivers: Array<{ ID: number, Name: string }>, Vehicles: Array<{ ID: number, Name: string }> }) => {
        this.setState({ vehicles: data.Vehicles });

        // Pre-populate filter text
        if (offeredVehicleId != null) {
          const vehicle = data.Vehicles.find(v => v.ID === parseInt(offeredVehicleId));
          if (vehicle != null) {
            this.setState({ filterText: vehicle.Name });
          }
        }
      });
  }

  public render() {
    const hasTemplateFilter = this.hasFilterDescriptor(this.state.dataState.filter?.filters as FilterDescriptor[], 'IsTemplate');
    return (
      <React.Fragment>
        <Title string="Quote History" />
        {this.state.loading && <GridLoadingPanel />}
        <ExcelExport ref={this.export}>
          <Grid sortable={{ allowUnsort: true }}
              pageable={{ pageSizes: [20, 50, 100, 500] }}
              scrollable={'none'}
              total={this.state.total}
              data={this.state.data}
              onDataStateChange={this.dataStateChange}
              {...this.state.dataState}
              >
            <GridToolbar>
            <Button
              title="Clear Filters and Sort"
              icon="filter-clear"
              svgIcon={filterClearIcon}
              onClick={this.reset}
            />
            <Button
              title="Refresh"
              icon="refresh"
              svgIcon={arrowRotateCwIcon}
              onClick={this.fetchData}
            />
            <Button
              onClick={() => this.export.current.save(this.state.data)}
            >Excel
            </Button>
            <Input placeholder="Unit # Offered" value={this.state.filterText} onChange={(value) => {
              this.setState({ filterText: value.value.toUpperCase() }, () => {
                this.filterTextChangeDebounced();
              });
            }} />
            <Button
              togglable
              selected={hasTemplateFilter}
              onClick={() => this.dataStateChange({ dataState: {
                ...this.state.dataState,
                filter: { logic: 'and', filters: hasTemplateFilter ? [] : [{ field: 'IsTemplate', operator: 'eq', value: true }] }
              } } as GridDataStateChangeEvent)
            }
            >Templates
            </Button>
            </GridToolbar>
            <Column
              width="120px"
              {...this.columnProps("QuoteID")}
              title="#"
              filter="numeric"
              cell={quoteIDCell()}
            />
            <Column {...this.columnProps("ThreadID")} title="Front" cell={TheadIDCell} />
            <Column {...this.columnProps("OrderNumber")} title="Order No." filter="numeric" cell={OrderNumberCell} />
            <Column {...this.columnProps("OrderStatus")} title="Status" filter="numeric"
              cell={(props) => {
                const status = props.dataItem.OrderStatus;
                return <td>{status != null ? orderStatuses.find(x => x.ID == status)?.Name : ''}</td>;
              }}
              columnMenu={(props) =>
                <GridColumnMenuFilter
                    {...props}
                    expanded
                    filterUI={(props) =>
                        <CustomFilterUIDropdown
                          {...props}
                          data={orderStatuses}
                          textField="Name"
                          dataItemKey="ID"
                          operator='eq'
                          defaultItem={{ ID: 0, Name: "Status" }}
                        />
                    }
                />
              }
            />
            <Column {...this.columnProps("RouteName")} title="Route" />
            <Column {...this.columnProps("Distance")} title="Miles" filter="numeric" format="{0:n0}" />
            <Column {...this.columnProps("Pieces")} filter="numeric" format="{0:n0}" />
            <Column {...this.columnProps("Weight")} filter="numeric" format="{0:n0}" />
            <Column {...this.columnProps("Total")} title="Charges" filter="numeric" format="{0:c}" />
            <Column {...this.columnProps("VehicleTypeName")} title="Vehicle Type" />
            <Column {...this.columnProps("CustomerNumber")} title="Bill To" />
            <Column {...this.columnProps("FromLocation")} title="From" />
            <Column {...this.columnProps("ToLocation")} title="To" />
            <Column {...this.columnProps("FromDateTime")} title="Ship Date" filter="date" cell={DateCell} />
            <Column {...this.columnProps("CreatedDateTime")} title="Created" filter="date" cell={DateCell} />
            <Column {...this.columnProps("CreatedByUserName")} title="By" />
          </Grid>
        </ExcelExport>
      </React.Fragment>
    );
  }

  private filterTextChangeDebounced = debounce(this.filterTextChange, 250);

  private filterTextChange() {
    // Vehicle Number Search
    var vehicleResult = this.state.vehicles.find(x => x.Name === this.state.filterText);
    if (vehicleResult && vehicleResult.ID != this.state.vehicleId) {
      this.setState({ vehicleId: vehicleResult.ID }, this.fetchData);
    } else if (this.state.vehicleId > 0 && !this.state.filterText) {
      this.setState({ vehicleId: 0 }, this.fetchData);
    }
  }

  private reset() {
    this.dataStateChange({ dataState: {
      skip: 0,
      take: 50,
      sort: [{field: "QuoteID", dir: "desc"}],
      filter: { logic: 'and', filters: [] }
    } } as GridDataStateChangeEvent);
  }

  private dataStateChange(changeEvent: GridDataStateChangeEvent) {
    sessionStorage.setItem('QuoteHistory-DataState', JSON.stringify(changeEvent.dataState));
    this.setState({ dataState: changeEvent.dataState }, () => this.fetchData());
  }

  private hasFilterDescriptor = (filters: FilterDescriptor[] | undefined, field: string) => {
    if (!filters) return false;
    return filters.filter(x => x.field == field).length > 0;
  }

  private fetchData() {
    this.setState({ loading: true });

    const dataState = this.state.dataState;

    const queryStr = `${toDataSourceRequestString(dataState)}`; // Serialize the state
    const hasGroups = dataState.group && dataState.group.length;

    fetchApi(`/api/Quote/History_Read?${queryStr}`, { VehicleID: this.state.vehicleId }, 'POST')
        .then(({ Data, Total }) => {
            this.setState({
                data: hasGroups ? translateDataSourceResultGroups(Data) : Data,
                total: Total,
                dataState,
                loading: false,
            });
        }).catch(() => {
          this.setState({ loading: false });
        });
  }
}

export default QuoteHistory;
