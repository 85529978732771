import { GridCellProps } from '@progress/kendo-react-grid';
import Moment from 'moment-timezone';
import * as React from 'react';

export class PickupCell extends React.Component<GridCellProps> {
  public render() {
    return (
      <td>
        {this.props.dataItem.PickupLocation}<br />
        {Moment.utc(this.props.dataItem.PickupDateTime).tz("America/New_York").format('MM/DD/YYYY HH:mm')}<br />
        {this.props.dataItem.LaneName}
      </td>
    );
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    if (nextProps.dataItem.Hash === this.props.dataItem.Hash) {
      return false;
    }

    return true;
  }
}
