import { Popup } from '@progress/kendo-react-popup';
import { GridCellProps } from "@progress/kendo-react-grid";
import * as React from 'react';
import { ILink } from '../../../types/link';
import { openWindow } from '../../../services/openWindow';
import OutsideAlerter from '../../../components/OutsideAlerter';
import { VehicleValue } from '../Vehicles';
import { Coordinate } from '../../../types/address';

// Rate = Linehaul + Accessorial
export default function vehicleActionCell(
    distance: number,
    rate: number,
    fuel: number,
    orderStatus: number,
    dispatch: (vehicleId: number, vehicleNumber: string, plan: boolean) => void,
    reoffer: (vehicle: VehicleValue, allInRate: number, linehaul: number, fuel: number, deadhead: number) => void,
    turndown: (vehicleId: number, driverId: number, distance: number, reset: boolean) => void,
    transfer: (vehicleId: number) => void,
    release: (vehicleId: number) => void,
    copyEmail: (vehicleId: number) => void,
    openDeadhead: (coordinates: Coordinate) => void,
) {
    return class ActionPopup extends React.Component<GridCellProps> {

        private show: boolean;
        private anchor: React.RefObject<HTMLButtonElement>;

        constructor(props: GridCellProps) {
            super(props);

            this.anchor = React.createRef();

            this.show = false;
        }

        render() {
            const vehicle = this.props.dataItem as VehicleValue;
            return (
                <td>
                    <button
                        disabled={vehicle.VehicleID === 0}
                        className={`btn btn-sm ${this.show ? 'btn-primary' : 'btn-secondary'}`}
                        style={{ float: 'left', marginRight: '5px' }}
                        onClick={(e) => {
                            this.show = !this.show;
                            this.forceUpdate();
                            e.preventDefault();
                        }}
                        ref={this.anchor}
                    >
                        {this.show ? 'Action' : 'Action'}
                    </button>
                    <div>
                        <Popup anchor={this.anchor.current} show={this.show} popupClass={'popup-content'}>
                            <OutsideAlerter toggle={() => { this.show = !this.show; this.forceUpdate(); }} style={{ display: 'contents' }}>
                                {this.displayReOfferButton()}
                                {orderStatus == 100 && <a
                                    href="#"
                                    style={{ color: '#007bff' }}
                                    onClick={(e) => {
                                        dispatch(vehicle.VehicleID, vehicle.VehicleNumber, vehicle.Status == 'On A Load');
                                        this.show = !this.show;
                                        this.forceUpdate();
                                        e.preventDefault();
                                    }}
                                >{vehicle.Status == 'On A Load' ? 'Plan' : 'Dispatch'}
                                </a>}
                                {vehicle.Links.find((x: ILink) => x.Name === 'VehicleMessage') && <a
                                    href="#"
                                    style={{ color: '#007bff' }}
                                    onClick={(e) => {
                                        openWindow((vehicle.Links.find((x: ILink) => x.Name === 'VehicleMessage') as ILink).Link);
                                        this.show = !this.show;
                                        this.forceUpdate();
                                        e.preventDefault();
                                    }}
                                >Sat Message
                                </a>}
                                {this.props.dataItem.QuoteOfferStatus === 2 && <a
                                    href="#"
                                    style={{ color: '#007bff' }}
                                    onClick={(e) => {
                                        copyEmail(vehicle.VehicleID);
                                        this.show = !this.show;
                                        this.forceUpdate();
                                        e.preventDefault();
                                    }}
                                >Copy Email
                                </a>}
                                <a
                                    href="#"
                                    style={{ color: '#007bff' }}
                                    onClick={(e) => {
                                        openDeadhead(vehicle.Coordinates);
                                        this.show = !this.show;
                                        this.forceUpdate();
                                        e.preventDefault();
                                    }}
                                >View Deadhead
                                </a>
                                <a
                                    href="#"
                                    style={{ color: '#007bff' }}
                                    onClick={(e) => {
                                        openWindow(`/Assets/Vehicle/${vehicle.VehicleID}/Turndowns`);
                                        this.show = !this.show;
                                        this.forceUpdate();
                                        e.preventDefault();
                                    }}
                                >View Turn Downs
                                </a>
                                <a
                                    href="#"
                                    style={{ color: '#007bff' }}
                                    onClick={(e) => {
                                        window.confirm("Are you sure you want to create a TD and RESET LA?") && turndown(vehicle.VehicleID, vehicle.DriverID, vehicle.Distance, true);
                                        this.show = !this.show;
                                        this.forceUpdate();
                                        e.preventDefault();
                                    }}
                                >Create Turn Down - Reset LA
                                </a>
                                <a
                                    href="#"
                                    style={{ color: '#007bff' }}
                                    onClick={(e) => {
                                        window.confirm("Are you sure you want to create a TD with NO LA RESET?") && turndown(vehicle.VehicleID, vehicle.DriverID, vehicle.Distance, false);
                                        this.show = !this.show;
                                        this.forceUpdate();
                                        e.preventDefault();
                                    }}
                                >Create Turn Down - No Reset
                                </a>
                                {vehicle.Links.find((x: ILink) => x.Name === 'VehicleTransfer') && <a
                                    href="#"
                                    style={{ color: '#007bff' }}
                                    onClick={(e) => {
                                        transfer(vehicle.VehicleID);
                                        this.show = !this.show;
                                        this.forceUpdate();
                                        e.preventDefault();
                                    }}
                                >Transfer Reserve
                                </a>}
                                {vehicle.Links.find((x: ILink) => x.Name === 'VehicleRelease') && <a
                                    href="#"
                                    style={{ color: '#007bff' }}
                                    onClick={(e) => {
                                        window.confirm("Are you sure you want to release your vehicle reserve?") && release(vehicle.VehicleID);
                                        this.show = !this.show;
                                        this.forceUpdate();
                                        e.preventDefault();
                                    }}
                                >Release Reserve
                                </a>}
                                <a
                                    href="#"
                                    style={{ color: '#007bff' }}
                                    onClick={(e) => {
                                        openWindow(`/Quote/History?offeredVehicleId=${vehicle.VehicleID}`, 1000);
                                        this.show = !this.show;
                                        this.forceUpdate();
                                        e.preventDefault();
                                    }}
                                >Recent Offers
                                </a>
                            </OutsideAlerter>
                        </Popup>
                    </div>
                </td>
            );
        }

        private displayReOfferButton() {
            if (this.props.dataItem.QuoteOfferStatus === 1 || this.props.dataItem.QuoteOfferStatus === 3) {
                if (this.props.dataItem.Links.filter((x: ILink) => x.Name === "Offer").length > 0) {
                    const fuelAmount = fuel * this.props.dataItem.FuelPercentage;
                    const linehaulAmount = (rate * this.props.dataItem.LinehaulPercentage) + (distance * this.props.dataItem.LinehaulRPM);
                    const minLinehaulAmount = distance * this.props.dataItem.LinehaulRPMMin;
                    const deadheadAmount = Math.max(this.props.dataItem.Distance - this.props.dataItem.FreeDeadheadMiles, 0) * this.props.dataItem.DeadheadRPM;
                    const payAmount = this.props.dataItem.QuoteOfferAllInRate ? this.props.dataItem.QuoteOfferAllInRate : Math.max(linehaulAmount, minLinehaulAmount) + fuelAmount + deadheadAmount;
                    return <a
                        href="#"
                        style={{ color: '#007bff' }}
                        onClick={(e) => {
                            reoffer(this.props.dataItem, payAmount, linehaulAmount, fuelAmount, deadheadAmount);
                            this.show = false;
                            this.forceUpdate();
                            e.preventDefault();
                        }}>
                        Re-Offer
                    </a>
                }

                return <a
                    href="#"
                    style={{ color: '#007bff' }}
                    onClick={(e) => {
                        openWindow(this.props.dataItem.Links.filter((x: ILink) => x.Name === 'DriverProfile')[0].Link)
                        this.show = !this.show;
                        this.forceUpdate();
                        e.preventDefault();
                    }}>
                    Re-Offer
                </a>
            }

            return null;
        }
    }
}
