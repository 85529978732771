import { useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import Moment from 'moment-timezone';
import { fetchApi } from "../../services/api";
import { Loader } from "@progress/kendo-react-indicators";
import { CustomerNavBar } from "./NavBar";
import JsonPretty from "components/JsonPretty";

export enum ResourceType {
    Carrier = 1,
    Vehicle = 2,
    Driver = 3,
    Owner = 4,
    Trailer = 5
}

type RouteComponentParams = {
    customerId: string;
}

type ResourceLogsType = {
    Logs: ResourceLogType[]
}

type ResourceLogType = {
    CustomerLogID: number;
    ModifiedByFullName: string;
    ModifiedDateTime: Date;
    TypeName: string;
    OldData: string;
    NewData: string;
}

const CustomerLogs = () => {
    const { customerId: customerIdCodeParam } = useParams<RouteComponentParams>();

    const [loading, setLoading] = useState(true);
    const [logs, setLogs] = useState<ResourceLogsType>();

    useEffect(() => {
        setLoading(true);
        fetchApi(`/api/Customer/CustomerLogs/${customerIdCodeParam}`)
            .then(data => {
                setLogs(data);
            })
            .catch(e => {
                // If not problem details
                if (!e?.status) alert(e);
            })
            .finally(() => setLoading(false));
    }, [customerIdCodeParam]);

    const loadingView = <div className="k-pos-absolute k-top-center mt-5">
        <Loader type="converging-spinner" />
    </div>;

    const dataView = () => <>
        {logs.Logs.length === 0 && <h1 className="text-center">No Logs.</h1>}
        {logs.Logs.map(log => <div key={log.CustomerLogID} className="media m-2 border-bottom">
            <div className="media-body">
                <h5 className="mt-0">{log.TypeName} - {log.ModifiedByFullName}</h5>
                <div className="row">
                    <div className="col-6"><label className="font-weight-bold">Old:</label> {log.OldData.length ? <JsonPretty json={log.OldData} /> : "N/A"}</div>
                    <div className="col-6"><label className="font-weight-bold">New:</label> {log.NewData.length ? <JsonPretty json={log.NewData} /> : "N/A"}</div>
                </div>
            </div>
            <p><small>{Moment.utc(log.ModifiedDateTime).tz("America/New_York").format('MM/DD/YYYY HH:mm')}</small></p>
        </div>)}
    </>

    return (<>
        <CustomerNavBar id={customerIdCodeParam} currentTab="Logs" />
        <Title string="Logs" />
        {loading || !logs ? loadingView : dataView()}
    </>);
}

export default CustomerLogs;
